import { Switch } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

interface Props {
  control: Control
  name: string
  disabled?: boolean
}

const FormSwitch = ({ control, name, disabled }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Switch
          checked={field.value}
          onChange={(e) => field.onChange(e.target.checked)}
          disabled={disabled}
        />
      )}
    />
  )
}

export default FormSwitch
