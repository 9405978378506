import React from "react"
import { DeleteOutline } from "@mui/icons-material"
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Avatar,
  styled,
} from "@mui/material"
import { IUserForProjectShow } from "src/interfaces/project.interface"
import panda from "src/assets/images/PandaCoolAhre.png"
import { SENIORITY_OPTIONS } from "src/interfaces/user.interfaces"

interface IUsersForProjectTableProps {
  users: IUserForProjectShow[]
  onDelete: (id: string) => void
  minHeight?: number
}

const EmployeesTableHead = styled(TableRow)(({ theme }) => ({
  "&": {
    boxShadow: "0px 3px 6px #1C212426",
    opacity: 1,
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  },
  "& th": {
    borderBottom: "0",
  },
}))

const UsersForProjectTable: React.FC<IUsersForProjectTableProps> = ({
  users,
  onDelete,
  minHeight,
}) => {
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        size={"medium"}
      >
        <TableHead>
          <EmployeesTableHead>
            <TableCell>Member</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Seniority</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Allocation</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell></TableCell>
          </EmployeesTableHead>
        </TableHead>
        <TableBody>
          {users.map((row) => (
            <TableRow sx={{ height: "70px" }} key={row.id}>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    alt="stakeholder photo"
                    src={row.photoURL || panda}
                    sx={{ marginRight: 3 }}
                  />
                  {row.fullName}
                </div>
              </TableCell>
              <TableCell>{row.role.name}</TableCell>
              <TableCell>
                {
                  SENIORITY_OPTIONS.find((el) => el.value === row.seniority)
                    ?.label
                }
              </TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{`${row.allocationPercentage}%`}</TableCell>
              <TableCell>{row.dayStart}</TableCell>
              <TableCell>{row.dayFinish}</TableCell>
              <TableCell width={1} className="class-deleteButton">
                <IconButton
                  onClick={() => {
                    onDelete(row.id)
                  }}
                >
                  <DeleteOutline />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UsersForProjectTable
