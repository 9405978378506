import React from 'react'
import { AvatarComponent } from '../../atoms'

import style from './UserLogged.module.scss'

export interface UserLoggedProps {
  name?: string
  position?: string
  urlImg?: string
  sizeAvatar?: 'tiny' | 'small' | 'medium' | 'big'
  onClick?: () => void
}

export const UserLoggedComponent = ({
  name,
  position,
  urlImg,
  sizeAvatar = 'medium',
  onClick,
}: UserLoggedProps) => {
  return (
    <div className={style.root} onClick={onClick} style={{ cursor: 'pointer' }}>
      {position ? (
        <div className={'message'}>
          <p className=""> {name} </p>
          <p className={style.position}> {position} </p>
        </div>
      ) : name ? (
        <div>
          {' '}
          <p>Welcome</p> <p className={style.position}> {name}</p>{' '}
        </div>
      ) : (
        ''
      )}
      <AvatarComponent urlImage={urlImg} size={sizeAvatar} />
    </div>
  )
}
