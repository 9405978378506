import React from 'react'
import { Box, Card, CardContent, Link } from '@mui/material'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

interface IFileCardProps {
  fileName: string
  url: string
}

const FileCard: React.FC<IFileCardProps> = ({ fileName, url }) => {
  return (
    <Card
      sx={{
        padding: '0.1rem',
        paddingBottom: 0,
        minWidth: 200,
        borderRadius: '30px',
        boxShadow: '0px 3px 6px #1C21244F',
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center">
          <InsertDriveFileIcon />
          <Link href={url} target="_blank" ml={1}>
            {fileName}
          </Link>
        </Box>
      </CardContent>
    </Card>
  )
}

export default FileCard
