import { Grid } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import SelectProfile from "src/components/atoms/SelectProfile"
import { iSkillFixed, SkillLevel } from "src/interfaces/skills.interfaces"
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons"
import FaIconButton from "src/components/atoms/FaIconButton"
import { TechWithId } from 'src/interfaces/skills.interfaces'
import { getSkills } from 'src/services/skills/skills.service'
import InputProfile from "src/components/atoms/InputProfile"
// import InputDropdown from "src/stories/components/ui/atoms/InputDropdown.tsx"
import {InputDropdown} from '../../../stories/components/ui/atoms/InputDropdown/InputDropdown'

const SKILL_LEVELS = [
  { value: SkillLevel.trainee, label: "Trainee" },
  { value: SkillLevel.junior, label: "Junior" },
  { value: SkillLevel.semiSenior, label: "Semi Senior" },
  { value: SkillLevel.senior, label: "Senior" },
]

const yearsOptions = Array.from({ length: 16 }, (_, i) =>
  i === 15 ? (i).toString() + "+" : (i).toString()
).map((years, index) => ({ value: index, label: years }))

interface Props {
  onAddSkill: (newSkill: iSkillFixed) => void
  spacing?: number
  disabled?: boolean
}

const SkillSelectProfile = ({ onAddSkill, spacing, disabled }: Props) => {
  const [selectedTech, setSelectedTech] = useState<number | string>('Select a Skill')
  const [skillLevel, setSkillLevel] = useState<number>()
  const [year, setYear] = useState<number>()
  const [techs, setTechs] = useState<TechWithId[]>([])

  useEffect(() => {
    getSkills().then((result) => setTechs(result.response as TechWithId[]))
  }, [])

  const techOptions = useMemo(() => {
    return techs.map((tech, key) => ({
      value: key,
      label: tech.tech,
    }))
  }, [techs])

  const handleAddSkill = () => {
    const currentSkillLevel = SKILL_LEVELS[skillLevel!].value
    const currentYear = yearsOptions[year!].label

    const newSkill = {
      tech: selectedTech,
      skillLevel: currentSkillLevel,
      year: currentYear,
    } as iSkillFixed

    onAddSkill(newSkill)
  }


  const isDisabled = !selectedTech || !skillLevel || !year;

  return (
    <>
      <Grid item sm={12} md={4}>
        {/* <SelectProfile
          label="Skill *"
          options={techOptions}
          value={selectedTech}
          onChange={setSelectedTech}
          disabled={disabled}
        /> */}
        <InputDropdown
          labelName="Skill *"
          disabled={disabled}
          arrayOptions={techOptions.map(obj => obj.label)}
          onChange={setSelectedTech}
          value={typeof selectedTech == "number" ? selectedTech.toString() : selectedTech}
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <SelectProfile
          label="Level *"
          options={SKILL_LEVELS}
          value={skillLevel}
          onChange={setSkillLevel}
          disabled={disabled}
        />
      </Grid>
      <Grid item sm={12} md={3}>
        <SelectProfile
          label="Years of Exp *"
          options={yearsOptions}
          value={year}
          onChange={setYear}
          disabled={disabled}
        />
      </Grid>
      <Grid item sm={12} md={1}>
        <FaIconButton
          // disabled={isDisabled}
          onClick={handleAddSkill}
          icon={faFloppyDisk}
        />
      </Grid>
    </>
  )
}

export default SkillSelectProfile
