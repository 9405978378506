
import { createTheme, PaletteOptions, Theme } from '@mui/material/styles';

const theme = createTheme({
    palette: {

    },
})

const LIGHT_PALETTE = {
    mode: 'light',
    primary: {
        main: "#707070",
        light: "#b3b3b3",
        dark: "#111111",
        pupple: "#5850ec",
        darkPupple: "#1f2937",
        blue: "#0083D2"
    },
    secondary: {
        main: "#f5f5f5",
    },
    background: {
        default: "#f1f1f1"
    },
    text: {
        primary: '#173A5E',
        secondary: '#46505A',
    },
    action: {
        active: '#001E3C',
    },
    success: {
        main: '#00B865',
    },
    error: {
        main: '#FF5252',
    },
    paper: {
        dark: '#1f2937',
        light: '#b3b3b3',
        main: '#f1f1f1',
    }

}

export const themeLight = createTheme({
    palette: LIGHT_PALETTE as PaletteOptions,
    spacing: [0, 4, 8, 16, 32, 64],

    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    components: {
        MuiListItem: {
            styleOverrides: {
                "root": {
                    "&.Mui-selected": {
                        "backgroundColor": "#222",
                        "color": "white"
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    color: LIGHT_PALETTE.primary.dark,
                },
            }
        }
    }
});

export const themeDark = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: "#f5f5f5",
        },
        secondary: {
            main: "#707070",
        },
        background: {
            default: "#111111"
        },
        text: {
            primary: '#173A5E',
            secondary: '#46505A',
        },
        action: {
            active: '#001E3C',
        },
        success: {
            main: '#00BFA5',
        },
    },
    spacing: [0, 4, 8, 16, 32, 64],
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    }
});

declare module '@mui/material/styles' {
    //@ts-ignore
    interface DefaultTheme extends Theme {
        palette: {
            mode: 'light' | 'dark';
            primary: {
                main: string;
            };
            secondary: {
                main: string;
            };
            background: {
                default: string;
            };
        };
        typography: {
            fontFamily: string;
        }
    }
}