import { TrashIcon } from "@heroicons/react/outline"
import PencilIcon from "@heroicons/react/solid/PencilIcon"
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material"
import React, { FC, useState } from "react"
import { NotificationManager } from "react-notifications"
import CRUDModal from "src/components/moleculas/CRUDModal"
import Spinner from "src/components/moleculas/Spinner/Index"
import { TechWithId } from "src/interfaces/skills.interfaces"
import { updateSkill, deleteSkill } from "src/services/skills/skills.service"
import { Input } from "src/stories/components/ui/atoms"

interface iProps {
  skills: TechWithId[] | undefined
  refreshSkills: () => void
}

const SkillList: FC<iProps> = ({ skills, refreshSkills }) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [updateModal, setUpdateModal] = useState<boolean>(false)
  const [selectedSkill, setSelectedRole] =
    useState<TechWithId | undefined>(undefined)
  const [skillName, setSkillName] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const deleteASkill = async (id: string) => {
    setLoading(true)
    const response = await deleteSkill(id)
    if (response) {
      NotificationManager.success("Skill deleted successfully", "Success", 750)
      refreshSkills()
    }
    setLoading(false)
    setDeleteModal(false)
  }
  const updateASkill = async (id: string, role: string) => {
    setLoading(true)
    const response = await updateSkill(id, role)
    if (response) {
      NotificationManager.success("Skill updated successfully", "Success", 750)
      refreshSkills()
    }
    setLoading(false)
    setUpdateModal(false)
  }

  return (
    <div style={{ width: "100%" }}>
      <Grid
        className="gap-5-md"
        display="flex"
        sx={{
          width: "100%",
          overflow: "auto",
          flexWrap: "wrap",
          justifyContent: "space-between",
          flexDirection: "row",
          alignContent: "stretch",
        }}
      >
        {skills?.map((skill, key) => (
          <Box
            className="width-49-md"
            key={key}
            sx={{
              background: "#EAEEF1",
              borderRadius: "20px",
              width: "33%",
              margin: "6px 0",
            }}
          >
            <Box
              key={skill.id}
              sx={{
                display: "flex",
                padding: "20px",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ color: "#828689", lineHeight: "24px", fontWeight: 500 }}
              >
                {skill.tech}
              </Typography>
              <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                <Tooltip title="Edit Skill" arrow>
                  <Box
                    onClick={() => {
                      setSkillName(skill.tech)
                      setUpdateModal(true)
                      setSelectedRole(skill)
                    }}
                    sx={{
                      color: "primary.blue",
                      cursor: "pointer",
                    }}
                  >
                    <PencilIcon style={{ width: "1.4em" }} />
                  </Box>
                </Tooltip>
                <Tooltip title="Delete Skill" arrow>
                  <Box
                    onClick={() => {
                      setDeleteModal(true)
                      setSelectedRole(skill)
                    }}
                    sx={{
                      color: "error.main",
                      cursor: "pointer",
                    }}
                  >
                    <TrashIcon style={{ width: "1.4em" }} />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        ))}
      </Grid>
      <CRUDModal
        title={"Delete skill: " + selectedSkill?.tech}
        open={deleteModal}
        setOpen={setDeleteModal}
        content={
          <div>
            <Typography style={{ margin: "0 10px" }}>
              Your are going to delete {selectedSkill?.tech ?? "a skill"}.
              <br></br>Are you sure ?
            </Typography>
          </div>
        }
        buttons={
          !loading ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  selectedSkill
                    ? deleteASkill(selectedSkill.id)
                    : setDeleteModal(false)
                }}
              >
                Delete
              </Button>
            </>
          ) : (
            <Box
              display={"flex"}
              justifyContent={"center"}
              sx={{ width: "100%" }}
            >
              <Spinner />
            </Box>
          )
        }
      />
      <CRUDModal
        title={"Edit skill: " + selectedSkill?.tech}
        open={updateModal}
        setOpen={setUpdateModal}
        content={
          <div>
            <Input
              backgroundColor="#EAEEF1"
              border="all"
              label="Skill name"
              widthMenu
              type="text"
              value={skillName}
              onHandleChange={setSkillName}
              customClass="flex-auto"
            />
          </div>
        }
        buttons={
          !loading ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setUpdateModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  if (skillName) {
                    selectedSkill
                      ? updateASkill(selectedSkill.id, skillName)
                      : setUpdateModal(false)
                  }
                }}
              >
                Edit
              </Button>
            </>
          ) : (
            <Box
              display={"flex"}
              justifyContent={"center"}
              sx={{ width: "100%" }}
            >
              <Spinner />
            </Box>
          )
        }
      />
    </div>
  )
}

export default SkillList
