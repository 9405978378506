import * as React from "react"
import OutlinedInput from "@mui/material/OutlinedInput"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import ListItemText from "@mui/material/ListItemText"
import Select from "@mui/material/Select"
import Checkbox from "@mui/material/Checkbox"
import "./MultipleSelectCheckmarks.scss"
import { Box, FormLabel } from "@mui/material"

interface MultipleSelectCheckmarksProps {
  arrayData: string[] | undefined
  placeholder: string | undefined
  itemHeight: number
  itemPadingTop: number
  value: string | string[] | undefined
  onChange: (value: any) => void
  label?: string
  disabled?: boolean
}

export const MultipleSelectCheckmarks = ({
  arrayData = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ],
  itemHeight = 48,
  itemPadingTop = 8,
  value = "",
  onChange,
  label,
  placeholder = "this is a dummy placeholder",
  disabled = false
}: MultipleSelectCheckmarksProps) => {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: itemHeight * 4.5 + itemPadingTop,
        width: 250,
      },
    },
  }

  return (
    <Box sx={{ display: "flex" }} flexDirection="column">
      <FormLabel>{label}</FormLabel>
      <FormControl
        sx={{ m: 1, width: 300 }}
        className="class-MultipleSelectCheckmarks"
      >
        <Select
          id="demo-multiple-checkbox"
          multiple
          displayEmpty
          value={value}
          onChange={onChange}
          disabled={disabled}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected: any) => {
            if (selected.length === 0) {
              return placeholder
            }
            return selected.join(", ")
          }}
          MenuProps={MenuProps}
        >
          {placeholder && (
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {arrayData.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={value.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
