import { Box, Grid } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import PandaCheckbox from 'src/components/atoms/PandaCheckbox'
import SearchBar from 'src/components/atoms/SearchBar'
import AdminTimeOffTable from 'src/components/moleculas/AdminTimeOffTable'
import withSidebar from 'src/components/organisms/Sidebar'
import StringHelper from 'src/helpers/string/StringHelper'
import { TimeOff, TimeOffStatus } from 'src/interfaces/timeOff.interfaces'
import timeOffService from 'src/services/timeOff/timeOff.service'

const TIME_OFF_STATUSES = [
  TimeOffStatus.PENDING,
  TimeOffStatus.APPROVED,
  TimeOffStatus.REJECTED,
  TimeOffStatus.EXPIRED,
]

const AdminTimeOff = () => {
  const [timeOffs, setTimeOffs] = useState<TimeOff[]>([])
  const [search, setSearch] = useState('')
  const [statuses, setStatuses] = useState<TimeOffStatus[]>(TIME_OFF_STATUSES)

  const currentTimeOffs = useMemo(() => {
    const searchLowerCase = search.toLowerCase()
    return timeOffs.filter((timeOff) => {
      const fullName = timeOff?.fullName?.toLowerCase()
      const email = timeOff?.email?.toLowerCase()
      return (
        (fullName?.includes(searchLowerCase) ||
          email?.includes(searchLowerCase)) &&
        statuses.includes(timeOff?.status)
      )
    })
  }, [timeOffs, search, statuses])

  useEffect(() => {
    timeOffService.getAll().then(setTimeOffs)
  }, [])

  const handleChangeStatus = (timeOffId: string, newStatus: TimeOffStatus) => {
    const newTimeOff = {
      ...timeOffs.find((timeOff:TimeOff) => timeOff.id === timeOffId),
      status: newStatus,
    } as TimeOff

    const newTimeOffs = timeOffs.filter(timeOff => timeOff.id !== timeOffId)

    timeOffService.update(newTimeOff).then((res) => {
      setTimeOffs([newTimeOff, ...newTimeOffs])
      if (res.statusCode === 200) {
        NotificationManager.success('Time off updated successfully')
      } else {
        NotificationManager.error('Error updating time off')
      }
    })
  }

  const handleChangeFilter = (status: TimeOffStatus, checked: boolean) => {
    if (checked) {
      setStatuses([...statuses, status])
    } else {
      setStatuses(statuses.filter((s) => s !== status))
    }
  }

  const getTotalTimeOffsByStatus = (status: TimeOffStatus) => {
    return timeOffs.filter((timeOff) => timeOff.status === status).length
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <h3 className="text-xl">Time Off:</h3>{' '}
      </Box>
      <Grid container spacing={3} pl={4} pr={4}>
        <Grid item xs={12} md={3} alignItems="center">
          <SearchBar
            label="Filter..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        {TIME_OFF_STATUSES.map((status, index) => (
          <Grid item xs={12} md={2} key={index}>
            <PandaCheckbox
              label={`${StringHelper.capitalize(
                status
              )} (${getTotalTimeOffsByStatus(status)})`}
              checked={statuses.includes(status)}
              onChange={(e, checked) => handleChangeFilter(status, checked)}
            />
          </Grid>
        ))}
        <Grid item sm={12} md={12}>
          <AdminTimeOffTable
            timeOffs={currentTimeOffs}
            onChange={handleChangeStatus}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default withSidebar(AdminTimeOff, { showProfileBar: true })
