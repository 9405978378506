export enum SkillLevel {
  trainee = 0,
  junior = 1,
  semiSenior = 2,
  senior = 3,
}

export interface iSkillFixed {
  skillLevel: SkillLevel
  id: string
  tech: string | undefined
  year?: string
}

export interface Tech {
  tech: string
}

export interface TechWithId extends Tech {
  id: string
  deleted?: boolean
}
export interface iUserSkill {
  id: string
  skillLevel: SkillLevel
}

export type UserAsking = {
  userId: string
  skillLevel: SkillLevel
}

export interface PendingSkill {
  usersAsking: UserAsking[]
  tech: string
  deleted: boolean
}
