import React from 'react';

export const IconDoc = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18.96'
      height='25.28'
      viewBox='0 0 18.96 25.28'
    >
      <g id='Group_875' data-name='Group 875' transform='translate(-29 -26)'>
        <path
          id='file-solid_1_'
          data-name='file-solid (1)'
          d='M0,3.16A3.16,3.16,0,0,1,3.16,0h7.9V6.32A1.578,1.578,0,0,0,12.64,7.9h6.32V22.12a3.163,3.163,0,0,1-3.16,3.16H3.16A3.161,3.161,0,0,1,0,22.12ZM12.64,6.32V0l6.32,6.32Z'
          transform='translate(29 26)'
        />
      </g>
    </svg>
  );
};
