import { Grid } from "@mui/material"
import UserTechs from "src/components/moleculas/UserTechs"

import "../TechLevel/TechLevel.scss"

interface iProps {
  isEdit: boolean
  editMethods: any
}

const TechLevel = ({ isEdit, editMethods }: iProps) => {
  return (
      <Grid
        className="class-TechLevel"
        container
        spacing={4}
        alignItems="end"
        pb={4}
      >
        <UserTechs isEdit={isEdit} editMethods={editMethods} />
      </Grid>
  )
}

export default TechLevel
