import React from "react"
import { DeleteOutline } from "@mui/icons-material"
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Avatar,
  styled,
} from "@mui/material"
import { IStakeHolder } from "src/interfaces/client.interfaces"
import panda from "src/assets/images/PandaCoolAhre.png"

interface IStakeHoldersTableProps {
  stakeHolders: IStakeHolder[]
  onDelete: (email: string) => void
  minHeight?: number
}

const EmployeesTableHead = styled(TableRow)(({ theme }) => ({
  "&": {
    boxShadow: "0px 3px 6px #1C212426",
    opacity: 1,
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  },
  "& th": {
    borderBottom: "0",
  },
}))

const StakeHoldersTable: React.FC<IStakeHoldersTableProps> = ({
  stakeHolders,
  onDelete,
  minHeight,
}) => {
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        size={"medium"}
      >
        <TableHead>
          <EmployeesTableHead>
            <TableCell>Name</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Email</TableCell>
            <TableCell></TableCell>
          </EmployeesTableHead>
        </TableHead>
        <TableBody>
          {stakeHolders.map((row) => (
            <TableRow key={row.email} sx={{ height: "70px" }}>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    alt="stakeholder photo"
                    src={
                      row.photoFile
                        ? URL.createObjectURL(row.photoFile)
                        : row?.photo?.url || panda
                    }
                    sx={{ marginRight: 3 }}
                  />
                  {row.name}
                </div>
              </TableCell>
              <TableCell>{row.role}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell width={1}>
                <IconButton
                  onClick={() => {
                    onDelete(row.email)
                  }}
                >
                  <DeleteOutline />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default StakeHoldersTable
