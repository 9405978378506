import { Control, Controller } from "react-hook-form"
import DatePicker from "../DatePicker"

interface Props {
  label: string
  control: Control
  name: string
  required?: boolean
  errorText?: string
  disabled?: boolean
  minDate?: any
  maxDate?: any
  isHookForm?: boolean
}

const FormDatePicker = ({
  required,
  name,
  control,
  label,
  disabled,
  minDate,
  maxDate,
  isHookForm = false,
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { message: "Field is required", value: !!required },
      }}
      render={({ field, fieldState }) => (
        <DatePicker
          {...field}
          isHookForm={isHookForm}
          label={label}
          error={!!fieldState.error}
          errorMessage={fieldState.error?.message}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
    />
  )
}

export default FormDatePicker
