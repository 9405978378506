import { FC } from "react"

import Clients from "src/pages/Clients"
import Employees from "src/pages/Employees"
import Profile from "src/pages/Profile"
import Projects from "src/pages/Projects"
import AdminTimeOff from "src/pages/AdminTimeOff"
import Roles from "src/pages/Roles"
import Skills from "src/pages/Skill/Skills"
import MyProfile from "src/pages/MyProfile"

export interface iRoute {
  name: string
  component: FC
  permissions?: string[]
  showInMenu?: boolean
  icon?: boolean
}

const mainRoutes: Record<string, iRoute> = {
  "/": {
    name: "Home",
    component: Employees,
    permissions: ["user:view"],
    showInMenu: true,
  },
  "/employees/*": {
    name: "Team Members",
    component: Employees,
    permissions: ["user:view"],
    showInMenu: true,
  },
  "/clients/*": {
    name: "Projects",
    component: Clients,
    permissions: ["client:view"],
    showInMenu: true,
  },
  "/projects/*": {
    name: "Projects",
    component: Projects,
    permissions: ["project:view"],
    showInMenu: true,
  },
  "/skills": {
    name: "Skills",
    component: Skills,
    permissions: ["skill:view"],
    showInMenu: true,
  },
  "/roles": {
    name: "Roles",
    component: Roles,
    permissions: ["role:view"],
    showInMenu: true,
  },
  "/admin-time-off": {
    name: "PTO",
    component: AdminTimeOff,
    permissions: ["pto:view"],
    showInMenu: true,
  },
  "/profile": {
    name: "User Profile",
    component: Profile,
    showInMenu: false, 
  },
  "/my-profile": {
    name: "My Profile",
    component: MyProfile,
    showInMenu: false, 
  }
}

export default mainRoutes