import {
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
  EyeIcon,
  PencilIcon,
} from "@heroicons/react/outline"
import {
  Avatar,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Tooltip,
} from "@mui/material"
import { Box } from "@mui/system"
import React, { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { iFilters, iUserWithId } from "src/interfaces/user.interfaces"
import authState from "src/recoil/auth"
import ROUTES from "src/types/Routes"
import ModalFilterEmployees from "../ModalFilterEmployees"
import Pagination from "../Pagination"
import { SENIORITY_OPTIONS } from "src/interfaces/user.interfaces"
import ProtectedComponent from "src/components/atoms/ProtectedComponent"
import GetAllocationTemporal from "src/pages/Employees/Hooks/AllocationComponent"

interface iProps {
  handleEditUser: (user: iUserWithId) => void
  employees: iUserWithId[]
  setFilters: (a: iFilters) => void
}

const EmployeesTableHead = styled(TableRow)(({ theme }) => ({
  "&": {
    boxShadow: "0px 3px 6px #1C212426",
    opacity: 1,
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  },
  "& th": {
    borderBottom: "0",
  },
}))

const EmployeesTableCell = styled(TableCell)(({ theme }) => ({
  "&": {
    border: 0,
  },
}))

const EmployeesTable: FC<iProps> = ({
  handleEditUser,
  employees,
  setFilters,
}) => {
  const navigate = useNavigate()

  const currentUser = useRecoilValue(authState.user)
  const [sortedByName, setUpSortedByName] =
    useState<boolean | undefined>(undefined)
  const [sortedByStatus, setUpSortedByStatus] =
    useState<boolean | undefined>(undefined)
  const [modalFilter, setModalFilter] = useState<boolean>(false)
  const [currentPageEmployees, setCurrentPageEmployees] =
    useState<iUserWithId[]>(employees)

  const sortByName = () => {
    let localSortedByName: boolean | undefined = undefined

    if (sortedByName === false) {
      localSortedByName = true
    } else if (sortedByName === undefined) {
      localSortedByName = false
    } else if (sortedByName === true) {
      localSortedByName = undefined
    }

    if (employees) {
      const newEmployees = [...employees]
      const sortedEmployees =
        localSortedByName === undefined
          ? newEmployees
          : newEmployees.sort((a, b) => {
              if (a.fullName < b.fullName) {
                return !localSortedByName ? -1 : 1
              }
              if (a.fullName > b.fullName) {
                return !localSortedByName ? 1 : -1
              }
              return 0
            })

      setCurrentPageEmployees(sortedEmployees)

      if (sortedByName === false) {
        setUpSortedByName(true)
      } else if (sortedByName === undefined) {
        setUpSortedByName(false)
      } else if (sortedByName === true) {
        setUpSortedByName(undefined)
      }

      setUpSortedByStatus(undefined)
    }
  }

  const sortByStatus = () => {
    let localSortedByStatus: boolean | undefined = undefined

    if (sortedByStatus === false) {
      localSortedByStatus = true
    } else if (sortedByStatus === undefined) {
      localSortedByStatus = false
    } else if (sortedByStatus === true) {
      localSortedByStatus = undefined
    }

    if (employees) {
      const newEmployees = [...employees]
      const sortedEmployees =
        localSortedByStatus === undefined
          ? newEmployees
          : newEmployees.sort((a, b) => {
              if (a.inBench < b.inBench) {
                return localSortedByStatus ? -1 : 1
              } else {
                return localSortedByStatus ? 1 : -1
              }
            })

      setCurrentPageEmployees(sortedEmployees)

      if (sortedByStatus === false) {
        setUpSortedByStatus(true)
      } else if (sortedByStatus === undefined) {
        setUpSortedByStatus(false)
      } else if (sortedByStatus === true) {
        setUpSortedByStatus(undefined)
      }

      setUpSortedByName(undefined)
    }
  }

  const handleRedirectProfile = (email: string, id: string) => {
    if (email === currentUser?.email) {
      navigate(ROUTES.PROFILE);
      // window.open(ROUTES.PROFILE, '_blank')
    } else {
      navigate(`${ROUTES.EMPLOYEES}/${id}`)
      // window.open(`${ROUTES.EMPLOYEES}/${id}`, '_blank')
    }
  }

  return (
    <Box mt={2}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
        >
          <TableHead>
            <EmployeesTableHead>
              <TableCell
                sx={{
                  justifyContent: "space-between",
                  lineHeight: "1.5rem",
                  cursor: "pointer",
                }}
                onClick={sortByName}
              >
                <Box
                  sx={{ paddingLeft: "5px" }}
                  display={"flex"}
                  justifyContent="space-between"
                >
                  Name
                  {sortedByName === undefined ? null : !sortedByName ? (
                    <ArrowCircleUpIcon style={{ width: "1em" }} />
                  ) : (
                    <ArrowCircleDownIcon style={{ width: "1em" }} />
                  )}
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  cursor: "pointer",
                }}
                onClick={sortByStatus}
              >
                <Box display={"flex"} justifyContent="space-between">
                  Status
                  {sortedByStatus === undefined ? null : !sortedByStatus ? (
                    <ArrowCircleUpIcon style={{ width: "1em" }} />
                  ) : (
                    <ArrowCircleDownIcon style={{ width: "1em" }} />
                  )}
                </Box>
              </TableCell>
              <TableCell>Allocation</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Seniority</TableCell>
              <TableCell>Primary Skill</TableCell>
              <TableCell>Location</TableCell>
              <TableCell></TableCell>
            </EmployeesTableHead>
          </TableHead>
          <TableBody>
            {currentPageEmployees?.map((person, i) => (
              <TableRow key={person.email + i} sx={{ height: "70px" }}>
                <EmployeesTableCell>
                  <Box display={"flex"}>
                    <Box>
                      <Avatar src={person.photoURL} alt={person.fullName} />
                    </Box>
                    <Box ml={2}>
                      <Typography>{person.fullName}</Typography>
                      <Typography>{person.email}</Typography>
                    </Box>
                  </Box>
                </EmployeesTableCell>
                <EmployeesTableCell>
                  <GetAllocationTemporal
                    id={person.id}
                    inBench={person.inBench}
                    isCurrentEmployee={person.isCurrentEmployee}
                    isChip={true}
                  />
                  {/* <Chip
                    label={
                      person.isCurrentEmployee ?
                        person.inBench
                          ? "In Bench"
                          : "In Project"
                        : "Inactive"
                    }
                    color={
                      person.isCurrentEmployee ?
                        person.inBench
                          ? "error"
                          : "info"
                      : "primary"
                    }
                  /> */}
                </EmployeesTableCell>
                <EmployeesTableCell>
                  <GetAllocationTemporal
                    id={person.id}
                    inBench={person.inBench}
                    isCurrentEmployee={person.isCurrentEmployee}
                  />
                </EmployeesTableCell>
                <EmployeesTableCell>
                  {person.companyRole ? person.companyRole.name : "Unknown"}
                </EmployeesTableCell>
                <EmployeesTableCell>
                  {person.seniority
                    ? SENIORITY_OPTIONS.filter(
                        (srty) => srty.value === person?.seniority
                      ).map((data) => data.label)
                    : "Unknown"}
                </EmployeesTableCell>
                <EmployeesTableCell>
                  {person.skillPrincipals ? person.skillPrincipals : "Unknown"}
                </EmployeesTableCell>
                <EmployeesTableCell>
                  {person.location?.description
                    ? person.location.description
                    : "Unknown"}
                </EmployeesTableCell>
                {/* <EmployeesTableCell>
                  <Box>
                    {person?.skills?.map((skill, i) => {
                      return i < 2 ? (
                        <ChipSmall
                          key={'chip' + skill.tech + i}
                          text={skill.tech}
                          action={() => null}
                          close={false}
                        />
                      ) : null
                    })}
                  </Box>
                </EmployeesTableCell> */}
                <EmployeesTableCell>
                  <Box
                    display={"flex"}
                    ml={"auto"}
                    sx={{ color: "primary.blue", display: "flex", gap: "20px" }}
                  >
                    <ProtectedComponent
                      requiredPermissions={
                        currentUser?.email === person.email
                          ? []
                          : ["user:update"]
                      }
                    >
                      <Tooltip title="View Profile" arrow>
                        <Box
                          ml={"auto"}
                          mr={1}
                          onClick={() =>
                            handleRedirectProfile(person.email, person.id)
                          }
                          sx={{
                            color: "primary.blue",
                          }}
                        >
                          <EyeIcon
                            style={{ width: "1.8em", cursor: "pointer" }}
                          />
                        </Box>
                      </Tooltip>
                    </ProtectedComponent>
                    <ProtectedComponent
                      requiredPermissions={
                        currentUser?.email === person.email
                          ? []
                          : ["user:update"]
                      }
                    >
                      <Tooltip title="Edit User" arrow>
                        <Box
                          onClick={() => handleEditUser(person)}
                          sx={{
                            color: "primary.blue",
                          }}
                        >
                          <PencilIcon
                            style={{ width: "1.65em", cursor: "pointer" }}
                          />
                        </Box>
                      </Tooltip>
                    </ProtectedComponent>
                  </Box>
                </EmployeesTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalFilterEmployees
        modalFilter={modalFilter}
        setModalFilter={setModalFilter}
        setFilters={setFilters}
      />
      <Pagination
        onChangeVisibleRecords={setCurrentPageEmployees}
        records={employees}
      />
    </Box>
  )
}

export default EmployeesTable
