import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import { COLLECTIONS, storage } from './firebase.service'

const TAG = 'STORAGE_SERVICE'

class StorageService {
  public async uploadFile(file: Blob, path: string): Promise<string | ''> {
    const storageRef = ref(storage, path)
    try {
      const result = await uploadBytes(storageRef, file)
      console.log({
        file,
        path,
        result,
        ref:result.ref
      })
      return await getDownloadURL(result.ref)
    } catch (e) {
      console.log(TAG, 'There was an issue', e)
      return ''
    }
  }

  public async deleteFile(path: string): Promise<boolean> {
    try {
      await deleteObject(ref(storage, path))
      return true
    } catch (e) {
      console.log(TAG, 'There was an issue', e)
      return false
    }
  }

  public async getFileDownloadURL(
    userUid: string,
    path: string
  ): Promise<string | ''> {
    const userPath = `${COLLECTIONS.USERS}/${userUid}/${path}`
    const storageRef = ref(storage, userPath)
    try {
      return await getDownloadURL(storageRef)
    } catch (e) {
      console.log(TAG, 'There was an issue', e)
      return ''
    }
  }
}

export default new StorageService()
