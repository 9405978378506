import { AppRole } from "src/interfaces/user.interfaces"

class UserHelper {
    public isAdmin = (permissions: string[]) => {
        return permissions.includes("*");
    }
}

export const hasSuperPermissions = (userPermissions: string[]) => userPermissions.includes("*")

export const hasManagementPermissionsOnResource = (userPermissions: string[], permission: string) => {
    const [resource] = permission.split(":")
    return userPermissions.includes(`${resource}:manage`)
}

export const hasPermissions = (userPermissions: string[]) => (permissions: string | string[]) => {
    if (!userPermissions || !Array.isArray(userPermissions) || userPermissions.length === 0) {
        return false
    }
    if (hasSuperPermissions(userPermissions)) {
        return true
    }

    if (Array.isArray(permissions)) {
        if (permissions.length === 0) {
            return true
        }
        return permissions.some((permission: string) => {
            if (userPermissions.includes(permission)) {
                return true
            } else {
                return hasManagementPermissionsOnResource(userPermissions, permission)
            }
        })
    }

    if (userPermissions.includes(permissions)) {
        return true
    } else {
        return hasManagementPermissionsOnResource(userPermissions, permissions)
    }
};

export default new UserHelper()