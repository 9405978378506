import { Grid } from "@mui/material"
import { useContext } from "react"
import FormInputText from "src/components/atoms/FormInputText"
import { RegisterContext } from "src/contexts/register.context"
import "../FormAbotMe/FormAboutMe.scss"

interface iProps {
  isEdit: boolean
  editMethods: any
}

const FormAboutMe = ({ isEdit, editMethods }: iProps) => {
  const { methods } = useContext(RegisterContext)

  const { control } = isEdit ? editMethods : methods

  return (
    <Grid className="class-FormAboutMe" container spacing={3} py={4}>
      <Grid item sm={12} md={4}>
        <FormInputText
          label="Nickname"
          placeholder="Nickname"
          name="nickname"
          control={control}
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <FormInputText
          label="Pet Name"
          placeholder="Pet Name"
          name="petName"
          control={control}
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <FormInputText
          label="Favorite Movie/TV Show"
          placeholder="Fav Movie"
          name="favoriteMovie"
          control={control}
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <FormInputText
          label="Superpower"
          placeholder="Superpower"
          name="superPower"
          control={control}
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <FormInputText
          label="Phobia"
          placeholder="Phobia"
          name="phobia"
          control={control}
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <FormInputText
          label="Fav holiday place"
          placeholder="Fav holiday place"
          name="favoritePlaces"
          control={control}
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <FormInputText
          label="What makes you laugh"
          placeholder="Laugh"
          name="mostLaughs"
          control={control}
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <FormInputText
          label="Fav Place"
          placeholder="Fav Place"
          name="favoriteWorldPlace"
          control={control}
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <FormInputText
          label="Fav Food"
          placeholder="Fav Food"
          name="favoriteFood"
          control={control}
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <FormInputText
          label="Fav Song/Band"
          placeholder="Fav Song/Band"
          name="favoriteSong"
          control={control}
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <FormInputText
          label="Fav Quote"
          placeholder="Fav Quote"
          name="favoriteQuotes"
          control={control}
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <FormInputText
          label="Fav Season"
          placeholder="Fav Season"
          name="favoriteSeason"
          control={control}
        />
      </Grid>
      {/* <Grid item sm={12} md={4}>
        <FormInputText
          label="Nomination"
          placeholder="INominate"
          name="nomination"
          control={control}
        />
      </Grid> */}
    </Grid>
  )
}

export default FormAboutMe
