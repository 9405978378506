import styled from "@emotion/styled"
import { DeleteOutline } from "@mui/icons-material"
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material"
import { skillLevelMapper } from "src/helpers/mappers/skillLevel.mapper"
import { iSkillFixed } from "src/interfaces/skills.interfaces"

interface Props {
  skills: iSkillFixed[]
  onDelete?: (id: string) => void
  minHeight?: number
}

const TechLevelTableHead = styled(TableRow)(({ theme }) => ({
  "&": {
    boxShadow: "0px 3px 6px #1C212426",
    opacity: 1,
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  },
  "& th": {
    borderBottom: "0",
  },
}))

const SkillsTable = ({ skills, onDelete, minHeight }: Props) => {
  return (
    <TableContainer
    >
      <Table aria-label="simple table">
        <TableHead>
          <TechLevelTableHead>
            <TableCell>Tech Name</TableCell>
            <TableCell>Level</TableCell>
            <TableCell>Years of Exp</TableCell>
            <TableCell></TableCell>
          </TechLevelTableHead>
        </TableHead>
        <TableBody>
          {skills.length > 0 ? (
            skills.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.tech}</TableCell>
                <TableCell>{skillLevelMapper[row.skillLevel]}</TableCell>
                <TableCell>{row.year}</TableCell>
                {onDelete && (
                  <TableCell width={1}>
                    <IconButton
                      onClick={() => {
                        onDelete(row.id)
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}>No skills added yet</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SkillsTable
