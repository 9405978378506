import { createContext } from 'react';
import { iUserWithId, DropdownInput } from 'src/interfaces/user.interfaces';
import { IProjectShow, iFilters } from 'src/interfaces/project.interface';

export const ProjectContext = createContext({
    arrayDirectFiltersProject:[{
        leable:'',
        onClick:()=>{},
        linkProperties:null,
        name:''
    }],
    clearFiltersProject:()=>{},
    userProject:{} as iUserWithId,
    loadingProject:false,
    projects:[] as IProjectShow[],
    listInputDropdownProject:[] as DropdownInput[],
    projectsFiltered:[] as IProjectShow[],
    filter: {} as iFilters,
    setFilter: (e:any)=>{}
});
