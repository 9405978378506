import React from 'react';

export const IconPen = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25.282'
      height='25.28'
      viewBox='0 0 25.282 25.28'
    >
      <path
        id='pen-solid_1_'
        data-name='pen-solid (1)'
        d='M17.929,1.5a3.169,3.169,0,0,1,4.479,0l1.948,1.949a3.166,3.166,0,0,1,0,4.476l-2.393,2.393L15.537,3.891Zm2.917,9.935L9.318,22.956a4.334,4.334,0,0,1-1.849,1.1L1.521,25.8a1.115,1.115,0,0,1-1.174-.341,1.088,1.088,0,0,1-.3-1.132L1.8,18.383a4.359,4.359,0,0,1,1.1-1.849L14.42,5.009Z'
        transform='translate(0 -0.572)'
      />
    </svg>
  );
};
