import { Box } from '@mui/system'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { CSSProperties, useRef } from 'react'
import FaIconButton from '../FaIconButton'

interface Props {
  onChange: (imageUrl: string) => void
  value?: string
}

const ProfileCoverPicture = ({ onChange, value }: Props) => {
  const fileInput = useRef(null)

  const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.[0]
    if (!image) {
      return
    }

    const imageUrl = URL.createObjectURL(image)
    onChange(imageUrl)
  }

  return (
    <Box
      sx={styles.container}
      // @ts-ignore
      onClick={() => fileInput.current?.click()}
    >
      <Box sx={styles.cover} component="img" alt="Cover Picture" src={value} />
      <FaIconButton icon={faCamera} style={styles.fa as CSSProperties} />
      <input
        hidden
        type="file"
        // @ts-ignore
        ref={fileInput}
        className="hidden"
        accept="image/*"
        onChange={handleChangeImage}
      />
    </Box>
  )
}

const styles = {
  container: {
    position: 'relative',
    cursor: 'pointer',
  },
  cover: {
    display: 'block',
    height: 150,
    width: '100%',
    backgroundColor: '#f5f5f5',
    borderRadius: 2,
  },
  fa: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    height: 40,
    width: 40,
  },
}

export default ProfileCoverPicture
