import React from "react"
import {
  Typography,
  Box,
  Avatar,
  Link,
  Chip,
  Card,
  CardContent,
} from "@mui/material"
import EmailIcon from "@mui/icons-material/Email"
import { IStakeHolder } from "src/interfaces/client.interfaces"
import panda from "src/assets/images/PandaCoolAhre.png"

interface IStakeHolderCardProps {
  stakeholder: IStakeHolder
}

const StakeHolderCard: React.FC<IStakeHolderCardProps> = ({ stakeholder }) => {
  return (
    <Card
      sx={{
        padding: "0.6rem",
        width: "fit-content",
        borderRadius: "30px",
        boxShadow: "0px 3px 6px #1C21244F",
        background: "#EAEEF1",
      }}
    >
      <CardContent>
        <Box display="flex">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mr={3}
          >
            <Avatar
              src={stakeholder?.photo?.url || panda}
              alt="stakeholder_photo"
              sx={{
                width: 70,
                height: 70,
              }}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="start"
            flexDirection="column"
          >
            <Typography variant="body1" style={{ paddingBottom: 5 }}>
              {stakeholder.name}
            </Typography>
            <Chip label={stakeholder.role} color="primary" />
          </Box>
        </Box>
        <Box mt={1} display="flex" alignItems="center">
          <Typography style={{ color: "#0083D2", marginTop: 6 }}>
            <EmailIcon />
          </Typography>
          <Link href={`mailto:${stakeholder.email}`} target="_blank" ml={1}>
            <Typography style={{ color: "#0083D2" }}>
              {stakeholder.email}
            </Typography>
          </Link>
        </Box>
      </CardContent>
    </Card>
  )
}

export default StakeHolderCard
