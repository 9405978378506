import { Box, Button, Typography } from "@mui/material"
import CRUDModal from "src/components/moleculas/CRUDModal"
import Spinner from "src/components/moleculas/Spinner/Index"
import withSidebar from "src/components/organisms/Sidebar"
import { Input } from "src/stories/components/ui/atoms"
import SkillList from "src/components/organisms/SkillList"
import ProtectedComponent from "src/components/atoms/ProtectedComponent"
import useSkills from "./use-skills"

const Skills = () => {
  const {
    printedSkills,
    getAllSkills,
    createModal,
    setCreateModal,
    newSkill,
    setNewSkill,
    loading,
    addNewSkill,
  } = useSkills()

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Typography sx={{ lineHeight: "24px", fontWeight: 500 }}>
          Skills
        </Typography>
        <ProtectedComponent requiredPermissions={["skill:create"]}>
          <Button
            onClick={() => setCreateModal(true)}
            type="button"
            variant="contained"
            sx={{
              backgroundColor: "primary.blue",
              borderRadius: "30px",
              width: "200px",
            }}
          >
            Add Skill
          </Button>
        </ProtectedComponent>
      </Box>
      <SkillList skills={printedSkills} refreshSkills={getAllSkills} />
      <CRUDModal
        title={"Add Skill"}
        open={createModal}
        setOpen={setCreateModal}
        content={
          <div>
            <Input
              backgroundColor="#EAEEF1"
              border="all"
              label="Skill name"
              widthMenu
              type="text"
              value={newSkill}
              onHandleChange={setNewSkill}
              customClass="flex-auto"
            />
          </div>
        }
        buttons={
          !loading ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCreateModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  if (newSkill.length > 0) {
                    addNewSkill()
                  }
                }}
              >
                Add
              </Button>
            </>
          ) : (
            <Box
              display={"flex"}
              justifyContent={"center"}
              sx={{ width: "100%" }}
            >
              <Spinner />
            </Box>
          )
        }
      />
    </>
  )
}

export default withSidebar(Skills, { showProfileBar: true })
