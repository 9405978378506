import React from "react"
import { Controller, Control } from "react-hook-form"
import { Box, Select, MenuItem, FormLabel, FormHelperText } from "@mui/material"

interface iProps {
  label: string
  name: string
  control: Control
  options: { value: string | number | any; label: string }[]
  required?: boolean
  disabled?: boolean
}

const FormSelect = ({
  label,
  options,
  disabled,
  name,
  control,
  required,
}: iProps) => {
  const generateSelectOptions = () => {
    return options.map((option, key) => (
      <MenuItem key={key} value={option.value} sx={Styles.option}>
        {option.label}
      </MenuItem>
    ))
  }

  return (
    <Box sx={{ display: "flex" }} flexDirection="column">
      <FormLabel>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={{
          required: { message: "Field is required", value: !!required },
        }}
        render={({ field, fieldState }) => (
          <>
            <Select
              value={field.value}
              onChange={field.onChange}
              disabled={disabled}
              displayEmpty
              error={!!fieldState.error}
              classes={{
                select: "1000px 1000px",
              }}
              sx={Styles.select}
            >
              <MenuItem key={"none"} value="">
                <em>None</em>
              </MenuItem>
              {generateSelectOptions()}
            </Select>
            <FormHelperText sx={{ marginLeft: "14px", color: "red" }}>
              {/* Always have some text to prevent misalignments with its siblings */}
              {fieldState.error?.message || " "}
            </FormHelperText>
          </>
        )}
      />
    </Box>
  )
}

const Styles = {
  select: {
    color: "primary.dark",
    "& .MuiInputBase-input": {
      borderRadius: 5,
      color: "primary.main",
      padding: 2,
      paddingLeft: 3,
    },
    "& fieldset": {
      borderRadius: 5,
      color: "primary.main",
    },
    "& input": {
      color: "red",
    },
  },
  option: {
    color: "primary.dark",
  },
}

export default FormSelect
