import { iSkillFixed } from "src/interfaces/skills.interfaces"
import { Role } from "./roles.interfaces"
import { TimeOff } from "./timeOff.interfaces"

export enum AppRole {
  superAdmin = 0,
  admin = 1,
  user = 2,
}

export enum EnglishLevel {
  preIntermediate = 2,
  intermediate = 3,
  upperIntemediate = 4,
  advanced = 5,
  native = 6,
}

export interface Certification {
  id: string
  company: string
  title: string
  date: string
  url?: string
  attachedFile?: string
}

export enum Seniority {
  Manager = 6,
  Lead = 5,
  Expert = 4,
  Senior = 3,
  SemiSenior = 2,
  Junior = 1,
}

export interface iUser {
  fullName: string
  email: string
  firebaseId: string
  photoURL: string
  phoneNumber?: string
  skills?: iSkillFixed[]
  lastUpdated: string
  creationTime: string
  role: AppRole
  englishLevel: EnglishLevel
  isCurrentEmployee: boolean
  birthDate: string
  inBench: boolean
  inBenchSince: string
  pendingSkills?: iSkillFixed[]
  bio: string
  companyRole: Role
  certifications: Certification[]
  isFirstLogin: boolean | undefined
  linkedin: string
  github: string
  portfolio: string
  location: Location
  nationality: Location
  countryId: string
  stateId: string
  cityId: string
  nickname: string
  petName: string
  favoriteShow: string
  superPower: string
  phobia: string
  favoritePlaces: string
  mostLaughs: string
  favoriteWorldPlace: string
  favoritePlaceHoliday: string
  favoriteFood: string
  favoriteMovie: string
  favoriteQuotes: string
  favoriteBand: string
  favoriteSong: string
  nomination: string
  coverPhotoURL: string
  aboutMe: string
  seniority: Seniority
  timeOffs: TimeOff[]
  ptosAvailable: number
  salary?: number
  skillPrincipals?: string
  favoriteSeason: string
  companyStartDate: any
  companyEndDate?: any
  permissions?: string[]
  gender: string
}

export interface iUserWithId extends iUser {
  id: string
}
export interface iFilters {
  fullName?: string
  onBench?: boolean
  skillsId: string
  role: AppRole
  englishLevel?: EnglishLevel | string
  birthDate?: string
  companyRoleName: string
  location: Location
  seniority?: Seniority | string
  email?: string
  isCurrentEmployee: boolean
  skillPrincipals: string
}

export interface Location {
  description: string
  terms: { offset: number; value: string }[]
}

export interface DropdownInput {
  labelName: string
  arrayOptions:
    | string
    | string[]
    | { value: string | Seniority | EnglishLevel | undefined; label: string }[]
  onChange: (e: any) => void
  value: string | EnglishLevel | Seniority | undefined
}

export const SENIORITY_OPTIONS = [
  { value: Seniority.Manager, label: "Manager" },
  { value: Seniority.Lead, label: "Lead" },
  { value: Seniority.Expert, label: "Expert" },
  { value: Seniority.Senior, label: "Senior" },
  { value: Seniority.SemiSenior, label: "Semi Senior" },
  { value: Seniority.Junior, label: "Junior" },
]

export const ENGLISH_LEVEL_OPTIONS = [
  { value: "1", label: "Unknown" },
  { value: "2", label: "Pre-Intermediate" },
  { value: "3", label: "Intermediate" },
  { value: "4", label: "Upper-Intemediate" },
  { value: "5", label: "Advanced" },
  { value: "6", label: "Native" },
]

export const GENDER_OPTIONS = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
]
