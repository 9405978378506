import React, { useEffect } from 'react'
import './Input.css'
import TextField from '@mui/material/TextField'

interface InputProps {
  label?: string
  backgroundColor?: string
  border?: 'all' | 'left' | 'right'
  widthMenu?: boolean
  type?: 'text' | 'number' | 'date' | 'email' | 'search'
  size?: 'small' | 'medium'
  onHandleChange?: (value: any) => void
  value?: string
  customClass?: string
  name?: string
}

export const Input = ({
  label,
  backgroundColor,
  border,
  type,
  widthMenu = false,
  size,
  onHandleChange = () => ({}),
  value = '',
  customClass,
  name
}: InputProps) => {
  useEffect(() => {
    if (onHandleChange) {
      switch (type) {
        case 'text':
          onHandleChange('')
          break
        case 'number':
          onHandleChange(0)
          break
        case 'date':
          onHandleChange(new Date().toISOString().slice(0, 10))
          break
        default:
          onHandleChange(null)
      }
    }
  }, [type])

  return (
    <TextField
      id="outlined-basic"
      name={name}
      label={label}
      variant="outlined"
      className={
        (type === 'date' ? ' class-inputDate ' : '') +
        ' class-input-clasic ' +
        (border === 'all'
          ? 'class-all-border '
          : border === 'left'
          ? 'class-border-left '
          : border === 'right'
          ? 'class-border-right '
          : ' ') +
        (widthMenu === true ? ' class-input-widthMenu ' : ' ') + (customClass || "")
      }
      style={{ backgroundColor }}
      type={type}
      value={value}
      placeholder=""
      onChange={(e) => onHandleChange(e.target.value)}
      size={
        size === 'small' ? 'small' : size === 'medium' ? 'medium' : undefined
      }
    />
  )
}
