/* This example requires Tailwind CSS v2.0+ */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { FC, Fragment, ReactFragment, useRef } from 'react'
import Slide from '@mui/material/Slide'
import React from 'react'
import { TransitionProps } from '@mui/material/transitions'
import { Box } from '@mui/system'
// import { ExclamationIcon } from '@heroicons/react/outline'

interface iModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  content: string | JSX.Element
  title: string
  buttons?: JSX.Element | ReactFragment
  icon?: JSX.Element
  onSubmit?: any
  buttonTop?: JSX.Element | ReactFragment | undefined | null | false
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CRUDModal: FC<iModalProps> = ({
  buttonTop,
  open,
  setOpen,
  content,
  title,
  buttons,
  icon,
  onSubmit,
}) => {
  // const cancelButtonRef = useRef(null)

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <form style={{borderRadius: "20px"}} onSubmit={onSubmit}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyItems: 'center',
          }}
        >
          {icon && icon}
          <Box my={'auto'} ml={2}>
            {title}
          </Box>
          <Box ml={'auto'}>{buttonTop}</Box>
        </DialogTitle>
        <DialogContent>
          {content}
          {/* <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>{buttons}</DialogActions>
      </form>
    </Dialog>
  )
}

export default CRUDModal
