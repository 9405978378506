import React, {FC, useState} from "react"
import {NotificationManager} from "react-notifications"
import {TrashIcon} from "@heroicons/react/outline"
import PencilIcon from "@heroicons/react/solid/PencilIcon"
import {Box, Button, Grid, Tooltip, Typography} from "@mui/material"
import CRUDModal from "src/components/moleculas/CRUDModal"
import Spinner from "src/components/moleculas/Spinner/Index"
import {RoleWithId} from "src/interfaces/roles.interfaces"
import RolesService from "src/services/roles/roles.service"
import {Input} from "src/stories/components/ui/atoms"
import "../../../pages/Roles/Roles.scss"
import ProtectedComponent from "../../atoms/ProtectedComponent";

interface iProps {
  roles: RoleWithId[] | undefined
  refreshRoles: () => void
}

const RoleList: FC<iProps> = ({roles = [], refreshRoles}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false)
  const [selectedRole, setSelectedRole] =
    useState<RoleWithId | undefined>(undefined)
  const [roleName, setRoleName] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const handleDeleteRole = async (id: string) => {
    setLoading(true)
    const response = await RolesService.deleteRole(id)
    if (response) {
      NotificationManager.success("Role deleted successfully", "Success", 750)
      refreshRoles()
    }
    setLoading(false)
    setShowDeleteModal(false)
  }

  const handleUpdateRole = async (id: string, role: string) => {
    setLoading(true)
    const response = await RolesService.updateRole(id, role)
    if (response) {
      NotificationManager.success("Role updated successfully", "Success", 750)
      refreshRoles()
    }
    setLoading(false)
    setShowUpdateModal(false)
  }

  return (
    <div>
      <Grid
        display="flex"
        className="gap-5-md"
        sx={{
          width: "100%",
          overflow: "auto",
          flexWrap: "wrap",
          justifyContent: "space-between",
          flexDirection: "row",
          alignContent: "stretch",
        }}
      >
        {roles?.map((role, key) => (
          <Box
            className="width-49-md"
            key={key}
            sx={{
              background: "#EAEEF1",
              borderRadius: "20px",
              width: "33%",
              margin: "6px 0",
            }}
          >
            <Box
              key={role.id}
              sx={{
                display: "flex",
                padding: "20px",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{color: "#828689", lineHeight: "24px", fontWeight: 500}}
              >
                {role.name}
              </Typography>
              <Box sx={{display: "inline-flex", alignItems: "center"}}>
                <ProtectedComponent requiredPermissions={["role:update"]}>
                  <Tooltip title="Edit Role" arrow>
                    <Box
                      onClick={() => {
                        setRoleName(role.name)
                        setShowUpdateModal(true)
                        setSelectedRole(role)
                      }}
                      sx={{
                        color: "primary.blue",
                        cursor: "pointer",
                      }}
                    >
                      <PencilIcon style={{width: "1.4em"}}/>
                    </Box>
                  </Tooltip>
                </ProtectedComponent>
                <ProtectedComponent requiredPermissions={["role:delete"]}>
                  <Tooltip title="Delete Role" arrow>
                    <Box
                      onClick={() => {
                        setShowDeleteModal(true)
                        setSelectedRole(role)
                      }}
                      sx={{
                        color: "error.main",
                        cursor: "pointer",
                      }}
                    >
                      <TrashIcon style={{width: "1.4em"}}/>
                    </Box>
                  </Tooltip>
                </ProtectedComponent>
              </Box>
            </Box>
          </Box>
        ))}
      </Grid>
      <CRUDModal
        title={"Delete role: " + selectedRole?.name}
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        content={
          <div>
            <Typography style={{margin: "0 10px"}}>
              Your are going to delete {selectedRole?.name ?? "a role"}.
              <br></br>Are you sure ?
            </Typography>
          </div>
        }
        buttons={
          !loading ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  selectedRole
                    ? handleDeleteRole(selectedRole.id)
                    : setShowDeleteModal(false)
                }}
              >
                Delete
              </Button>
            </>
          ) : (
            <Box
              display={"flex"}
              justifyContent={"center"}
              sx={{width: "100%"}}
            >
              <Spinner/>
            </Box>
          )
        }
      />
      <CRUDModal
        title={"Edit role: " + selectedRole?.name}
        open={showUpdateModal}
        setOpen={setShowUpdateModal}
        content={
          <div>
            <Input
              backgroundColor="#EAEEF1"
              border="all"
              label="Role name"
              widthMenu
              type="text"
              value={roleName}
              onHandleChange={setRoleName}
              customClass="flex-auto"
            />
          </div>
        }
        buttons={
          !loading ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowUpdateModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  if (roleName) {
                    selectedRole
                      ? handleUpdateRole(selectedRole.id, roleName)
                      : setShowUpdateModal(false)
                  }
                }}
              >
                Edit
              </Button>
            </>
          ) : (
            <Box
              display={"flex"}
              justifyContent={"center"}
              sx={{width: "100%"}}
            >
              <Spinner/>
            </Box>
          )
        }
      />
    </div>
  )
}

export default RoleList
