import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { TechWithId } from 'src/interfaces/skills.interfaces'
import { getSkills, addSkill } from 'src/services/skills/skills.service'

export default function useSkills() {
  const [printedSkills, setPrintedSkills] = useState<TechWithId[]>()
  const [newSkill, setNewSkill] = useState<string>('')
  const [createModal, setCreateModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [techs, setTechs] = useState<TechWithId[]>([])

  const getAllSkills = async () => {
    const newSkills = await getSkills()
    if (newSkills.response) {
      setTechs(newSkills.response)
      setPrintedSkills(newSkills.response)
    }
  }

  const addNewSkill = async () => {
    setLoading(true)
    const result = await addSkill(newSkill)
    if (result.response) {
      NotificationManager.success('Skill added successfully', 'Success', 750)
      console.log(result.response)
      getAllSkills()
      setCreateModal(false)
    }
    setLoading(false)
    setNewSkill('')
  }

  useEffect(() => {
    setPrintedSkills(techs)
    getAllSkills()
  }, [])

  return {
    printedSkills,
    getAllSkills,
    createModal,
    setCreateModal,
    newSkill,
    setNewSkill,
    loading,
    addNewSkill,
  }
}
