import React, { useMemo } from 'react'
import { Chip } from "@mui/material"
import useProjects from "../../Projects/ViewProjects/use-projects";

export default function GetAllocationTemporal(props:{
    id:string,
    inBench:boolean,
    isCurrentEmployee:boolean,
    isChip?:boolean,
    isOnProfile?:boolean
  }) {
    const { projectsFiltered } = useProjects();
    const getNumberAllocationValueObj = useMemo(()=>{
      let getNumberAllocation = 0;

      const result = (num:number) => getNumberAllocation += num
      projectsFiltered.map(project => project.employees.filter(user => user.id === props.id && result(user.allocationPercentage)));

      return {
        getNumberAllocation,
        condition: getNumberAllocation == 0,
        conditionBench: getNumberAllocation < 50,
      }
    },[projectsFiltered])

    // && props.inBench
    return props.isChip ? <Chip
      label={ props.isCurrentEmployee ? props.inBench || getNumberAllocationValueObj.conditionBench ? "In Bench" : "In Project" : "Inactive"}
      color={ props.isCurrentEmployee ? props.inBench || getNumberAllocationValueObj.conditionBench ? "error" : "info" : "primary"}
    /> : props.isOnProfile ? <Chip
      label={ props.isCurrentEmployee ? getNumberAllocationValueObj.conditionBench ? `In Bench ${getNumberAllocationValueObj.getNumberAllocation}%` : `In Project ${getNumberAllocationValueObj.getNumberAllocation}%` : "Inactive"}
      color={props.isCurrentEmployee ? getNumberAllocationValueObj.conditionBench ? "error" : "info" : "primary"}
    /> : getNumberAllocationValueObj.condition || !props.isCurrentEmployee ? <>--</> : <>{getNumberAllocationValueObj.getNumberAllocation}%</>
}