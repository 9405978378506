import React, { FC } from "react"
import { Route, Routes } from "react-router-dom"

import { iUserWithId } from "../interfaces/user.interfaces"
import { hasPermissions } from "../helpers/user/UserHelper"
import ObjectHelper from "../helpers/object/ObjectHelper"
import Login from "../pages/Login"
import ROUTES from "../types/Routes"

import mainRoutes from "./main.routes"

interface iPropsRoutes {
  user?: iUserWithId
}

const Router: FC<iPropsRoutes> = ({ user }) => {
  const userHasPermission =  hasPermissions([...(user?.permissions || []), ...(user?.companyRole?.permissions || [])])
  const routesWithPermissions = Object.keys(mainRoutes)
    .filter((routeKey: string) => userHasPermission(mainRoutes[routeKey].permissions || []))

  if (!ObjectHelper.hasProperties(user)) {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path={ROUTES.LOGIN} element={<Login />} />
      </Routes>
    )
  }
  return (
    <Routes>
      {routesWithPermissions.map((routeKey: string) => {
        const { component: Component } = mainRoutes[routeKey]
        return (
          <Route
            key={`route-${routeKey}`}
            path={routeKey}
            element={<Component />}
          />
        )
      })}
    </Routes>
  )
}

export default Router
