import { Box, Select, MenuItem, Typography, FormLabel } from '@mui/material'
import React, { FC } from 'react'

interface iProps {
  label: string
  value: string | number | undefined | any
  onChange: (newValue: any) => void
  options: { value: string | number | any; label: string }[]
  placeholder?: string
  disabled?: boolean
  error?: boolean
  helperText?: any
  disableNone?: boolean
}

const SelectProfile: FC<iProps> = ({
  label,
  value,
  onChange,
  options,
  placeholder,
  disabled,
  error,
  helperText,
  disableNone,
}) => {
  const handleChange = (event: any) => {
    onChange(event.target.value)
  }

  return (
    <Box sx={{ display: 'flex' }} flexDirection="column">
      <FormLabel>{label}</FormLabel>
      <Select
        value={value ?? ''}
        onChange={handleChange}
        disabled={disabled}
        displayEmpty
        classes={{
          select: '1000px 1000px',
        }}
        sx={{
          color: 'primary.dark',
          '& .MuiInputBase-input': {
            borderRadius: 5,
            color: 'primary.main',
            padding: 2,
            paddingLeft: 3,
          },
          '& fieldset': {
            borderRadius: 5,
            color: 'primary.main',
          },
          '& input': {
            color: 'red',
          },
        }}
        error={error}
      >
        <MenuItem key={'none'} value="" disabled={disableNone}>
          <em>None</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              color: 'primary.dark',
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <Typography variant="caption" color={error ? 'error' : 'inherit'}>
        {helperText}
      </Typography>
    </Box>
  )
}

export default SelectProfile
