import { iUserWithId } from "./user.interfaces";

export enum TimeOffStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    CANCELLED = 'CANCELLED',
    EXPIRED = 'EXPIRED',
}

export enum TimeOffType {
    PAID = 'PAID',
    UNPAID = 'UNPAID',
    COMPENSATORY = 'COMPENSATORY',
}

export interface TimeOff {
    startDate: string;
    endDate: string;
    userId: string;
    id: string;
    status: TimeOffStatus;
    createdAt: string;
    deleted: boolean;
    type: TimeOffType;
    admin: iUserWithId;
    fullName?: string;
    email?: string;
}