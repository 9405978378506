// context for firebase user data
import React, { createContext, FC, ReactNode, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import ObjectHelper from 'src/helpers/object/ObjectHelper'
import { RoleWithId } from 'src/interfaces/roles.interfaces'
import authState from 'src/recoil/auth'
import { getRoles } from 'src/services/roles/roles.service'

interface ISkillsController {
  roles: RoleWithId[]
  setRoles: (raw: RoleWithId[]) => void
}

const INITIAL_STATE: RoleWithId[] = []

export const RolesContext = createContext<ISkillsController>({
  roles: INITIAL_STATE,
  setRoles: () => {},
})

interface iProps {
  children: ReactNode
}

const RolesContextProvider: FC<iProps> = ({ children }) => {
  const currentUser = useRecoilValue(authState.user)
  const [roles, setRoles] = useState<RoleWithId[]>(INITIAL_STATE)

  useEffect(() => {
    if (ObjectHelper.hasProperties(currentUser)) {
      getRoles().then(setRoles)
    }
  }, [currentUser])
  console.log(currentUser, roles)
  return (
    <RolesContext.Provider value={{ roles, setRoles }}>
      {children}
    </RolesContext.Provider>
  )
}

export default RolesContextProvider
