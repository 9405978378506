import { Routes, Route, Navigate } from "react-router-dom"

import { ProjectContext } from "./ViewProjects/project-context"
import useProjects from "./ViewProjects/use-projects"
import ViewProjects from "./ViewProjects"
import ProjectDetails from "./ProjectDetails"
import ProjectForm from "./ProjectForm"

import ProtectedComponent from "src/components/atoms/ProtectedComponent"

const ProjectsContainer = () => {
  const projectFuncs = useProjects()
  return (
    <ProjectContext.Provider
      value={projectFuncs}
    >
      <Routes>
        {/* <Route index element={<ViewProjects />} /> */}
        <Route index element={
          <ProtectedComponent requiredPermissions={["project:view", "user:view"]} fallback={<Navigate to="/" />}>
            <ViewProjects />
          </ProtectedComponent>
        } />
        <Route path="/:idProjects" element={
          <ProtectedComponent requiredPermissions={["project:read"]} fallback={<Navigate to="/projects" />}>
            <ProjectDetails />
          </ProtectedComponent>
        } />
        <Route path="/create" element={
          <ProtectedComponent requiredPermissions={["project:create"]} fallback={<Navigate to="/projects" />}>
            <ProjectForm />
          </ProtectedComponent>
        } />
        <Route path="/:id/edit" element={
          <ProtectedComponent requiredPermissions={["project:update"]} fallback={<Navigate to="/projects" />}>
            <ProjectForm />
          </ProtectedComponent>
        } />
      </Routes>
    </ProjectContext.Provider>
  )
}

export default ProjectsContainer
