import * as React from "react"
import {
  Grid,
  Box,
  TextField,
  Typography,
  InputAdornment,
  FormLabel,
  Autocomplete,
} from "@mui/material"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import parse from "autosuggest-highlight/parse"
import throttle from "lodash/throttle"
import pandaConfig from "src/config/pandaConfig"
import "./GoogleInput.scss"

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = pandaConfig.GOOGLE_MAPS

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement("script")
  script.setAttribute("async", "")
  script.setAttribute("id", id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }

interface MainTextMatchedSubstrings {
  offset: number
  length: number
}
interface StructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[]
}
interface PlaceType {
  description: string
  structured_formatting: StructuredFormatting
}

interface Input {
  input: string
  types?: string[]
}

export default function GoogleInput(props: any) {
  const {
    handleValue,
    label,
    variant,
    helperText,
    error,
    comingValue,
    onlyCountries,
    disabled,
    sx,
  } = props
  const [value, setValue] = React.useState<PlaceType | null>(
    comingValue || null
  )
  const [inputValue, setInputValue] = React.useState("")
  const [options, setOptions] = React.useState<readonly PlaceType[]>([])
  const loaded = React.useRef(false)

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      )
    }

    loaded.current = true
  }

  const fetch = React.useMemo(
    () =>
      throttle((request: { input: string }, callback: () => void) => {
        ;(autocompleteService.current as any).getPlacePredictions(
          request,
          callback
        )
      }, 200),
    []
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === "") {
      setOptions(value ? [value] : [])
      return undefined
    }

    const input = onlyCountries
      ? {
          input: inputValue,
          types: ["country"],
        }
      : {
          input: inputValue,
        }

    fetch(input as Input, (results?: readonly PlaceType[]) => {
      if (active) {
        setOptions(results ? results : [])
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch, onlyCountries])

  React.useEffect(() => {
    if (comingValue) {
      setValue(comingValue)
    }
  }, [comingValue])

  return (
    <Autocomplete
      className="class-GoogleInput"
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      noOptionsText={"Start typing to search..."}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      isOptionEqualToValue={(option, value) =>
        option.description === value.description
      }
      disabled={disabled}
      onChange={(_, newValue: PlaceType | null) => {
        handleValue(newValue)
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
      }}
      sx={sx}
      renderInput={(params) => (
        <>
          <FormLabel>{label}</FormLabel>
          <TextField
            {...params}
            key={"google-input"}
            variant={variant}
            hiddenLabel
            error={error}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: 5,
                },
                "& textarea": {
                  "&:focus": {
                    border: "none",
                    overFlow: "auto",
                    outline: "none",
                    WebkitBoxShadow: "none",
                  },
                },
              },
              "& input": {
                borderRadius: 5,
                "&:focus": {
                  borderRadius: 5,
                },
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <LocationOnIcon color={disabled ? "disabled" : "action"} />
                </InputAdornment>
              ),
            }}
          />
          <Typography
            variant="caption"
            color="error"
            sx={{ marginLeft: "14px" }}
          >
            {helperText}
          </Typography>
        </>
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        )

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: "text.secondary", mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index + "-" + part}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}
