import { loginWithGoogle } from 'src/services/firebase/login'
import azumoLogo from '../../../stories/assets/img/Group8622x.png'
import GoogleLogginButton from 'src/components/moleculas/GoogleLogginButton/Index'
import Spinner from 'src/components/moleculas/Spinner/Index'
import { Box } from '@mui/material'
import { useState } from 'react'

const LoginBox = () => {
  const [loading, setLoading] = useState(false)

  const loginHandle = async () => {
    setLoading(true)

    try {
      await loginWithGoogle()
    } catch (e) {
      console.log(e)
    }

    setLoading(false)
  }

  return (
    <Box my={'auto'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box
          component="img"
          sx={{
            width: 233,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="Azumo Panda"
          src={azumoLogo}
          style={{
            // marginTop: '280px',
            marginBottom: 'auto',
          }}
        />
        <br />
        <GoogleLogginButton onClick={loginHandle} />
        {loading && (
          <div className="flex justify-center my-3">
            <Spinner />
          </div>
        )}
      </Box>
    </Box>
  )
}

export default LoginBox
