import React, { useState, useEffect, useRef } from "react"
import { faFile } from "@fortawesome/free-solid-svg-icons"
import { Typography, Grid } from "@mui/material"
import FaIconButton from "../FaIconButton"

interface Props {
  onChange: (imageUrl: string) => void
  value?: string
  // When needing to get the File instead of url
  isUsingFile?: boolean
  fileName?: string
  onFileChange?: (file: File) => void
}

const InputUploadFile = ({
  onChange,
  value,
  isUsingFile,
  fileName,
  onFileChange,
}: Props) => {
  const [localFileName, setLocalFileName] = useState<string>("Select your file")

  const fileInput = useRef(null)

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (!file) {
      return
    }

    const fileType = file?.type.split("/")[1]
    if (fileType !== "pdf") {
      alert("Only PDF files allowed")
      return
    }

    const fileUrl = URL.createObjectURL(file)

    onChange(fileUrl)
    setLocalFileName(file.name)
    isUsingFile && onFileChange && onFileChange(file)
  }

  useEffect(() => {
    if (fileName) setLocalFileName(fileName)
  }, [fileName])

  return (
    <Grid
      // @ts-ignore
      onClick={() => fileInput.current?.click()}
      flexDirection="row"
      display="flex"
      alignItems="center"
      gap={2}
    >
      <FaIconButton icon={faFile} color="success" />
      <input
        hidden
        type="file"
        // @ts-ignore
        ref={fileInput}
        className="hidden"
        accept="application/pdf"
        onChange={handleChangeFile}
      />
      <Typography>
        {isUsingFile
          ? localFileName
          : value
          ? "File selected"
          : "Select your file"}
      </Typography>
    </Grid>
  )
}

export default InputUploadFile
