import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, IconButtonProps } from '@mui/material'

//extends from material ui icon button
interface Props extends IconButtonProps {
  icon: IconDefinition
}

const FaIconButton = ({ icon, ...props }: Props) => {
  return (
    <IconButton
      color="primary"
      {...props}
      sx={{
        backgroundColor: 'primary.blue',
        borderRadius: 6,
        padding: '12px',

        '&:hover': {
          backgroundColor: 'primary.dark',
        },
      }}
    >
      <FontAwesomeIcon icon={icon} color="white" />
    </IconButton>
  )
}

export default FaIconButton
