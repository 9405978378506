class ObjectHelper {
    public removeEmptyProperties(obj: any): any {
        for (const key in obj) {
            if (obj[key] === null || obj[key] === undefined) {
                delete obj[key];
            }
        }
        return obj;
    }

    public hasProperties(obj: any): boolean {
        return Object.keys(obj).length > 0;
    }

    public isEmpty(obj: any): boolean {
        return Object.keys(obj).length === 0;
    }
}

export default new ObjectHelper();