import { Grid } from '@mui/material'
import React from 'react'
import FormAboutMe from 'src/components/organisms/FormAbotMe'
import { withRegisterContext } from 'src/contexts/register.context'


interface iProps {
  isEdit: boolean
  editMethods: any
}

const AboutMe = ({ isEdit, editMethods }: iProps) => {
  return (
    <Grid>
      {/* <Box>
        <Typography variant="h4">Know more about me</Typography>
      </Box> */}
      <FormAboutMe isEdit={isEdit} editMethods={editMethods} />
    </Grid>
  )
}

export default AboutMe
