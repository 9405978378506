import { IClient, IStakeHolder } from "src/interfaces/client.interfaces"
import {
  IProjectForm,
  IUserForProjectShow,
} from "src/interfaces/project.interface"

export const initialClient: Omit<IClient, "id"> = {
  name: "",
  logo: {
    name: "",
    path: "",
    url: "",
  },
  webpage: "",
  linkedin: "",
  country: "",
  keyStakeHolders: [],
  businessDescription: "",
  sow: {
    name: "",
    path: "",
    url: "",
  },
  csmId: "",
  csmName: "",
  status: "",
}

export const initialStakeHolder: IStakeHolder = {
  name: "",
  email: "",
  role: "",
  photo: {
    name: "",
    path: "",
    url: "",
  },
  photoFile: undefined,
}

export const initialProject: IProjectForm = {
  name: "",
  description: "",
  clientId: "",
  clientName: "",
  csmId: "",
  csmName: "",
  repositoryLink: "",
  dayStart: "",
  dayFinish: "",
  document: {
    name: "",
    path: "",
    url: "",
  },
  employees: [],
  foId: "",
  foName: "",
  tlId: "",
  tlName: "",
  status: "Active",
  onHoldDescription: "",
  skillList: [],
}

export const initialUser: IUserForProjectShow = {
  id: "",
  photoURL: "",
  fullName: "",
  role: {
    id: "",
    name: "",
    deleted: false,
  },
  status: "dedicated",
  dayStart: "",
  dayFinish: "",
  allocationPercentage: 0,
  seniority: "",
}
