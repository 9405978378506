import ROUTES from 'src/types/Routes'
import * as Icons from '@mui/icons-material'
import SvgIcon from '@mui/material/SvgIcon'

export interface NavigationItem {
  id: number
  label: string
  to: ROUTES
  icon: typeof SvgIcon
  permissions: string[]
  isDisabled?: boolean
}

const NAVIGATION_ROUTES = [
  // {
  //   id: 0,
  //   label: 'Home',
  //   to: ROUTES.HOME,
  //   icon: Icons.Home,
  //   permissions: ["home:view"],
  // },
  {
    id: 1,
    label: 'Team members',
    to: ROUTES.EMPLOYEES,
    icon: Icons.Groups,
    permissions: ["user:view"],
  },
  {
    id: 2,
    label: 'Roles',
    to: ROUTES.ROLES,
    icon: Icons.BusinessCenter,
    permissions: ["role:view"],
  },
  {
    id: 3,
    label: 'Skills',
    to: ROUTES.SKILLS,
    icon: Icons.FlashOn,
    permissions: ["skill:view"],
  },
  // {
  //   id: 4,
  //   label: 'PTO',
  //   to: ROUTES.ADMIN_TIME_OFF,
  //   icon: Icons.CalendarMonth,
  //   permissions: ["pto:view"],
  // },
  {
    id: 5,
    label: 'Projects',
    to: ROUTES.PROJECTS,
    icon: Icons.Share,
    permissions: ["project:view", "user:view"],
  },
  {
    id: 6,
    label: 'Clients',
    to: ROUTES.CLIENTS,
    icon: Icons.People,
    permissions: ["client:view"],
  },
] as NavigationItem[]

export default NAVIGATION_ROUTES
