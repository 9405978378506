import React from 'react';

export const IconAglesLeft = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='23.649'
      height='21.828'
      viewBox='0 0 23.649 21.828'
    >
      <path
        id='angles-left-solid'
        d='M4.415,74.89l7.81-7.81a1.819,1.819,0,0,0-2.572-2.572L.558,73.6a1.818,1.818,0,0,0,0,2.572l9.095,9.095a1.814,1.814,0,0,0,2.57,0,1.818,1.818,0,0,0,0-2.572Zm10.916,0,7.81-7.81a1.819,1.819,0,1,0-2.572-2.572L11.474,73.6a1.818,1.818,0,0,0,0,2.572l9.095,9.095a1.782,1.782,0,0,0,1.282.534,1.819,1.819,0,0,0,1.286-3.1Z'
        transform='translate(-0.025 -63.975)'
      />
    </svg>
  );
};
