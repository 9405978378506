import { TimeOff, TimeOffStatus, TimeOffType } from "src/interfaces/timeOff.interfaces"
import { iUserWithId } from "src/interfaces/user.interfaces"
import DateHelper from "../date/DateHelper"

class TimeOffHelper {
    public createTimeOff = (startDate: Date, endDate: Date, user: iUserWithId, admin: iUserWithId, type: TimeOffType): TimeOff => {
        return {
            admin,
            type,
            startDate: DateHelper.toString(startDate!),
            endDate: DateHelper.toString(endDate!),
            status: TimeOffStatus.PENDING,
            createdAt: DateHelper.getToday(),
            deleted: false,
            userId: user.id,
            fullName: user.fullName,
            email: user.email,
        } as TimeOff
    }

    public isTimeOffExist = (startDate: Date, endDate: Date, timeOffs: TimeOff[]): boolean => {
        const startDateString = DateHelper.toString(startDate);
        const endDateString = DateHelper.toString(endDate);

        return timeOffs.some(timeOff => timeOff.startDate === startDateString && timeOff.endDate === endDateString && this.isApprovededOrPending(timeOff));
    }

    public hasEnoughDays = (startDate: Date, endDate: Date, avaibleDays: number): boolean => {
        const days = DateHelper.getTotalDays(startDate.toString(), endDate.toString());
        return days <= avaibleDays;
    }

    public getTotalDaysFromTimeOffs = (timeOffs: TimeOff[]): number => {
        return timeOffs.reduce((total, timeOff) => {
            const days = DateHelper.getTotalDays(timeOff.startDate, timeOff.endDate);
            return total + days;
        }, 0);
    }

    public getPtosAvailable = (timeOffs: TimeOff[], avaibleDays: number): number => {
        const totalDays = this.getTotalDaysFromTimeOffs(timeOffs);
        return avaibleDays - totalDays;
    }

    public isApprovededOrPending = (timeOff: TimeOff): boolean => {
        return (timeOff.status === TimeOffStatus.APPROVED || timeOff.status === TimeOffStatus.PENDING)
    }
}

export default new TimeOffHelper()