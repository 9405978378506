import { FC, ReactNode } from "react"
import { useRecoilValue } from 'recoil'
import { hasPermissions } from "src/helpers/user/UserHelper"
import authState from 'src/recoil/auth'

interface IProps {
  requiredPermissions: string[]
  children: ReactNode
  fallback?: ReactNode
}

const ProtectedComponent: FC<IProps> = ({ requiredPermissions, fallback, children }) => {
  const currentUser = useRecoilValue(authState.user)
  const allAssignedPermissions = [...(currentUser?.permissions || []), ...(currentUser?.companyRole?.permissions || [])]

  const userHasPermission =  hasPermissions(allAssignedPermissions)

  if (!userHasPermission(requiredPermissions)) {
    return <>{fallback}</> || null
  }

  return <>{children}</>
}

export default ProtectedComponent
