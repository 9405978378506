import { User } from "firebase/auth"
import {
  collection,
  doc,
  documentId,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import {
  AppRole,
  Certification,
  iUser,
  iUserWithId,
} from "src/interfaces/user.interfaces"
import { ResponseT } from "src/types/types"
import { auth, COLLECTIONS, db, functions } from "../firebase/firebase.service"
import storageService from "../firebase/storage.service"
import timeOffService from "../timeOff/timeOff.service"

class UserService {
  private updateCertifications = async (
    certifications: Certification[] | undefined,
    userId: string
  ): Promise<Certification[]> => {
    if (!certifications || !certifications.length) {
      return []
    }

    const certificationPromises = certifications.map((certification) => {
      if (!certification.attachedFile) {
        return certification
      }

      return this.updateFile(
        userId,
        certification.attachedFile,
        `certifications/${certification.id}`
      ).then((newAttachedFile) => {
        return { ...certification, attachedFile: newAttachedFile }
      })
    })

    const newCertifications = await Promise.all(certificationPromises)
    return newCertifications
  }

  public updateFile = async (
    userId: string,
    imageUrl: string,
    type: string | "cover" | "profile"
  ): Promise<string> => {
    // if not a local url created by URL.createObjectURL
    //when it was execute with the condition "if (!imageUrl.includes("local"))" in dev. , it was enter and return an incorect url
    // console.log({CONDITION:!(imageUrl.includes("local") || imageUrl.includes("dev"))})
    // console.log({
    //   CONDITION_A: !imageUrl.includes("local"),
    //   CONDITION_B: !imageUrl.includes("local" || "dev"),
    //   CONDITION_C: !(imageUrl.includes("local") || imageUrl.includes("dev"))
    // })
    if (!imageUrl.includes("local" || "dev")) {
      // alert({imageUrl,message:"Works !!"})
      return new Promise((res) => res(imageUrl))
    }

    const imageBlob = await fetch(imageUrl).then((r) => r.blob())
    const pathToUpload = `${COLLECTIONS.USERS}/${userId}/${type}`
    const newImageUrl = await storageService.uploadFile(imageBlob, pathToUpload)
    return newImageUrl
  }

  public getUsers = async (): Promise<iUserWithId[]> => {
    const querySnapshot = await getDocs(collection(db, COLLECTIONS.USERS))
    let users: iUserWithId[] = []
    querySnapshot.forEach((doc) => {
      const data = doc.data()
      let user = {
        ...(data as iUser),
        id: doc.id,
        inBench: data.inBench || false,
        birthDate: data.birthDate || "01/01/2000",
        ptosAvailable: data.ptosAvailable || 0,
      } as iUserWithId

      users.push(user)
    })
    return users
  }

  public getUser = async (id: string): Promise<iUserWithId> => {
    let user: iUserWithId | undefined
    //TODO maybe use .converter() like in the backend project
    //console.log("userpath", COLLECTIONS.USERS)
    const usersRef = collection(db, COLLECTIONS.USERS)
    const q = query(usersRef, where(documentId(), "==", id))

    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      user = { id: doc.id, ...(doc.data() as iUser) }
    })

    if (!user) {
      throw new Error("No users found")
    }

    return user
  }

  public updateUser = async (
    userId: string,
    user: Partial<iUserWithId>
  ): Promise<ResponseT> => {
    try {
      const coverPhotoURL = !!user.coverPhotoURL
        ? await this.updateFile(userId, user.coverPhotoURL!, "cover")
        : ""
      const photoURL = !!user.photoURL
        ? await this.updateFile(userId, user.photoURL!, "profile")
        : ""
      const certifications = await this.updateCertifications(
        user.certifications,
        userId
      )
      const timeOffs = await timeOffService.updateByUserId(
        user.timeOffs,
        userId
      )
      const updateUser: Partial<iUserWithId> = {
        ...user,
        coverPhotoURL,
        photoURL,
        timeOffs,
        firebaseId: "",
        role: user.role ?? AppRole.user,
        certifications,
        // Avoid sending the whole location object
        location: {
          description: user.location?.description || "",
          terms: user.location?.terms || [],
        },
        // Avoid sending the whole location object
        nationality: {
          description: user.nationality?.description || "",
          terms: user.nationality?.terms || [],
        },
      }
      const userDocRef = doc(db, COLLECTIONS.USERS, userId)
      await updateDoc(userDocRef, updateUser)
      return {
        statusCode: 200,
        message: "User updated successfully",
        data: updateUser,
      }
    } catch (err: any) {
      console.error(err)
      return {
        statusCode: 500,
        message: err.message || "Error while updating user",
      }
    }
  }

  public changeUsersRole = async (
    userEmail: string,
    role: number
  ): Promise<ResponseT> => {
    const addMessage = httpsCallable(functions, "dev-makeNewAdmin")
    await addMessage({ email: userEmail, role }).then((result) => {
      console.log("this log", result)
      /** @type {any} */
    })
    return { statusCode: 200, message: "User updated successfully" }
  }

  public getCurrentUser = async (user: User): Promise<iUserWithId> => {
    if (!user) {
      throw new Error("No user found")
    }

    const currentUser = await this.getUser(user.uid)

    return currentUser
  }

  public onSucessLogin = () => {
    localStorage.setItem("loggedIn", "true")
  }

  public onLogout = () => {
    auth.signOut()
    localStorage.removeItem("loggedIn")
  }

  public isLoggedIn = () => {
    return localStorage.getItem("loggedIn") === "true"
  }

  public getAdminUsers = async (): Promise<iUserWithId[]> => {
    const users = await this.getUsers()
    return users.filter(
      (user) => user.role === AppRole.admin || user.role === AppRole.superAdmin
    )
  }
}

export default new UserService()
