import storageService from '../firebase/storage.service'
import { IStorageFile } from 'src/interfaces/file.interfaces'

const uploadFile = async (path: string, file: File): Promise<IStorageFile> => {
  const uploadedUrl = await storageService.uploadFile(file, path)

  return {
    name: file.name,
    path,
    url: uploadedUrl,
  }
}

const FileService = {
  uploadFile,
}

export default FileService
