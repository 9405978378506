import { TodayOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import panda from 'src/assets/images/PandaWeb.png'

const Loading = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>
        <img
          src={panda}
          alt="panda"
          className=" animation-spin"
          style={{ height: '8em' }}
        />
        <Box  >
          <Typography className='typewriter' align='center' variant='h5'>Loading...</Typography>
        </Box>
      </div>
    </div>
  )
}

export default Loading
