import {
  IProjectForm,
  IProjectModel,
  IProjectShow,
  IProjectWithAction,
  IUserForProjectShow,
} from "src/interfaces/project.interface"
import { TechWithId } from "src/interfaces/skills.interfaces"

export const mapProjectToDB = (
  project: IProjectModel,
  seats: IUserForProjectShow[],
  skillList: TechWithId[]
): IProjectModel => {
  return {
    ...project,
    employees: seats.map((seat) => ({
      id: seat.id,
      role: seat.role,
      status: seat.status,
      allocationPercentage: seat.allocationPercentage,
      dayStart: seat.dayStart,
      dayFinish: seat.dayFinish,
      photoURL: seat.photoURL,
      fullName: seat.fullName,
      seniority: seat.seniority,
    })),
    skillList: skillList.map((seat) => ({
      id: seat.id,
      tech: seat.tech,
    })),
  }
}

export const mapProjectToForm = (
  project: IProjectShow,
  skillList: TechWithId[]
): IProjectModel => {
  return {
    name: project.name,
    description: project.description,
    clientId: project.clientId,
    clientName: project.clientName,
    csmId: project.csmId,
    csmName: project.csmName,
    repositoryLink: project.repositoryLink,
    dayStart: project.dayStart,
    dayFinish: project.dayFinish,
    document: project.document,
    employees: [],
    foId: project.foId,
    foName: project.foName,
    tlId: project.tlId,
    tlName: project.tlName,
    status: project.status,
    onHoldDescription: project.onHoldDescription,
    skillList: skillList.map((seat) => ({
      id: seat.id,
      tech: seat.tech,
    })),
  }
}

export const mapToProjectModel = (
  project: IProjectForm | IProjectWithAction,
  clientId: string
): IProjectModel => {
  return {
    name: project.name,
    description: project.description,
    clientId,
    clientName: project.clientName,
    csmId: project.csmId,
    csmName: project.csmName,
    repositoryLink: project.repositoryLink,
    dayStart: project.dayStart,
    dayFinish: project.dayFinish,
    document: project.document,
    employees: project.employees.map((seat) => ({
      id: seat.id,
      role: seat.role,
      status: seat.status,
      allocationPercentage: seat.allocationPercentage,
      dayStart: seat.dayStart,
      dayFinish: seat.dayFinish,
      photoURL: seat.photoURL,
      fullName: seat.fullName,
      seniority: seat.seniority,
    })),
    foId: project.foId,
    foName: project.foName,
    tlId: project.tlId,
    tlName: project.tlName,
    status: project.status,
    onHoldDescription: project.onHoldDescription,
    skillList: project.skillList.map((seat) => ({
      id: seat.id,
      tech: seat.tech,
    })),
  }
}

export const addDefaultActionToProjects = (
  projects: IProjectShow[]
): IProjectWithAction[] => {
  return projects.map((project) => ({
    ...project,
    action: "none",
  }))
}
