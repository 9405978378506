import { createContext } from "react"
import {
  iUserWithId,
  iFilters,
  DropdownInput,
} from "src/interfaces/user.interfaces"

export const EmployeeContext = createContext({
  handleSearchBarFilterEmployee: (filter: string) => {},
  searchBarTextEmployee: "",
  listSwitches: [
    {
      leable: "",
      checked: false as boolean | undefined,
      onChange: (e: any) => {},
    },
  ],
  listInputDropdownEmployee: [] as DropdownInput[],
  clearFilters: () => {},
  arrayDirectFilters: [
    {
      leable: "",
      onClick: () => {},
      linkProperties: null,
      name: "",
    },
  ],
  loadingEmployee: true,
  handleEditUser: (user: iUserWithId) => {},
  filteredEmployees: [] as iUserWithId[],
  selectedUser: {} as iUserWithId | undefined,
  getUsersInComponent: () => {},
  modalEdit: false,
  setModalEdit: (value: boolean) => {},
  updateSelectedUser: (user: iUserWithId) => {},
  filters: {} as iFilters,
  setFilters: (value: React.SetStateAction<iFilters>) => {},
  // setFilters: (value: React.SetStateAction<any>) => {value},
})
