import { TextField, Box, BaseTextFieldProps, FormLabel } from '@mui/material'
import React from 'react'

interface Props extends BaseTextFieldProps {
  label: string
  value: string | undefined
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  rows?: number
  placeholder?: string
  InputProps?: any
}

const InputProfile = ({ label, ...props }: Props) => {
  return (
    <Box sx={{ display: 'flex' }} flexDirection="column">
      <FormLabel>{label}</FormLabel>
      <TextField
        {...props}
        value={props.value ?? ''}
        // onChange={props.onChange}
        hiddenLabel
        multiline={!!props.rows}
        rows={props.rows}
        id="filled-hidden-label-small"
        size="small"
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderRadius: 5,
            },
            '& textarea': {
              '&:focus': {
                border: 'none',
                overFlow: 'auto',
                outline: 'none',
                WebkitBoxShadow: 'none',
              },
            },
          },
          '& input': {
            borderRadius: 5,
            '&:focus': {
              borderRadius: 5,
            },
          },
        }}
      />
    </Box>
  )
}

export default InputProfile
