import * as React from 'react';
import Avatar from '@mui/material/Avatar';

interface AvatarProps {
  size: 'tiny' | 'small' | 'medium' | 'big';
  name?: string;
  urlImage?: string;
}

export const AvatarComponent = ({
  size = 'medium',
  name,
  urlImage,
  ...props
}: AvatarProps) => {
  return (
    <Avatar
      src={urlImage}
      sx={{
        bgcolor: '#0083D2',
        width:
          size === 'tiny'
            ? 30
            : size === 'small'
            ? 45
            : size === 'medium'
            ? 62
            : size === 'big'
            ? 128
            : 45,
        height:
          size === 'tiny'
            ? 30
            : size === 'small'
            ? 45
            : size === 'medium'
            ? 62
            : size === 'big'
            ? 128
            : 45
      }}
      {...props}
    >
      {name?.slice(0, 1).toUpperCase() && 'E'}
    </Avatar>
  );
};
