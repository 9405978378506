import { Controller, Control } from "react-hook-form"
import GoogleInput from "src/components/organisms/AutocompleteLocation"

interface Props {
  control: Control
  name: string
  required?: boolean
  errorText?: string
  disabled?: boolean
  label?: string
  onlyCountries?: boolean
}

const FormGoogleInput = ({
  name,
  control,
  required,
  disabled,
  label,
  onlyCountries,
}: Props) => (
  <Controller
    name={name}
    control={control}
    rules={{
      required: { message: "Field is required", value: !!required },
    }}
    render={({ field, fieldState }) => (
      <GoogleInput
        onlyCountries={onlyCountries}
        label={label}
        disabled={disabled}
        comingValue={field.value}
        handleValue={(value: any) => field.onChange(value)}
        helperText={fieldState.error?.message || " "}
        sx={{ mt: -2 }}
      />
    )}
  />
)

export default FormGoogleInput
