// import { useState } from "react"
import { Box } from "@mui/system"
import { Typography } from "@mui/material"
import CertificationsTable from "src/components/moleculas/CertificationsTable"
import { Certification } from "src/interfaces/user.interfaces"
import "./CertificationView.scss"

interface Props {
  certifications: Certification[]
}

const CertificationsView = ({ certifications }: Props) => {
  // const [isTableMode, setIsTableMode] = useState(false)

  // const handleChangeMode = (e: any) => {
  //   setIsTableMode(e.target.checked)
  // }

  return (
    <Box
      sx={{
        // bgcolor: 'background.paper',
        // boxShadow: 1,
        borderRadius: 2,
        p: 2,
        minWidth: 300,
        gap: 2,
        margin: "30px 0",
      }}
    >
      <Typography style={{ color: "#828689" }}>
        <Box
          sx={{
            fontSize: 16,
            fontWeight: "medium",
            display: "flex",
            alignItems: "center",
            gap: 2,
            marginBottom: 2,
          }}
        >
          Certificate
          {/* <Switch checked={isTableMode} onChange={handleChangeMode} /> */}
        </Box>
      </Typography>
      <Box sx={{ color: "text.secondary" }} className="class-CertificationView">
        <CertificationsTable certifications={certifications} />
        {/* {isTableMode ? (
          <CertificationsTable certifications={certifications} />
        ) : (
          <CertificationsList certifications={certifications} />
        )} */}
      </Box>
    </Box>
  )
}

export default CertificationsView
