import azumoLogo from '../../../assets/images/pandas.svg'
import { Box, Container } from '@mui/material'

const LoginBanner = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
      }}
      style={{
        boxShadow: 'inset 0px 0px 40px #1C212426',
        opacity: 1,
      }}
    >
      <Box
        component="img"
        sx={{
          width: 400,
          alignContent: 'center',
        }}
        alt="Azumo Panda"
        src={azumoLogo}
        style={{
          marginTop: 'auto',
          marginBottom: 'auto',
        }}
      />
    </Container>
  )
}

export default LoginBanner
