import { atom } from "recoil";
import { iUserWithId } from "src/interfaces/user.interfaces";

const user = atom({
    key: 'user',
    default: {} as iUserWithId
});

const isLoadingUser = atom({
    key: 'isLoadingUser',
    default: false
});

const authState = {
    user,
    isLoadingUser,
}

export default authState;