import styled from "@emotion/styled"
import { DeleteOutline } from "@mui/icons-material"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material"
import { Certification } from "src/interfaces/user.interfaces"

interface Props {
  certifications: Certification[]
  onDelete?: (id: string) => void
  minHeight?: number
}

const CertificationTableHead = styled(TableRow)(({ theme }) => ({
  "&": {
    boxShadow: "0px 3px 6px #1C212426",
    opacity: 1,
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  },
  "& th": {
    borderBottom: "0",
  },
}))

const handleDownload = async (fileUrl: string | undefined) => {
  window.open(fileUrl, "_blank")
}

const CertificationsTable = ({
  certifications,
  onDelete,
  minHeight,
}: Props) => {
  return (
    <TableContainer
      sx={{ overflowY: "auto" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <CertificationTableHead>
            <TableCell>Title</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Completion date</TableCell>
            <TableCell>Download file</TableCell>
            <TableCell></TableCell>
          </CertificationTableHead>
        </TableHead>
        <TableBody>
          {certifications && certifications.length > 0 ? (
            certifications.map((row) => (
              <TableRow key={row?.id}>
                <TableCell>{row?.title}</TableCell>
                <TableCell>{row?.company}</TableCell>
                <TableCell>{row?.date}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      handleDownload(row?.attachedFile)
                    }}
                  >
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                </TableCell>
                {onDelete && (
                  <TableCell width={1}>
                    <IconButton
                      onClick={() => {
                        onDelete(row?.id)
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}>No certifications added yet</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CertificationsTable
