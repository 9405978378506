import React, { useEffect, useRef } from 'react'
import './App.css'
import Router from './routes/Router'
import { NotificationContainer, NotificationManager } from 'react-notifications'

import Loading from './pages/Loading'
import { ThemeProvider } from '@mui/material'
import { themeLight } from './theme'
import { useRecoilState } from 'recoil'
import appState from './recoil/app'
import userService from './services/users/user.service'
import authState from './recoil/auth'
import { iUserWithId } from './interfaces/user.interfaces'
import { auth } from './services/firebase/firebase.service'
import { User } from 'firebase/auth'

function App() {
  const [isLoadingApp, setIsLoadingApp] = useRecoilState(appState.isLoadingApp)
  const [currentUser, setCurrentUser] = useRecoilState(authState.user)
  const loginTries = useRef(0);

  const updateUser = (newUser: iUserWithId) => {
    setCurrentUser(newUser)
    setIsLoadingApp(false)
    userService.onSucessLogin()
  }

  const retryToGetUser = async (user: User) => {
    try {
      const userToUpdate = await userService.getCurrentUser(user)
      updateUser(userToUpdate)
      console.log(loginTries.current)
    } catch (error: any) {
      if (loginTries.current < 10) {
        loginTries.current += 1
        setTimeout(() => {
          retryToGetUser(user)
        }, 2000)
      } else {
        NotificationManager.error(error.toString(), "Error", 3000)
        setIsLoadingApp(false)
      }
    }
  }

  useEffect(() => {
    if (userService.isLoggedIn()) {
      setIsLoadingApp(false)
    }
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsLoadingApp(true)
        retryToGetUser(user)
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ThemeProvider theme={themeLight}>
      {isLoadingApp ? <Loading /> :  <Router user={currentUser} />}
      <NotificationContainer />
    </ThemeProvider>
  )
}

export default App
