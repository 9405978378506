import { useEffect, useState, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import {
  iFilters,
  iUserWithId,
  SENIORITY_OPTIONS,
  EnglishLevel,
} from "src/interfaces/user.interfaces"
import userService from "src/services/users/user.service"
import ROUTES from "src/types/Routes"
import { RoleWithId } from "src/interfaces/roles.interfaces"
import { getRoles } from "src/services/roles/roles.service"
import { TechWithId } from "src/interfaces/skills.interfaces"
import { getSkills } from "src/services/skills/skills.service"

const DEFAULT_FILTERS = {
  isCurrentEmployee: true,
  skillPrincipals: "",
} as iFilters

export default function useEmployees() {
  const [hasRun, setHasRun] = useState(false)
// this is the filter value to compare data
// OLD
  const getFilterData = localStorage.getItem("filterData")
  const arrFilter = getFilterData && JSON.parse(getFilterData)

  const [filters, setFilters] = useState<iFilters>(DEFAULT_FILTERS)

  // this's input text to search employee by name or email
  const [searchBarTextEmployee, setSearchBarText] = useState("")

  // this's fisrt get all employee
  const [employees, setEmployees] = useState<iUserWithId[]>([])

  // this is the employees but filtered by the filters
  const [filteredEmployees, setFilteredEmployees] = useState<iUserWithId[]>([])

  const [modalEdit, setModalEdit] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<iUserWithId | undefined>()

  const [trigger, setTrigger] = useState<boolean>(false)
  const [loadingEmployee, setLoading] = useState<boolean>(true)

  const [getCSM, setGetCSM] = useState<iUserWithId[]>([])

  const modifyFilterFunction = (param:iFilters) => {
    setFilters(param)
    localStorage.setItem("filterData", JSON.stringify(param))
  }

  useCallback(()=>{
    getFilterData && arrFilter && setFilters(arrFilter)
},[getFilterData,arrFilter])

  const [skillOptionsReformat, setSkillOptionsReformat] = useState<any>([])
  const [skillList, setSkillList] = useState<TechWithId[]>([])
  const [roles, setRoles] = useState<RoleWithId[]>([])

  const navigate = useNavigate()

  // Here you get all roles
  const getRoleFunction = useCallback(async () => {
    const newRoles = await getRoles()
    setRoles(newRoles)
  },[roles])

  useEffect(()=>{
    getRoleFunction()
  },[roles])

  // Here you get all skills
  const getSkillsFunction = useCallback(async ()=>{
    const getSkillsList = await getSkills()
    getSkillsList.response && setSkillList(getSkillsList.response)
  },[skillList])

  useEffect(()=>{
    getSkillsFunction()
  },[skillList])

  useCallback(() => {
    const getAllCSM = () => {
      let result = employees.filter((data) => data.companyRole?.name === "CSM")
      setGetCSM(result)
    }
    getAllCSM()
  }, [employees])

  const handleEditUser = (user: iUserWithId) => {
    setSelectedUser(user)
    navigate(`${ROUTES.EMPLOYEES}/${user.id}/edit`)
    // window.open(`${ROUTES.EMPLOYEES}/${user.id}/edit`,'_blank')
  }

  const updateSelectedUser = (user: iUserWithId) => {
    setSelectedUser(user)
  }

  useCallback(() => {
    setTrigger(!trigger)
    // eslint-disable-next-line
  }, [employees])

  // this's the execute employee filtered
  useEffect(() => {
    setFilteredEmployees(applyFilters(employees ? employees : []))
    setTrigger(!trigger)
    // eslint-disable-next-line
  }, [filters, employees])

  useCallback(() => {
    const newEmployees = employees.map((employee) =>
      employee.id === selectedUser?.id ? selectedUser : employee
    )

    setEmployees(newEmployees)
    // eslint-disable-next-line
  }, [selectedUser])

  const applyFilters = useCallback((emp: iUserWithId[]) => {
    const filteredEmployees = [...emp]

    if (filters) {
      let result: iUserWithId[] = filteredEmployees
      // filter by full name
      if (filters.fullName && filters.fullName.length > 0) {
        result = filteredEmployees.filter((employee) => {
          if (employee.fullName === undefined) {
            return null
          }
          return employee.fullName
            .toLowerCase()
            .includes(filters.fullName ? filters.fullName.toLowerCase() : "")
        })
      }
      // filter by onBench
      if (filters.onBench) {
        result = result.filter((employee) => {
          return employee.inBench === filters.onBench
        })
      }
      // filter by if it current employee
      result = result.filter((employee) => {
        return employee.isCurrentEmployee === filters.isCurrentEmployee
      })
      // NEW here we filter by skills
      if (filters.skillsId && filters.skillsId.length > 0) {
        // eslint-disable-next-line
        result = filteredEmployees.filter((employee) => {
          if (employee.skills && employee.skills.length > 0) {
            return (
              employee.skills?.filter(
                (skills) => skills.id === filters.skillsId
              ).length > 0
            )
          }
        })
      }
      // filter by company rol name
      if (filters.companyRoleName && filters.companyRoleName.length > 0) {
        result = result.filter((employee) => {
          if (employee.companyRole === undefined) {
            return false
          }
          return employee.companyRole?.name
            .toLowerCase()
            .includes(
              filters.companyRoleName
                ? filters.companyRoleName.toLowerCase()
                : ""
            )
        })
      }
      // filter by principal skills
      if (filters.skillPrincipals && filters.skillPrincipals.length > 0) {
        result = result.filter((employee) => {
          if (employee.skillPrincipals === undefined) {
            return false
          }
          return employee.skillPrincipals
            .toLowerCase()
            .startsWith(
              filters.skillPrincipals
                ? filters.skillPrincipals.toLowerCase()
                : ""
            )
        })
      }
      // filter by english level
      if (filters.englishLevel) {
        result = result.filter((employee) => {
          if (employee.englishLevel === undefined) {
            return false
          }
          if (typeof employee.englishLevel === "string") {
            return Number(employee.englishLevel) === filters.englishLevel
          }
          return employee.englishLevel === filters.englishLevel
        })
      }
      // filter by location
      if (filters.location && filters.location.terms.length > 0) {
        result = result.filter((employee) => {
          if (employee.location === undefined) {
            return false
          }
          return filters.location?.terms.some((term) => {
            return employee.location?.terms?.some((termEmployee) => {
              return termEmployee.value
                .toLowerCase()
                .includes(term.value.toLowerCase())
            })
          })
        })
      }
      // filter by seniority
      if (filters.seniority) {
        result = result.filter((employee) => {
          if (employee.seniority === undefined) {
            return false
          }
          // @ts-ignore
          return employee.seniority === filters.seniority
        })
      }
      // filter by email
      if (filters.email && filters.email.length > 0) {
        result = result.filter((employee) => {
          if (employee.email === undefined) {
            return false
          }
          return employee.email
            .toLowerCase()
            .includes(filters.email ? filters.email.toLowerCase() : "")
        })
      }
      setTrigger(!trigger)
      return result
    }
    return emp
  },[filters, employees])

  const getUsersInComponent = useCallback(async () => {
    setLoading(true)
    const users = await userService.getUsers()
    setEmployees(users)
    setFilteredEmployees(applyFilters(users))
    setLoading(false)
  },[])

  useEffect(() => {
    getUsersInComponent()
    // eslint-disable-next-line
  }, [])

  const handleSearchBarFilterEmployee = (filter: string) => {
    if (!hasRun) {
      getFilterData && arrFilter && arrFilter?.email?.length > 1 && setSearchBarText(arrFilter?.email)
      setHasRun(true)
    } else {
      // window.location.reload();
      setSearchBarText(filter)
      if (filter) {
        modifyFilterFunction({ ...filters, fullName: filter })
        modifyFilterFunction({ ...filters, email: filter })
      }
    }
  }

  const listSwitches = [
    {
      leable: "Current Employee",
      checked: filters.isCurrentEmployee,
      onChange: (e: any) =>
      modifyFilterFunction({ ...filters, isCurrentEmployee: e.target.checked }),
    },
    {
      leable: "In Bench",
      checked: filters.onBench,
      onChange: (e: any) =>
      modifyFilterFunction({ ...filters, onBench: e.target.checked }),
    },
  ]

  const ENGLISH_LEVEL_OPTIONS = [
    { value: EnglishLevel.preIntermediate, label: "Pre-Intermediate" },
    { value: EnglishLevel.intermediate, label: "Intermediate" },
    { value: EnglishLevel.upperIntemediate, label: "Upper-Intemediate" },
    { value: EnglishLevel.advanced, label: "Advanced" },
    { value: EnglishLevel.native, label: "Native" },
  ]

  const listInputDropdownEmployee = [
    {
      labelName: "Company Role",
      arrayOptions: roles.map((role) => role.name),
      onChange: (e: any) => modifyFilterFunction({ ...filters, companyRoleName: e }),
      value: filters.companyRoleName,
    },
    {
      labelName: "English Level",
      arrayOptions: ENGLISH_LEVEL_OPTIONS,
      onChange: (e: any) =>
      modifyFilterFunction({
          ...filters,
          englishLevel: ENGLISH_LEVEL_OPTIONS.find((el) => el.label === e)
            ?.value,
        }),
      // value: filters.englishLevel,
      value:
        filters.englishLevel === "" || filters.englishLevel === undefined
          ? ""
          : ENGLISH_LEVEL_OPTIONS.find(
              (el) => el.value === filters.englishLevel
            )?.label,
    },
    {
      labelName: "Seniority",
      arrayOptions: SENIORITY_OPTIONS,
      onChange: (e: any) =>
      modifyFilterFunction({
          ...filters,
          seniority: SENIORITY_OPTIONS.find((el) => el.label === e)?.value,
        }),
      // value: filters.seniority,
      value:
        filters.seniority === "" || filters.seniority === undefined
          ? ""
          : SENIORITY_OPTIONS.find((el) => el.value === filters.seniority)
              ?.label,
    },
    {
      labelName: "Primary Skill",
      arrayOptions: skillList.map((role) => role.tech),
      onChange: (e: any) => modifyFilterFunction({ ...filters, skillPrincipals: e }),
      value: filters.skillPrincipals,
    },
  ]

  const clearFilters = () => {
    setFilters(DEFAULT_FILTERS)
    getUsersInComponent()
    setSearchBarText("")
  }

  const arrayDirectFilters = skillOptionsReformat ? skillOptionsReformat : []

  return {
    employees,
    getCSM,
    handleSearchBarFilterEmployee,
    searchBarTextEmployee,
    listSwitches,
    listInputDropdownEmployee,
    clearFilters,
    arrayDirectFilters,
    loadingEmployee,
    handleEditUser,
    filteredEmployees,
    filters,
    setFilters,
    selectedUser,
    getUsersInComponent,
    modalEdit,
    setModalEdit,
    updateSelectedUser,
  }
}
