import { Box, Grid, Typography } from '@mui/material'
import React, { FC } from 'react'

interface iProps {
  title: string
  description: string
}

const CardAboutMe: FC<iProps> = ({ title, description }) => {
  const [showDescription, setShowDescription] = React.useState(true)
  return (
    <Grid
      item
      display="flex"
      sx={{
        width: '100%',
        overflow: 'auto',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignContent: 'stretch',
      }}
      onClick={() => setShowDescription(!showDescription)}
      container
      direction={'row'}
      xs={4}
      spacing={1}
    >
      <Box
        sx={{
          background: '#EAEEF1',
          borderRadius: '20px',
          margin: '6px 0',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding: '20px',
            justifyContent: 'space-between',
          }}
        >
          <Grid
            display="flex"
            sx={{
              width: '100%',
              overflow: 'auto',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignContent: 'stretch',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#828689',
                  lineHeight: '24px',
                  fontWeight: 500,
                }}
              >
                {title}
                {': '}
              </Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#828689',
                  lineHeight: '22px',
                }}
              >
                {description ? description : 'Unknown'}
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Grid>
  )
}

export default CardAboutMe
