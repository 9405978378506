import {
  collection,
  doc,
  documentId,
  getDocs,
  query,
  addDoc,
  updateDoc,
  where,
  QueryDocumentSnapshot,
  DocumentData,
} from "firebase/firestore"
import { COLLECTIONS, db } from "../firebase/firebase.service"
import storageService from "../firebase/storage.service"
import { ResponseT } from "src/types/types"
import {
  IProjectModel,
  IProjectShow,
  IUserForProjectShow,
} from "src/interfaces/project.interface"
// Other Services
import FileService from "src/services/file/file.service"
import ClientsService from "src/services/clients/clients.service"
import userService from "src/services/users/user.service"
import { iUserWithId } from "src/interfaces/user.interfaces"

const populateProjectData = async (
  doc: QueryDocumentSnapshot<DocumentData>
): Promise<IProjectShow> => {
  const projectFromDB: IProjectModel = {
    ...(doc.data() as IProjectModel),
  }

  return {
    id: doc.id,
    clientId: projectFromDB.clientId || "",
    clientName: projectFromDB.clientName || "",
    csmId: projectFromDB.csmId || "",
    csmName: projectFromDB.csmName || "",
    name: projectFromDB.name,
    description: projectFromDB.description,
    repositoryLink: projectFromDB.repositoryLink,
    dayStart: projectFromDB.dayStart,
    dayFinish: projectFromDB.dayFinish,
    document: projectFromDB.document,
    employees: projectFromDB.employees,
    foId: projectFromDB.foId || "",
    foName: projectFromDB.foName || "",
    tlId: projectFromDB.tlId || "",
    tlName: projectFromDB.tlName || "",
    status: projectFromDB.status,
    onHoldDescription: projectFromDB.onHoldDescription,
    skillList: projectFromDB.skillList,
  }
}

const createProject = async (
  project: IProjectModel,
  documentFile?: File
): Promise<ResponseT> => {
  try {
    const collectionReference = collection(db, COLLECTIONS.PROJECTS)
    const response = await addDoc(collectionReference, {
      ...project,
      deleted: false,
    })

    if (documentFile !== undefined) {
      const projectId = response.id

      const document = await FileService.uploadFile(
        `${COLLECTIONS.PROJECTS}/${projectId}/${documentFile.name}`,
        documentFile
      )

      const projectDocRef = doc(db, COLLECTIONS.PROJECTS, projectId)
      await updateDoc(projectDocRef, { document })
    }

    return {
      statusCode: 200,
      message: "Project created successfully",
      data: response,
    }
  } catch (err: any) {
    console.error(err.message)
    return {
      statusCode: err.code || 500,
      message: err.message || "Error while creating project",
    }
  }
}

const updateProject = async (
  projectId: string,
  project: IProjectModel,
  documentFile?: File
): Promise<ResponseT> => {
  try {
    let document = { ...project.document }

    if (documentFile !== undefined) {
      // Delete previous file (if any)
      const previousFilePath = project.document.path
      if (previousFilePath) await storageService.deleteFile(previousFilePath)

      document = {
        ...(await FileService.uploadFile(
          `${COLLECTIONS.PROJECTS}/${projectId}/${documentFile.name}`,
          documentFile
        )),
      }
    }

    const updatedProject = {
      ...project,
      document,
    }

    const projectDocRef = doc(db, COLLECTIONS.PROJECTS, projectId)
    await updateDoc(projectDocRef, updatedProject)
    return {
      statusCode: 200,
      message: "Project updated successfully",
      data: updatedProject,
    }
  } catch (err: any) {
    console.error(err.message)
    return {
      statusCode: 500,
      message: err.message || "Error while updating project",
    }
  }
}

const getByClientId = async (
  clientId: string
): Promise<IProjectShow[] | { error: string }> => {
  try {
    const collectionReference = collection(db, COLLECTIONS.PROJECTS)
    const q = query(
      collectionReference,
      ...[where("clientId", "==", clientId), where("deleted", "==", false)]
    )

    const querySnapshot = await getDocs(q)

    const projects: IProjectShow[] = []

    for (const doc of querySnapshot.docs) {
      const project = await populateProjectData(doc)
      projects.push(project)
    }

    return projects
  } catch (err: any) {
    console.error(err.message)
    return { error: err.message }
  }
}

const getProjects = async (): Promise<IProjectShow[] | { error: string }> => {
  try {
    const collectionReference = collection(db, COLLECTIONS.PROJECTS)
    const q = query(collectionReference, where("deleted", "==", false))

    const querySnapshot = await getDocs(q)

    const projects: IProjectShow[] = []

    for (const doc of querySnapshot.docs) {
      const project = await populateProjectData(doc)
      projects.push(project)
    }
    return projects
  } catch (err: any) {
    console.error(err.message)
    return { error: err.message }
  }
}

const getUserProjects = async (userId: string): Promise<IProjectShow[] | { error: string }> => {
  try {
    const collectionReference = collection(db, COLLECTIONS.PROJECTS)
    const q = query(collectionReference, where("deleted", "==", false))

    const querySnapshot = await getDocs(q)

    const projects: IProjectShow[] = []

    for (const doc of querySnapshot.docs) {
      const project = await populateProjectData(doc)
      projects.push(project)
    }
    console.log("PROJECTS", projects)
    return projects.filter((project) =>
      (project.employees || {}).findIndex(({ id }) => userId === id) >= 0 || project.foId === userId || project.csmId === userId)
  } catch (err: any) {
    console.error(err.message)
    return { error: err.message }
  }
}

const getProject = async (
  projectId: string
): Promise<IProjectShow | { error: string }> => {
  try {
    let project: IProjectShow | undefined

    const collectionReference = collection(db, COLLECTIONS.PROJECTS)
    const q = query(
      collectionReference,
      ...[where(documentId(), "==", projectId), where("deleted", "==", false)]
    )

    const querySnapshot = await getDocs(q)

    for (const doc of querySnapshot.docs) {
      project = await populateProjectData(doc)
    }

    if (!project) {
      throw new Error("No projects found")
    }

    return project
  } catch (err: any) {
    console.error(err.message)
    return { error: err.message }
  }
}

const deleteProject = async (projectId: string): Promise<ResponseT> => {
  try {
    const documentReference = doc(db, COLLECTIONS.PROJECTS, projectId)
    const updatedDoc = await updateDoc(documentReference, { deleted: true })

    return {
      statusCode: 200,
      message: "Project deleted successfully",
      data: updatedDoc,
    }
  } catch (err: any) {
    console.error(err.message)
    return {
      statusCode: err.code || 500,
      message: err.message || "Error while deleting project",
    }
  }
}

const ProjectsService = {
  createProject,
  updateProject,
  getByClientId,
  getProjects,
  getProject,
  deleteProject,
  getUserProjects,
}

export default ProjectsService
