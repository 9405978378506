import React from "react"
import { useMediaQuery } from "@mui/material"
import { useRecoilState } from "recoil"
import leftSidebar from "src/recoil/sidebar"
import { MenuListComponent } from "../../atoms/menuList/MenuList"
import styles from "./SideMenuLeft.module.scss"

interface SideMenuLeftProps {
  isOpen?: boolean
  children?: React.ReactNode
  imgUrl?: string
}

export const SideMenuLeft = ({
  isOpen = true,
  children,
  imgUrl,
}: SideMenuLeftProps) => {
  const isDesktopWidth = useMediaQuery("(min-width:1024px)")

  const [isLeftSidebar, setIsLeftSidebar] = useRecoilState(
    leftSidebar.isLeftSidebar
  )

  return (
    <div
      className={!isLeftSidebar || !isDesktopWidth ? styles.root2 : styles.root}
    >
      <MenuListComponent>
        <div
          data-open={!isLeftSidebar || !isDesktopWidth ? false : true}
          className={styles["logoPanda"]}
          onClick={() => setIsLeftSidebar(!isLeftSidebar)}
        />
        {children}
      </MenuListComponent>
    </div>
  )
}
