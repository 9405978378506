import { createContext } from 'react';
import { IClient } from 'src/interfaces/client.interfaces';

export const ClientContext = createContext({
    searchBarText:'',
    handleSearchBarFilter:(text:string)=>{},
    loading:false,
    filteredClients:[] as IClient[],
    listInputDropdown:[{
        labelName:'',
        arrayOptions:[{
            value: 0,
            label: ''
        }],
        onChange:(value:string)=>{},
        value:''
    }],
    cleanFilters:()=>{}
});
