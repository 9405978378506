import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { AvatarComponent } from '../../atoms/Avatar/Avatar';
import { ButtonComponent } from '../../atoms/Button/Button';
import './CardTeamMember.css';

interface CardTeamMemberProps {
  userName?: string;
  urlImage?: string;
  status?: 'In branch' | 'In project' | 'Suspended' | 'Inactive';
  cardType?: 'basic' | 'result';
  userDescription?: string;
  CardActionsArray?: any[];
  SkillArray?: any[];
}

export const CardTeamMember = ({
  userName = 'userName',
  urlImage = 'https://cdn.pixabay.com/photo/2018/01/04/21/15/young-3061652_960_720.jpg',
  cardType = 'basic',
  status = 'In branch',
  userDescription = 'About him or her About him or her About him or her About him or her About him or her About him or her',
  CardActionsArray,
  SkillArray
}: CardTeamMemberProps) => {
  return (
    <Card
      sx={{ maxWidth: 345 }}
      className={
        cardType === 'basic'
          ? 'class-CardTeamMember'
          : cardType === 'result'
          ? 'class-cardResult'
          : ''
      }
    >
      {SkillArray && SkillArray.length > 0 && (
        <span>
          {SkillArray.map((item, key) => (
            <ButtonComponent
              key={key}
              backgroundColor={item.backgroundColor}
              borderRadius={item.borderRadius}
              color={item.color}
              iconBolean={item.iconBolean}
              iconColor={item.iconColor}
              iconType={item.iconType}
              isIconOnly={item.isIconOnly}
              label={item.label}
              primary={item.primary}
              variant={item.variant}
              disabled={item.disabled}
            />
          ))}
        </span>
      )}
      <AvatarComponent name={userName} size='big' urlImage={urlImage} />
      <Typography
        gutterBottom
        variant='h6'
        component='div'
        className={
          status === 'In branch'
            ? 'class-InBranch'
            : status === 'In project'
            ? 'class-InProject'
            : status === 'Suspended'
            ? 'class-Suspended'
            : ''
        }
      >
        {status}
      </Typography>
      <CardContent>
        <Typography gutterBottom variant='h5' component='div'>
          {userName}
        </Typography>
        {userDescription ?? (
          <Typography variant='body2' color='text.secondary'>
            {userDescription}
          </Typography>
        )}
      </CardContent>
      {CardActionsArray && CardActionsArray.length > 0 && (
        <CardActions>
          {CardActionsArray.map((element, key) => (
            <ButtonComponent
              key={key}
              backgroundColor={element.backgroundColor}
              borderRadius={element.borderRadius}
              color={element.color}
              iconBolean={element.iconBolean}
              iconColor={element.iconColor}
              iconType={element.iconType}
              isReverseIcon={element.isReverseIcon}
              label={element.label}
              onClick={element.onClick}
            />
          ))}
        </CardActions>
      )}
    </Card>
  );
};
