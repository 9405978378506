import { Grid } from "@mui/material"
import { FC, useContext } from "react"
import { Controller, ControllerRenderProps, FieldValues } from "react-hook-form"
import { NotificationManager } from "react-notifications"
import SkillSelectProfile from "src/components/moleculas/SkillSelectProfile"
import SkillsTable from "src/components/moleculas/SkillsTable"
import { iSkillFixed } from "src/interfaces/skills.interfaces"
import { hasPermissions } from "src/helpers/user/UserHelper"
import { EmployeeContext } from "src/pages/Employees/Hooks/Employee-context"
import { RegisterContext } from "src/contexts/register.context"
import { useRecoilState } from "recoil"
import authState from "src/recoil/auth"

interface iProps {
  isEdit: boolean
  editMethods: any
  withContainer?: boolean
}

const UserTechs: FC<iProps> = ({
  isEdit,
  editMethods,
  withContainer = false,
}) => {
  // eslint-disable-next-line
  const [user] = useRecoilState(authState.user)
  const { methods } = useContext(RegisterContext)
  const { selectedUser } = useContext(EmployeeContext)

  const { control } = isEdit ? editMethods : methods

  const userHasPermissions = hasPermissions([
    ...(user.companyRole?.permissions || []),
    ...(user.permissions || []),
  ])

  const canEdit = !(userHasPermissions("user:update") || user.id === selectedUser?.id)

  const handleAddSkill = (
    newSkill: iSkillFixed,
    field: ControllerRenderProps<FieldValues, "skills">
  ) => {
    const isSkillAlreadyAdded = field.value?.find(
      (skill: iSkillFixed) => skill.tech === newSkill.tech
    )

    if (isSkillAlreadyAdded) {
      NotificationManager.warning(
        "Skills cannot be duplicated",
        "Warning",
        2000
      )
      return null
    }

    const newSkills = [...(field.value ? field.value : []), newSkill]
    field.onChange(newSkills)
  }

  const handleDeleteSkill = (
    id: string,
    field: ControllerRenderProps<FieldValues, "skills">
  ) => {
    const currentSkills = field.value.filter(
      (skill: iSkillFixed) => skill.id !== id
    )
    field.onChange(currentSkills)
  }

  const content = () => (
    <Controller
      name="skills"
      control={control}
      render={({ field }) => (
        <>
          <SkillSelectProfile
            spacing={1}
            onAddSkill={(newSkill) => handleAddSkill(newSkill, field)}
            disabled={canEdit}
          />
          <Grid item sm={12} md={12}>
            <SkillsTable
              skills={field.value ?? []}
              onDelete={(id: string) => handleDeleteSkill(id, field)}
            />
          </Grid>
        </>
      )}
    />
  )

  if (withContainer) {
    return (
      <Grid container spacing={1} alignItems="center">
        {content()}
      </Grid>
    )
  }

  return content()
}

export default UserTechs
