import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { FC, useState, SyntheticEvent } from "react"
import BioData from "src/pages/MyProfile/steps/BioData"
import AboutMe from "src/pages/MyProfile/steps/AboutMe"
import TechLevel from "src/pages/MyProfile/steps/TechLevel"
import Certificate from "src/pages/MyProfile/steps/Certificate"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  tabIndex: number
}

function TabPanel(props: TabPanelProps) {
  const { children, tabIndex, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={tabIndex !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  )
}

function applyProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

interface iTabsEditUser {
  isEdit: boolean
  editMethods: any
}

const TabsEditUser: FC<iTabsEditUser> = ({ isEdit, editMethods }) => {
  const [tabIndex, setTabIndex] = useState(0)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Bio Data" {...applyProps(0)} />
          <Tab label="About Me" {...applyProps(1)} />
          <Tab label={"Tech Level"} {...applyProps(2)} />
          <Tab label={"Certificate"} {...applyProps(3)} />
        </Tabs>
      </Box>
      <TabPanel tabIndex={tabIndex} index={0}>
        <BioData isEdit={isEdit} editMethods={editMethods} />
      </TabPanel>
      <TabPanel tabIndex={tabIndex} index={1}>
        <AboutMe isEdit={isEdit} editMethods={editMethods} />
      </TabPanel>
      <TabPanel tabIndex={tabIndex} index={2}>
        <TechLevel isEdit={isEdit} editMethods={editMethods} />
      </TabPanel>
      <TabPanel tabIndex={tabIndex} index={3}>
        <Certificate isEdit={isEdit} editMethods={editMethods} />
      </TabPanel>
    </Box>
  )
}

export default TabsEditUser
