import dayjs from "dayjs"

class DateHelper {
  public getWeekendDays(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs): number {
    let weekendDays = 0
    for (
      let date = startDate;
      date.isBefore(endDate);
      date = date.add(1, "day")
    ) {
      if (date.day() === 0 || date.day() === 6) {
        weekendDays++
      }
    }
    return weekendDays
  }

  // parse date to mm/dd/yyyy format string
  public toString(date: Date): string {
    const month = date.getMonth() + 1
    const day = date.getDate()
    const year = date.getFullYear()
    return `${month}/${day}/${year}`
  }

  public isValidRange(startDate: Date, endDate: Date): boolean {
    return startDate.getTime() <= endDate.getTime()
  }

  public isFutureDate(date: Date): boolean {
    return date.getTime() > new Date().getTime()
  }

  public getTotalDays(
    start: string,
    end: string,
    withoutWeekends: boolean = true
  ): number {
    const startDate = dayjs(start)
    const endDate = dayjs(end)
    const totalDays = endDate.diff(startDate, "day") + 1
    if (withoutWeekends) {
      return totalDays - this.getWeekendDays(startDate, endDate)
    }
    return totalDays
  }

  public isValidFutureRange(startDate: Date, endDate: Date): boolean {
    const isFutureStartDate = this.isFutureDate(startDate)
    const isFutureEndDate = this.isFutureDate(endDate)
    const isValidRange = this.isValidRange(startDate, endDate)

    return isFutureStartDate && isFutureEndDate && isValidRange
  }

  public getToday(): string {
    return this.toString(new Date())
  }

  public isExpired(date: string): boolean {
    const today = new Date()
    const endDate = new Date(date)
    return today.getTime() > endDate.getTime()
  }

  public yearsBetween(date1: Date, date2: Date): number {
    const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime())
    const diffInYears = diffInMilliseconds / (1000 * 60 * 60 * 24 * 365)
    return parseFloat(diffInYears.toFixed(1))
  }

  public dateDiff(date1: Date, date2: Date) {
    let months
    months = (date2.getFullYear() - date1.getFullYear()) * 12
    months -= date1.getMonth()
    months += date2.getMonth()

    // Check the number of days between the two dates
    let daysDiff = date2.getDate() - date1.getDate()

    // If the number of days is negative, decrement the number of months
    if (daysDiff < 0) {
      months--
    }

    let years = Math.floor(months / 12)
    months = months % 12
    return { years, months }
  }
}

export default new DateHelper()
