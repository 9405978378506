import React from 'react'

export const IconAngular = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.757"
      height="23.453"
      viewBox="0 0 21.757 23.453"
      fill="white"
    >
      <path
        id="angular-brands"
        d="M24.884,44.36h3.989l-1.995-4.8ZM26.879,32,16,35.895l1.665,14.433,9.214,5.125,9.214-5.125,1.665-14.433Zm6.816,17.894H31.15L29.779,46.47h-5.79l-1.372,3.424H20.068l6.811-15.3Z"
        transform="translate(-16 -32)"
      />
    </svg>
  )
}
