import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography, Button } from '@mui/material'
// Molecules
import ClientsTable from 'src/components/moleculas/ClientsTable'
import Spinner from 'src/components/moleculas/Spinner/Index'
// Organisms
import withSidebar from 'src/components/organisms/Sidebar'
// Types
import ROUTES from 'src/types/Routes'
import { SearchFilterComponent } from 'src/stories/components/ui/molecules'
import { ClientContext } from './client-context'

import ProtectedComponent from 'src/components/atoms/ProtectedComponent'

const ProofSearchFilterComponent = () => {
  const {
    searchBarText,
    handleSearchBarFilter,
    listInputDropdown,
    cleanFilters
  } = useContext(ClientContext)

  return <SearchFilterComponent
    className="class-CLIENT-SearchFilterComponent"
    onChange={handleSearchBarFilter}
    value={searchBarText}
    listInputDropdown={listInputDropdown}
    onClearFilters={cleanFilters}
  />
}

const ViewClients: React.FC = () => {
  const {
    loading,
    filteredClients
  } = useContext(ClientContext)

  const navigate = useNavigate()

  return (
    <>
      <Grid container spacing={3} alignItems="center" pb={4} pt={4}>
        <Grid item xs={6} sx={{display: "block"}}>
            <Typography variant="h6">Show our clients</Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <ProtectedComponent requiredPermissions={["client:create"]}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => navigate(ROUTES.CLIENTS_CREATE)}
                sx={{ backgroundColor: 'primary.blue', borderRadius: "30px", width: '260px', height: '62px' }}
              >
                Add a Client
              </Button>
            </ProtectedComponent>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {loading ? <Spinner /> : <ClientsTable clients={filteredClients ? filteredClients : []} />}
        </Grid>
    </>
  )
}

export default withSidebar(ViewClients, {
  showProfileBar: true,
  searchComponent: <ProofSearchFilterComponent />
})
