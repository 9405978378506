import { useEffect, ReactNode, FC } from "react"
import { Grid, ListItemIcon } from "@mui/material"
import { styled } from "@mui/material/styles"
import * as Icons from "@mui/icons-material"
import Box from "@mui/material/Box"
import { useNavigate } from "react-router-dom"
import NAVIGATION_ROUTES, { NavigationItem } from "./navigation"
import { useRecoilState, useRecoilValue } from "recoil"
import authState from "src/recoil/auth"
import ROUTES from "src/types/Routes"
import { SideMenuLeft } from "src/stories/components/ui/molecules/SideMenuLeft"
import { MenuItemComponent } from "src/stories/components/ui/atoms"
import userService from "src/services/users/user.service"
import { iUserWithId } from "src/interfaces/user.interfaces"
import { UserLoggedComponent } from "src/stories/components/ui/molecules/userLogged"
import { hasPermissions } from "src/helpers/user/UserHelper"
interface Props {
  children?: ReactNode
}

function Sidebar({ children }: Props) {
  const navigate = useNavigate()
  const [user, setUser] = useRecoilState(authState.user)

  const userHasPermission = hasPermissions([
    ...(user?.permissions || []),
    ...(user?.companyRole?.permissions || []),
  ])

  const currentNavigations = NAVIGATION_ROUTES.filter(
    (navigationItem: NavigationItem) =>
      userHasPermission(navigationItem.permissions)
  )

  useEffect(() => {
    if (user?.isFirstLogin) {
      localStorage.setItem("isFirstLogin", "true")
      navigate(ROUTES.MY_PROFILE)
    }
  }, [user, navigate])

  const handleLogout = () => {
    setUser({} as iUserWithId)
    userService.onLogout()
    navigate(ROUTES.HOME)
  }

  const handleNavigate = (navigationItem: NavigationItem) => {
    navigate(navigationItem.to)
  }

  const renderItem = (navigationItem: NavigationItem, index: number) => {
    return (
      <MenuItemComponent
        key={`navigationItem-${index}`}
        divider={false}
        disabled={navigationItem.isDisabled}
        onClick={() => handleNavigate(navigationItem)}
      >
        <ListItemIcon>{<navigationItem.icon />}</ListItemIcon>
        <p> {navigationItem.label}</p>
      </MenuItemComponent>
    )
  }

  return (
    <Box sx={{ display: "flex" }} p={2}>
      <SideMenuLeft>
        {currentNavigations.map(renderItem)}
        {/* <MenuItemComponent divider key={NAVIGATION_ROUTES.length + 2} disabled>
          <BasicSwitches />
          <p>Light mode</p>
        </MenuItemComponent> */}
        <MenuItemComponent
          key={NAVIGATION_ROUTES.length + 3}
          onClick={handleLogout}
        >
          <ListItemIcon>
            <Icons.PowerSettingsNew />
          </ListItemIcon>
          <p>Log out</p>
        </MenuItemComponent>
      </SideMenuLeft>
      <Main>{children}</Main>
    </Box>
  )
}

const Main = styled("div")`
  flex-grow: 1;
  padding-left: ${({ theme }) => theme.spacing(3)};
`

export interface SidebarConfig {
  showProfileBar?: boolean
  searchComponent?: any
}

const withSidebar = (WrappedComponent: FC, config?: SidebarConfig) => {
  return (props: any) => {
    const { showProfileBar, searchComponent } = config || {}
    const currentUser = useRecoilValue(authState.user)
    const navigate = useNavigate()

    if (!showProfileBar) {
      return (
        <Sidebar>
          <WrappedComponent {...props} />
        </Sidebar>
      )
    }

    return (
      <Sidebar>
        <Grid container>
          <Grid item xs={9}>
            {searchComponent}
          </Grid>
          <Grid item xs={3}>
            <UserLoggedComponent
              name={currentUser.fullName}
              urlImg={currentUser.photoURL}
              position={currentUser.companyRole?.name}
              onClick={() => navigate(ROUTES.PROFILE)}
            />
          </Grid>
          <WrappedComponent {...props} />
        </Grid>
      </Sidebar>
    )
  }
}

export default withSidebar
