import { Button } from '@mui/material'
import React, { FC } from 'react'
import googleIcon from 'src/assets/images/google-white-icon.png'

interface IProps {
  onClick: () => void
}

const GoogleLogginButton: FC<IProps> = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      startIcon={<img alt="panda" width={16} src={googleIcon} />}
      style={{ textTransform: 'lowercase', backgroundColor: '#0083D2' }}
    >
      Login with your azumo account
    </Button>
  )
}
export default GoogleLogginButton
