import React from 'react';
import { IconAdmins } from '../../../../../assets/svg/IconAdmins';
import { IconFilter } from '../../../../../assets/svg/IconFilter';
import { IconHome } from '../../../../../assets/svg/IconHome';
import { IconLogOut } from '../../../../../assets/svg/IconLogOut';
import { IconProjects } from '../../../../../assets/svg/IconProjects';
import { IconPTO } from '../../../../../assets/svg/IconPTO';
import { IconRoles } from '../../../../../assets/svg/IconRoles';
import { IconSave } from '../../../../../assets/svg/IconSave';
import { IconSearch } from '../../../../../assets/svg/IconSearch';
import { IconSkills } from '../../../../../assets/svg/IconSkills';
import { IconTeamMembers } from '../../../../../assets/svg/IconTeamMembers';
import { IconAglesLeft } from '../../../../../assets/svg/IconAglesLeft';
import { IconAnglesRight } from '../../../../../assets/svg/IconAnglesRight';
import { IconCardMode } from '../../../../../assets/svg/IconCardMode';
import { IconDoc } from '../../../../../assets/svg/IconDoc';
import { IconEye } from '../../../../../assets/svg/IconEye';
import { IconLocate } from '../../../../../assets/svg/IconLocate';
import { IconPen } from '../../../../../assets/svg/IconPen';
import { IconTable } from '../../../../../assets/svg/IconTable';
import { IconTrash } from '../../../../../assets/svg/IconTrash';
import { IconAngular } from '../../../../../assets/svg/IconAngular';
import { IconReact } from '../../../../../assets/svg/IconReact';

export const BasicIconSet = [
  {
    name: 'IconAdmins',
    component: IconAdmins
  },
  {
    name: 'IconFilter',
    component: IconFilter
  },
  {
    name: 'IconHome',
    component: IconHome
  },
  {
    name: 'IconLogOut',
    component: IconLogOut
  },
  {
    name: 'IconProjects',
    component: IconProjects
  },
  {
    name: 'IconPTO',
    component: IconPTO
  },
  {
    name: 'IconRoles',
    component: IconRoles
  },
  {
    name: 'IconSave',
    component: IconSave
  },
  {
    name: 'IconSearch',
    component: IconSearch
  },
  {
    name: 'IconSkills',
    component: IconSkills
  },
  {
    name: 'IconTeamMembers',
    component: IconTeamMembers
  },
  {
    name: 'IconAglesLeft',
    component: IconAglesLeft
  },
  {
    name: 'IconAnglesRight',
    component: IconAnglesRight
  },
  {
    name: 'IconCardMode',
    component: IconCardMode
  },
  {
    name: 'IconDoc',
    component: IconDoc
  },
  {
    name: 'IconEye',
    component: IconEye
  },
  {
    name: 'IconLocate',
    component: IconLocate
  },
  {
    name: 'IconPen',
    component: IconPen
  },
  {
    name: 'IconTable',
    component: IconTable
  },
  {
    name: 'IconTrash',
    component: IconTrash
  },
  {
    name: 'IconAngular',
    component: IconAngular
  },
  {
    name: 'IconReact',
    component: IconReact
  }
];
