import {
  addDoc,
  collection,
  doc,
  documentId,
  getDocs,
  query,
  updateDoc,
  where,
  orderBy,
} from "firebase/firestore"
import { Role, RoleWithId } from "src/interfaces/roles.interfaces"
import { COLLECTIONS, db } from "../firebase/firebase.service"

export const getRoles = async () => {
  try {
    const q = query(
      collection(db, COLLECTIONS.ROLES),
      // You cannot order your query by any field included in an equality (=) or in clause. - firestore docs
      where("deleted", "!=", true),
      // your first ordering must be on the same field. - firestore docs
      orderBy("deleted"),
      orderBy("name")
    )
    const querySnapshot = await getDocs(q)
    let roles: RoleWithId[] = []
    querySnapshot.forEach((doc) => {
      let skill = { id: doc.id, ...(doc.data() as Role) }
      roles.push(skill)
    })
    return roles
  } catch (err: any) {
    console.error(err.message)
    return []
  }
}

export const getRole = async (id: number): Promise<RoleWithId | string> => {
  try {
    let skill: RoleWithId | undefined
    //TODO maybe use .converter() like in the backend project
    const q = query(
      collection(db, COLLECTIONS.ROLES),
      where(documentId(), "==", id),
      where("deleted", "==", false)
    )

    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      skill = { id: doc.id, ...(doc.data() as Role) }
    })
    if (!skill) {
      throw new Error("No roles found")
    }
    return skill
  } catch (err: any) {
    console.error(err.message)
    return err.message
  }
}

export const addRole = async (role: string) => {
  try {
    const collec = collection(db, COLLECTIONS.ROLES)
    const response = await addDoc(collec, {
      name: role,
      deleted: false,
      permissions: [
        "home:view",
        "project:view",
        "project:read",
        "user:view",
        "user:read",
      ],
    })
    return { response }
  } catch (err: any) {
    console.error(err.message)
    return {
      statusCode: err.code || 500,
      message: err.message || "Error while adding skill",
    }
  }
}

export const deleteRole = async (id: string) => {
  try {
    const document = doc(db, COLLECTIONS.ROLES, id)
    const updatedDoc = await updateDoc(document, { deleted: true })
    console.log(updatedDoc)
    return { response: updatedDoc }
  } catch (err: any) {
    console.error(err.message)
    return {
      statusCode: err.code || 500,
      message: err.message || "Error while deliting skill",
    }
  }
}

export const updateRole = async (id: string, role: string) => {
  try {
    const document = doc(db, COLLECTIONS.ROLES, id)
    const updatedDoc = await updateDoc(document, { name: role })
    console.log(updatedDoc)
    return { response: updatedDoc }
  } catch (err: any) {
    console.error(err.message)
    return {
      statusCode: err.code || 500,
      message: err.message || "Error while updating skill",
    }
  }
}

export default {
  getRoles,
  getRole,
  addRole,
  deleteRole,
  updateRole
}
