import { Grid, Typography } from "@mui/material"
import { useContext, useMemo, useEffect, useState } from "react"
import FormCoverPicture from "src/components/atoms/FormCoverPicture"
import FormDatePicker from "src/components/atoms/FormDatePicker"
import FormGoogleInput from "src/components/atoms/FormGoogleInput"
import FormInputText from "src/components/atoms/FormInputText"
import FormProfilePicture from "src/components/atoms/FormProfilePicture"
import FormSelect from "src/components/atoms/FormSelect"
import FormSwitch from "src/components/atoms/FormSwitch"
import InputProfile from "src/components/atoms/InputProfile"
import { RegisterContext } from "src/contexts/register.context"
import {
  SENIORITY_OPTIONS,
  ENGLISH_LEVEL_OPTIONS,
  GENDER_OPTIONS,
} from "src/interfaces/user.interfaces"
import UserHelper, { hasPermissions } from "src/helpers/user/UserHelper"
import "../BioData/BioData.scss"

import { EmployeeContext } from "src/pages/Employees/Hooks/Employee-context"
import { getRoles } from "src/services/roles/roles.service"
import { RoleWithId } from "src/interfaces/roles.interfaces"
import { TechWithId } from "src/interfaces/skills.interfaces"
import { getSkills } from "src/services/skills/skills.service"

import authState from "src/recoil/auth"
import { useRecoilState } from "recoil"
import dayjs from "dayjs"
import DateHelper from "src/helpers/date/DateHelper"

interface iProps {
  isEdit: boolean
  editMethods: any
}

const BioData = ({ isEdit, editMethods }: iProps) => {
  const [authUser] = useRecoilState(authState.user)
  const { selectedUser } = useContext(EmployeeContext)
  const [roles, setRoles] = useState<RoleWithId[]>([])
  const [techs, setTechs] = useState<TechWithId[]>([])

  useEffect(() => {
    getRoles().then(setRoles)
    getSkills().then((result) => setTechs(result.response as TechWithId[]))
  }, [])

  const { methods } = useContext(RegisterContext)
  const { control, getValues } = isEdit ? editMethods : methods
  const formValues = getValues()

  const rolesOptions = roles!.map((role) => ({
    value: role.name,
    label: role.name,
  }))

  const userHasPermission = hasPermissions([
    ...(authUser?.permissions || []),
    ...(authUser?.companyRole?.permissions || []),
  ])
  const canEdit = userHasPermission("user:update")
  const canEditByPermissionOrMyOwnProfile = !(canEdit || authUser.id === selectedUser?.id)

  const techOptions = useMemo(() => {
    return techs.map((tech) => ({
      value: tech.tech,
      label: tech.tech,
    }))
  }, [techs])

  const dateDiff = DateHelper.dateDiff(
    new Date(getValues("companyStartDate")),
    getValues("companyEndDate")
      ? new Date(getValues("companyEndDate"))
      : new Date()
  )

  return (
    <Grid className="class-BioData">
      <Grid container spacing={4} alignItems="center" pb={4}>
        <Grid item sm={12} md={4}>
          <Typography variant="h6">Personal</Typography>
        </Grid>
        <Grid
          container
          item
          sm={12}
          md={8}
          justifyContent="flex-end"
          alignItems="center"
        >
          Active
          <FormSwitch control={control} name="isCurrentEmployee" disabled={!canEdit}/>
          {/* In bench
          <FormSwitch control={control} name="inBench" disabled={!canEdit} /> */}
        </Grid>
        <Grid item sm={12} md={3}>
          <FormProfilePicture name="photoURL" control={control} />
        </Grid>
        <Grid item sm={12} md={9}>
          <FormCoverPicture name="coverPhotoURL" control={control} />
        </Grid>
        <Grid container item columnSpacing={4}>
          <Grid item sm={12} md={4}>
            <FormInputText
              label="Full Name *"
              placeholder="Name and Last name"
              name="fullName"
              control={control}
              required
              disabled={!canEdit}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <FormDatePicker
              label="Birthdate"
              control={control}
              name="birthDate"
              disabled={!canEdit}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <FormSelect
              label="Gender"
              name="gender"
              control={control}
              options={GENDER_OPTIONS}
              disabled={!canEdit}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} md={4}>
          <FormInputText
            label="Telephone"
            placeholder="+1 000 0000 000"
            name="phoneNumber"
            control={control}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormGoogleInput
            onlyCountries={true}
            label="Nationality"
            control={control}
            name="nationality"
            disabled={!canEdit}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormGoogleInput
            onlyCountries={true}
            label="Location"
            control={control}
            name="location"
            disabled={!canEdit}
          />
        </Grid>
        <Grid item sm={12} md={12}>
          <Typography variant="h6">Professional</Typography>
        </Grid>
        <Grid item sm={12} md={4}>
          <FormSelect
            label="Role *"
            name="companyRole.name"
            control={control}
            options={rolesOptions}
            required
            disabled={!canEdit}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormSelect
            label="Seniority *"
            name="seniority"
            control={control}
            options={SENIORITY_OPTIONS}
            required
            disabled={!canEdit}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormSelect
            label="Primary Skill *"
            name="skillPrincipals"
            control={control}
            options={techOptions}
            disabled={!canEdit}
            required
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormDatePicker
            label="Company Start Date *"
            control={control}
            name="companyStartDate"
            required
            disabled={!canEdit}
            maxDate={dayjs(new Date())}
            isHookForm
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormDatePicker
            label="Company End Date"
            control={control}
            name="companyEndDate"
            disabled={!canEdit}
            minDate={dayjs(new Date(formValues.companyStartDate))}
            maxDate={dayjs(new Date())}
            isHookForm
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormSelect
            label="English level"
            name="englishLevel"
            control={control}
            options={ENGLISH_LEVEL_OPTIONS}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <InputProfile
            label="Tenure year(s)"
            placeholder="Tenure year(s)"
            value={
              !Number.isNaN(dateDiff.years) ? `${dateDiff.years} Year(s)` : "--"
            }
            onChange={() => {}}
            disabled
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <InputProfile
            label="Tenure month(s)"
            placeholder="Tenure month(s)"
            value={
              !Number.isNaN(dateDiff.months)
                ? `${dateDiff.months} Month(s)`
                : "--"
            }
            onChange={() => {}}
            disabled
          />
        </Grid>
        <Grid item sm={12} md={12}>
          <Typography variant="h6">Other</Typography>
        </Grid>
        <Grid item sm={12} md={12}>
          <FormInputText
            label="About me"
            placeholder="Tell us something about you"
            name="bio"
            control={control}
            rows={2}
            disabled={canEditByPermissionOrMyOwnProfile}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormInputText
            label="LinkedIn"
            placeholder="Name and last name"
            name="linkedin"
            control={control}
            disabled={canEditByPermissionOrMyOwnProfile}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormInputText
            label="Github"
            placeholder="https://"
            name="github"
            control={control}
            disabled={canEditByPermissionOrMyOwnProfile}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormInputText
            label="Portfolio"
            placeholder="https://"
            name="portfolio"
            control={control}
            disabled={canEditByPermissionOrMyOwnProfile}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BioData
