import React, { useEffect } from "react"
import { NotificationManager } from "react-notifications"
// import userService from 'src/services/users/user.service'
import Button from "@mui/material/Button"
// import * as Icons from '@mui/icons-material'
import { Box } from "@mui/system"
import { useNavigate } from "react-router-dom"
import Bio from "src/components/moleculas/EmployeDetailsComponent/Bio"
import Skills from "src/components/moleculas/EmployeDetailsComponent/Skills"
import PersonalData from "src/components/moleculas/EmployeDetailsComponent/PersonalData"
import { useRecoilState } from "recoil"
import authState from "src/recoil/auth"
// import { iUserWithId } from 'src/interfaces/user.interfaces'
import CertificationsView from "src/components/organisms/CertificationView"
import withSidebar from "src/components/organisms/Sidebar"
import ROUTES from "src/types/Routes"
import AboutMeEmployee from "src/components/moleculas/EmployeDetailsComponent/AboutMeEmployee"
import { Typography } from "@mui/material"
import { CardTeamMember } from "src/stories/components"
import useEmployees from "./Employees/Hooks/use-employees"
import { useRecoilValue } from "recoil"
import UserHelper, { hasPermissions } from "src/helpers/user/UserHelper"
import ProfessionalView from "src/components/moleculas/ProfessionalView"

const Profile = () => {
  const { employees } = useEmployees()
  const navigate = useNavigate()
  const [
    user,
    // setUser
  ] = useRecoilState(authState.user)

  const currentUserLoged = useRecoilValue(authState.user)
  const userHasPermissions = hasPermissions([
    ...(currentUserLoged.companyRole?.permissions || []),
    ...(currentUserLoged.permissions || []),
  ])

  useEffect(() => {
    if (user) {
      if (localStorage.getItem("isFirstLogin")) {
        // setModalEdit(true)
        NotificationManager.success("Pleas fill your personal data", "Welcome")
        localStorage.removeItem("isFirstLogin")
      }
    }
  }, [user])

  // const handleLogout = () => {
  //   setUser({} as iUserWithId)
  //   userService.onLogout()
  //   navigate(ROUTES.HOME)
  // }

  if (!user) return null

  return (
    <div className="px-4 sm:px-6 lg:px-8 class-profile">
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          gap: 2,
          height: 60,
          paddingBottom: "20px",
        }}
      >
        <Button
          onClick={() => navigate(ROUTES.MY_PROFILE)}
          type="button"
          variant="contained"
          sx={{
            backgroundColor: "primary.blue",
            borderRadius: "30px",
            width: "200px",
            maxHeight: "42px",
          }}
        >
          Edit
        </Button>
        {/* <div>
          <Button
            variant="text"
            style={{ justifyContent: 'right', minWidth: '40px'}}
            onClick={handleLogout}
          >
              <Icons.PowerSettingsNew style={{ color: '#E11F1F'}} />
          </Button>
        </div> */}
      </Box>
      <Typography
        sx={{
          marin: "auto",
          fontSize: 24,
          fontWeight: "medium",
          gap: 2,
        }}
      >
        <PersonalData currentPageEmployees={user} />
        <div
          className={
            userHasPermissions("user:edit")
              ? "class-child-admin class-child-profile"
              : " class-child-profile"
          }
        >
          {userHasPermissions("user:view") ? (
            <div>
              <ProfessionalView user={user} />
              <Skills currentPageEmployees={user} />
              <CertificationsView certifications={user.certifications} />
              <Bio currentPageEmployees={user} />
              <AboutMeEmployee currentUser={user} />
            </div>
          ) : (
            <div>
              <Bio currentPageEmployees={user} />
              <AboutMeEmployee currentUser={user} />
              <ProfessionalView user={user} />
              <Skills currentPageEmployees={user} />
              <CertificationsView certifications={user.certifications} />
            </div>
          )}
          {!userHasPermissions("user:manage") && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <Box
                sx={{
                  fontSize: 16,
                  fontWeight: "medium",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  color: "#828689",
                }}
              >
                Look another members
              </Box>
              {employees
                .filter((data, key) => key <= 4 && data.email !== user.email)
                .map((data) => (
                  <CardTeamMember
                    userName={data.fullName}
                    urlImage={data.photoURL}
                    status={
                      data.isCurrentEmployee
                        ? data.inBench
                          ? "In branch"
                          : "In project"
                        : "Inactive"
                    }
                    SkillArray={[
                      {
                        backgroundColor: "#0083D2",
                        borderRadius: "all",
                        color: "#4fc4ff",
                        disabled: true,
                        iconBolean: true,
                        iconColor: "#F5F5F5",
                        iconType: "IconReact",
                        isIconOnly: true,
                        label: "Button name",
                        primary: true,
                        variant: "contained",
                      },
                      {
                        backgroundColor: "#E11F1F",
                        borderRadius: "all",
                        color: "#4fc4ff",
                        disabled: true,
                        iconBolean: true,
                        iconColor: "#F5F5F5",
                        iconType: "IconAngular",
                        isIconOnly: true,
                        label: "Button name",
                        primary: true,
                        variant: "contained",
                      },
                      {
                        backgroundColor: "#618C03",
                        borderRadius: "all",
                        color: "#fafff0",
                        disabled: true,
                        iconBolean: false,
                        iconColor: "#F5F5F5",
                        isIconOnly: false,
                        label: "+88",
                        primary: true,
                        variant: "contained",
                      },
                    ]}
                    cardType="result"
                    userDescription=""
                  />
                ))}
            </div>
          )}
        </div>
      </Typography>
    </div>
  )
}

export default withSidebar(Profile)
