import React, { useContext, useEffect, useState, useMemo } from "react"
import { useLocation, useNavigate, useParams, matchPath } from "react-router-dom"
import { useForm } from "react-hook-form"
import { NotificationManager } from "react-notifications"
import { Grid, Typography } from "@mui/material"
import ProfileHeader from "src/components/organisms/ProfileHeader"
import {
  RegisterContext,
  withRegisterContextProvider,
} from "src/contexts/register.context"
import userService from "src/services/users/user.service"
import { iUserWithId } from "src/interfaces/user.interfaces"
import { LoadingButton } from "@mui/lab"
import { useRecoilState } from "recoil"
import authState from "src/recoil/auth"
import ROUTES from "src/types/Routes"
import ObjectHelper from "src/helpers/object/ObjectHelper"
import withSidebar from "src/components/organisms/Sidebar"
import TabsEditUser from "src/components/moleculas/NewFormEditUser"
import { EmployeeContext } from "../Employees/Hooks/Employee-context"

import "./MyProfile.scss"
import DateHelper from "src/helpers/date/DateHelper"
import useProjects from "../Projects/ViewProjects/use-projects"

const MyProfile = () => {
  const navigate = useNavigate()
  const [user, setUser] = useRecoilState(authState.user)
  const { methods } = useContext(RegisterContext)
  const { selectedUser, updateSelectedUser } = useContext(EmployeeContext)
  const editMethods = useForm<iUserWithId>({
    defaultValues: selectedUser,
    mode: "onChange",
  })
  const params = useParams()
  const id = params.idEmployee
  const location = useLocation()
  const isEdit = !!matchPath(`${ROUTES.EMPLOYEES}/:idEmployee/edit`, location.pathname)
  const { handleSubmit, reset, watch, setValue } = isEdit
    ? editMethods
    : methods
  const companyStartDate = watch("companyStartDate")
  const companyEndDate = watch("companyEndDate")
  const [isLoading, setIsLoading] = useState(false)
  const isFirstLogin = localStorage.getItem("isFirstLogin") === "true"
  const { projectsFiltered } = useProjects();
  // check inBench status by allocation
  const getNumberAllocationValueObj = useMemo(()=>{
    let getNumberAllocation = 0;

    const result = (num:number) => getNumberAllocation += num
    projectsFiltered.map(project => project.employees.filter(user => user.id === id && result(user.allocationPercentage)));

    return {
      getNumberAllocation,
      condition: getNumberAllocation > 50 ? false : true
    }
  },[projectsFiltered])
  // const CalculateInBechFunction = (id:string,isCurrentEmployee:boolean) => {
  //   const getNumberAllocationValueObj = useMemo(()=>{
  //     let getNumberAllocation = 0;

  //     const result = (num:number) => getNumberAllocation += num
  //     projectsFiltered.map(project => project.employees.filter(user => user.id === id && result(user.allocationPercentage)));

  //     return {
  //       getNumberAllocation,
  //       // condition: getNumberAllocation == 0
  //     }
  //   },[projectsFiltered])
  //   return isCurrentEmployee ? getNumberAllocationValueObj : "FALSE";
  // }
  //function to get user with him or her id
  const getSelectedUser = async () => {
    try {
      if (!selectedUser) {
        const data = (await userService.getUser(id!)) as any;
        updateSelectedUser(data)
        reset(data)
      }
    } catch (err) {
      console.error(err)
    }
  }
  //implement function to get user with him or her id
  useEffect(() => {
    getSelectedUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    //here we get the employee added start date and end date
    const startDate = new Date(companyStartDate)
    const endDate = companyEndDate ? new Date(companyEndDate) : new Date()

    //condition to update the end date only if is after start date
    if (!DateHelper.isValidRange(startDate, endDate)) {
      setValue("companyEndDate", companyStartDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyStartDate])

    // function to automate a isCurrentEmployee false only if companyEndDate passed
    useEffect(()=>{
      const validateCompanyEndDate = () => {
        const currentDate = new Date();
        const isEndDateAfterCurrentDate = new Date(companyEndDate) <= currentDate;
        isEndDateAfterCurrentDate ? setValue("isCurrentEmployee", false) : setValue("isCurrentEmployee", true)
      }
      validateCompanyEndDate()
    },[companyEndDate])

  //This function is to start update cycle
  const submitUser = async (userToUpdate: iUserWithId) => {
    //open login (open cycle)
    setIsLoading(true)
    //this const execute a function that returns an object with statusCode, message, data
    const response = await userService.updateUser(userToUpdate!.id, {
      ...ObjectHelper.removeEmptyProperties(userToUpdate),
      isFirstLogin: false,
      inBench: getNumberAllocationValueObj.condition
    })
    //notification manager ?
    const status = response.statusCode === 200 ? "success" : "error"
    NotificationManager[status](response.message, response.message, 1050)
    //update
    if (response.statusCode === 200) {
      //this will activate when the route is edit
      if (isEdit) updateSelectedUser(response.data as iUserWithId)
      //this will activate when the route is NOT edit
      if (!isEdit) setUser(response.data as iUserWithId)
      //This return you to homepage '/'
      navigate(ROUTES.HOME)
    }
    //close login (close cycle)
    setIsLoading(false)
  }
  //this function is use to update companyStartDate and companyEndDate
  const mapDatesToTimestamp = (userToUpdate: iUserWithId) => {
    return {
      ...userToUpdate,
      companyStartDate: new Date(userToUpdate.companyStartDate).getTime(),
      companyEndDate: userToUpdate.companyEndDate
        ? new Date(userToUpdate.companyEndDate).getTime()
        : new Date().getTime(),
    }
  }
  //this function is use to send all change before save
  const submit = async (user: iUserWithId) => {
    let userToUpdate = user

    if (
      typeof user.companyStartDate !== "number" ||
      typeof user.companyEndDate !== "number"
    )
      userToUpdate = mapDatesToTimestamp(user)

    submitUser(userToUpdate)
  }
  //this function is to take any error message
  const onSubmitError = () => {
    NotificationManager.warning("Please check all fields", "Warning", 2000)
  }

  return (
    <Grid>
      <ProfileHeader isLoading={isLoading} isFirstLogin={isFirstLogin} />
      <Grid item xs={12}>
        <Typography variant="overline">
          {isEdit ? "Account > Edit account" : "My Account > Edit my account"}
        </Typography>
      </Grid>
      {/* the two last function are using as parameters for this principal function */}
      <form id="userForm" onSubmit={handleSubmit(submit, onSubmitError)}>
        <TabsEditUser isEdit={isEdit} editMethods={editMethods} />

        <Grid item sm={12} md={12} className="button-container">
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => navigate(-1)}
            disabled={isFirstLogin}
            fullWidth
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginLeft: 1 }}
          >
            Save
          </LoadingButton>
        </Grid>
      </form>
    </Grid>
  )
}

export default withRegisterContextProvider(withSidebar(MyProfile))
