import React, { useState, useEffect} from "react"
import {
  Avatar,
  Box,
  CardMedia,
  Chip,
  Grid,
  Typography,
  Link,
} from "@mui/material"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import EmailIcon from "@mui/icons-material/Email"
import PhoneIcon from "@mui/icons-material/Phone"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter"
import GitHubIcon from "@mui/icons-material/GitHub"
import MaleIcon from "@mui/icons-material/Male"
import FemaleIcon from "@mui/icons-material/Female"
import { iUserWithId } from "src/interfaces/user.interfaces"
import pandaWeb from "../../../assets/images/PandaWeb.png"
import pandaBackground from "../../../assets/images/background.png"
import "./PersonalData.scss"
import Button from "@mui/material/Button"
import { getFlagURL } from "src/helpers/country/CountryHelper"
import GetAllocationTemporal from "src/pages/Employees/Hooks/AllocationComponent"

interface Props {
  currentPageEmployees: iUserWithId
}

const PersonalData = (props: Props) => {
  const [getAge, setGetAge] = useState(0)
  // const [getAllocationResult, setGetAllocationResult] = useState<any>({})

  const isMaleFemaleOrOther =
    props.currentPageEmployees?.gender === "Male" ||
    props.currentPageEmployees?.gender === "Female" ||
    props.currentPageEmployees?.gender === "Other"

  const flagURL = props.currentPageEmployees?.nationality?.description
    ? getFlagURL(props.currentPageEmployees.nationality.description)
    : null

  useEffect(()=>{
    const calculateAge = (birthdate:string) => {
      const birthdateArr = birthdate.split("/");
      const birthYear = parseInt(birthdateArr[2]);
      const birthMonth = parseInt(birthdateArr[0]);
      const birthDay = parseInt(birthdateArr[1]);

      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth() + 1;
      const currentDay = today.getDate();

      let age = currentYear - birthYear;

      if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
        age--;
      }
      setGetAge(age)
    }
    props.currentPageEmployees.birthDate && typeof props.currentPageEmployees.birthDate === "string" && calculateAge(props.currentPageEmployees.birthDate)
  },[props.currentPageEmployees])

  return (
    <>
      <div className="class-CardUserDetails">
        <div>
          <div />
          <CardMedia
            component="img"
            height="140"
            width="800"
            image={ props.currentPageEmployees?.coverPhotoURL || pandaBackground }
            alt={props.currentPageEmployees?.fullName}
          />
          <div>
            {props.currentPageEmployees?.linkedin && (
              <Link
                href={props.currentPageEmployees?.linkedin || "--"}
                target="_blank"
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {}}
                >
                  <LinkedInIcon />
                </Button>
              </Link>
            )}
            {props.currentPageEmployees?.github && (
              <Link
                href={props.currentPageEmployees?.github || "--"}
                target="_blank"
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {}}
                >
                  <GitHubIcon />
                </Button>
              </Link>
            )}
            {props.currentPageEmployees?.portfolio && (
              <Link
                href={props.currentPageEmployees?.portfolio || "--"}
                target="_blank"
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {}}
                >
                  <BusinessCenterIcon />
                </Button>
              </Link>
            )}
          </div>
        </div>
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 2,
            minWidth: 300,
            display: "grid",
            gridTemplateColumns: "200px auto",
            gap: "0 16px",
          }}
        >
          <Box
            display={"center"}
            flexDirection={"column"}
            justifyContent={"center"}
            sx={{ gridRow: "1 / span 3" }}
          >
            <Avatar
              sx={{
                width: "150px",
                height: "150px",
              }}
              variant="circular"
              alt={props.currentPageEmployees?.photoURL || pandaWeb}
              src={props.currentPageEmployees?.photoURL || pandaWeb}
              style={{ marginTop: "-16%" }}
            />
          </Box>
          <Box
            sx={{
              color: "text.secondary",
              display: "flex",
              fontSize: 14,
              gap: 2,
            }}
          >
            <Typography
              style={{
                color: "white",
                fontFamily: `Arial, Helvetica, sans-serif, 'regular'`,
                fontSize: "20px",
                textShadow: "1px 1px 1px #00000029",
              }}
            >
              <Box>{props.currentPageEmployees?.fullName}</Box>
            </Typography>
            {flagURL && (
              <img
                src={flagURL}
                width="30"
                alt={props.currentPageEmployees.nationality.description}
                style={{ marginLeft: "0.5rem" }}
              />
            )}
            {isMaleFemaleOrOther && (
              <Box
                sx={{
                  background: props.currentPageEmployees?.gender === "Other" ? "white" : "#0083D2",
                  color: "#fff",
                  padding: "0.4rem",
                  marginLeft: "0.6rem",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "2.5rem",
                }}
              >
                {
                  props.currentPageEmployees?.gender === "Male" ? <MaleIcon color="inherit" /> :
                  props.currentPageEmployees?.gender === "Female" ? <FemaleIcon color="inherit" /> :
                  props.currentPageEmployees?.gender === "Other" ? <div style={{width:'20px',height:'20px'}}></div> : <div>gender not added yet</div>
                }
              </Box>
            )}
            <Grid
              sx={{
                color: "text.secondary",
                fontSize: 14,
              }}
            >
              <Typography
                style={{
                  color: "white",
                  fontFamily: `Arial, Helvetica, sans-serif, 'regular'`,
                  textShadow: "1px 1px 1px #00000029",
                }}
              >
                  Age: {props.currentPageEmployees.birthDate && getAge !== 0 ? getAge : "Not added yet"}
              </Typography>
            </Grid>
            <Box sx={{ p: 2 }}>
              <Box sx={{ color: "text.secondary", fontSize: 14 }}>
                <GetAllocationTemporal
                  id={props.currentPageEmployees?.id}
                  inBench={props.currentPageEmployees?.inBench}
                  isCurrentEmployee={props.currentPageEmployees?.isCurrentEmployee}
                  isOnProfile={true}
                />
              </Box>
            </Box>
          </Box>
          <Grid
            container
            mt={3}
            sx={{
              color: "text.secondary",
              fontSize: 14,
            }}
          >
            <Grid item xs={4} sx={{ p: 2 }}>
              <Typography style={{ color: "#828689" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <LocationOnIcon style={{ fontSize: 16 }} />
                  <span>
                    <strong>
                      {props.currentPageEmployees?.location?.description ||
                        `Not added yet`}
                    </strong>
                  </span>
                </div>
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ p: 2 }}>
              <Typography style={{ color: "#0083D2" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <EmailIcon style={{ fontSize: 16 }} />
                  <span>{props.currentPageEmployees?.email}</span>
                </div>
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ p: 2 }}>
              <Typography style={{ color: "#0083D2" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <PhoneIcon style={{ fontSize: 16 }} />
                  <span>{props.currentPageEmployees?.phoneNumber || "--"}</span>
                </div>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  )
}

export default PersonalData
