import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import { getStorage } from 'firebase/storage'
import { firebaseConfig } from 'src/config/firebaseConfig'
import pandaConfig from 'src/config/pandaConfig'

export const app = initializeApp(firebaseConfig)

export const auth = getAuth()

/**
 * DB USAGEloginWithGoogle
 * https://firebase.google.com/docs/firestore/quickstart
 */
export const db = getFirestore(app)

/**
 * STORAGE USAGE
 * https://firebase.google.com/docs/storage/web/start
 */
export const storage = getStorage(app)

/**
 * FUNCTIONS USAGE
 * https://firebase.google.com/docs/emulator-suite/connect_functions
 */
export const functions = getFunctions(app)
// Functions.useFunctionsEmulator('http://localhost:5001')

export const COLLECTIONS = {
  USERS: `users-${pandaConfig.ENV}`,
  SKILLS: `skills-${pandaConfig.ENV}`,
  PENDING_SKILLS: `pendingSkills-${pandaConfig.ENV}`,
  ROLES: `roles-${pandaConfig.ENV}`,
  TIME_OFF: `timeOff-${pandaConfig.ENV}`,
  CLIENTS: `clients-${pandaConfig.ENV}`,
  PROJECTS: `projects-${pandaConfig.ENV}`,
}
