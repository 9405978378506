import React from "react"
import {
  Grid,
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
// Atoms
import SelectProfile from "src/components/atoms/SelectProfile"
import UsersAutoComplete, {
  IUserAutoComplete,
} from "src/components/atoms/UsersAutoComplete"
import DatePicker from "src/components/atoms/DatePicker"
import FaIconButton from "src/components/atoms/FaIconButton"
// Interfaces
import { iUserWithId, SENIORITY_OPTIONS } from "src/interfaces/user.interfaces"
import { IUserForProjectShow } from "src/interfaces/project.interface"
// Helpers
import dateHelper from "src/helpers/date/DateHelper"
import "./SeatsForProject.scss"

interface ISeatsForProjectProps {
  user: IUserForProjectShow
  handleUserChange: (
    key: keyof Omit<IUserForProjectShow, "id" | "fullName" | "photoURL">,
    value: string | number
  ) => void
  isCheckingUser: boolean
  selectedUser: IUserAutoComplete | null
  handleUserAutocompleteChange: (user: IUserAutoComplete | null) => void
  handleAddUser: () => void
  roleOptions: {
    value: string
    label: string
  }[]
  employees: iUserWithId[]
}

const SeatsForProject: React.FC<ISeatsForProjectProps> = ({
  user,
  handleUserChange,
  isCheckingUser,
  selectedUser,
  handleUserAutocompleteChange,
  handleAddUser,
  roleOptions,
  employees,
}) => {
  const handleStatusChange = (value: string) => {
    handleUserChange("status", value)

    if (value === "dedicated") {
      handleUserChange("allocationPercentage", 100)
    } else {
      handleUserChange("allocationPercentage", 0)
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography color="primary" variant="h6">
          Add seats
        </Typography>
      </Grid>
      <Grid item sm={12} md={4} mb={3} className="class-selectMember" style={{zIndex:"9"}}>
        <UsersAutoComplete
          users={employees.length ? employees.filter(user => user.isCurrentEmployee === true).map(result => result) : []}
          label="Employee"
          onChange={handleUserAutocompleteChange}
          selectedUser={selectedUser}
        />
      </Grid>
      <Grid item sm={12} md={4} mb={3}>
        <SelectProfile
          label="Role *"
          placeholder="Choose role"
          value={user.role.name}
          onChange={(value) => handleUserChange("role", value)}
          options={roleOptions}
          error={isCheckingUser && !user.role.id}
          helperText={
            isCheckingUser && !user.role.id ? "Field is required" : <> &nbsp;</>
          }
        />
      </Grid>
      <Grid item sm={12} md={4} mb={3}>
        <SelectProfile
          label="Seniority *"
          placeholder="Choose seniority"
          value={user.seniority}
          onChange={(value) => handleUserChange("seniority", value)}
          options={SENIORITY_OPTIONS}
          error={isCheckingUser && !user.seniority}
          helperText={
            isCheckingUser && !user.seniority ? (
              "Field is required"
            ) : (
              <> &nbsp;</>
            )
          }
        />
      </Grid>
      <Grid item sm={12} md={4} mb={3}>
        <FormControl>
          <FormLabel id="status">Status *</FormLabel>
          <RadioGroup
            row
            aria-labelledby="status"
            name="status"
            value={user.status}
            onChange={(_, value) => handleStatusChange(value)}
          >
            <FormControlLabel
              value="dedicated"
              label="Dedicated"
              control={<Radio />}
            />
            <FormControlLabel
              value="on-demand"
              label="On-demand"
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item sm={12} md={4} mb={3}>
        <DatePicker
          value={user?.dayStart ? new Date(user?.dayStart) : null}
          label="Start Date"
          onChange={(e) =>
            handleUserChange(
              "dayStart",
              e !== null ? dateHelper.toString(e) : ""
            )
          }
        />
      </Grid>
      <Grid item sm={12} md={4} mb={3}>
        <DatePicker
          value={user?.dayFinish ? new Date(user?.dayFinish) : null}
          label="End Date"
          onChange={(e) =>
            handleUserChange(
              "dayFinish",
              e !== null ? dateHelper.toString(e) : ""
            )
          }
        />
      </Grid>
      <Grid item sm={12} md={4} mb={3}>
        <SelectProfile
          label="Allocation *"
          placeholder="Choose allocation"
          value={`${user.allocationPercentage}`}
          onChange={(value) => handleUserChange("allocationPercentage", value)}
          options={[
            {
              label:"25%",
              value:25
            },
            {
              label:"50%",
              value:50
            },
            {
              label:"75%",
              value:75
            },
            {
              label:"100%",
              value:100
            }
          ]}
          disabled={user.status === "dedicated"}
        />
      </Grid>
      <Grid item sm={12} md={1} mb={1} className="class-buttonPlus">
        <FaIconButton
          onClick={handleAddUser}
          icon={faPlus}
          className="class-button-save-add-seat"
        />
      </Grid>
    </>
  )
}

export default SeatsForProject
