import React, { useState, useEffect, useCallback } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { NotificationManager } from "react-notifications"
import { Grid, Box, Typography, Link, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons"
// Atoms
import InputProfile from "src/components/atoms/InputProfile"
import ProfilePicture from "src/components/atoms/ProfilePicture"
import SelectProfile from "src/components/atoms/SelectProfile"
import FaIconButton from "src/components/atoms/FaIconButton"

// Molecules
import StakeHoldersTable from "src/components/moleculas/StakeHoldersTable"

// Organisms
import GoogleInput from "src/components/organisms/AutocompleteLocation"
import withSidebar from "src/components/organisms/Sidebar"

// Interfaces
import { iUserWithId } from "src/interfaces/user.interfaces"
import { IClient, IStakeHolder } from "src/interfaces/client.interfaces"

// Services
import ClientsService from "src/services/clients/clients.service"
import userService from "src/services/users/user.service"
// Helpers
import { isEmailValid } from "src/helpers/validator/ValidatorHelper"
// Types
import ROUTES from "src/types/Routes"
// Data
import { initialClient, initialStakeHolder } from "./data"
import "./ClientForm.scss"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import {useRecoilValue} from "recoil";
import authState from "../../../recoil/auth";

const ClientForm: React.FC = () => {
  const currentUser = useRecoilValue(authState.user);
  const navigate = useNavigate()
  const { id: clientId } = useParams()
  const location = useLocation()
  const isCreate = location.pathname.includes("create")
  const [loading, setLoading] = useState(false)
  const [logoFile, setLogoFile] = useState<File | undefined>(undefined)
  const [employees, setEmployees] = useState<iUserWithId[]>([])
  const [client, setClient] = useState<Omit<IClient, "id">>(initialClient)

  const [stakeHolder, setstakeHolder] =
    useState<IStakeHolder>(initialStakeHolder)

  // Validations
  const [isCheckingStakeHolder, setIsCheckingStakeHolder] =
    useState<boolean>(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)

  const handleClientChange = (
    key: keyof Omit<IClient, "id" | "logo" | "keyStakeHolders" | "sow">,
    value: string
  ) => {
    setClient((prev) => ({
      ...prev,
      [key]: value,
    }))
  }
  const handleCsmChange = (value: string) => {
    handleClientChange("csmId", value)
    const employee = employees.find((employee) => employee.id === value)
    const csmName = employee?.fullName ? employee.fullName : ""
    handleClientChange("csmName", csmName)
  }

  const handleStakeHolderChange = (
    key: keyof Omit<IStakeHolder, "photo">,
    value: string | File
  ) => {
    setstakeHolder((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const handleAddStakeHolder = () => {
    setIsCheckingStakeHolder(true)

    if (isValidStakeHolder) {
      const newStakeHolders = [...client?.keyStakeHolders]
      newStakeHolders.push(stakeHolder)

      setClient((prev) => ({
        ...prev,
        keyStakeHolders: newStakeHolders,
      }))

      setstakeHolder(initialStakeHolder)
      setIsCheckingStakeHolder(false)
    }
  }

  const handleDeleteStakeHolder = (email: string) => {
    const newStakeHolders = [...client?.keyStakeHolders].filter(
      (x) => x.email !== email
    )

    setClient((prev) => ({
      ...prev,
      keyStakeHolders: newStakeHolders,
    }))
  }

  const onSubmitForm = async () => {
    setIsFormSubmitted(true)

    if (isValidClient) {
      setLoading(true)
      const result = isCreate
        ? await ClientsService.createClient(client, logoFile)
        : await ClientsService.updateClient(clientId!, client, logoFile)
      setLoading(false)

      if (result.statusCode === 200) {
        NotificationManager.success(result.message, "Success", 3000)
        navigate(ROUTES.CLIENTS)
      } else {
        NotificationManager.error(result.message, "Error", 3000)
      }
    } else {
      NotificationManager.warning(
        "Please fill the fields correctly",
        "Error",
        3000
      )
    }
  }

  const getEmployees = async () => {
    setLoading(true)
    const users = await userService.getUsers()
    setLoading(false)

    setEmployees(users)
  }

  const getClient = useCallback(async () => {
    setLoading(true)
    const result = await ClientsService.getClient(clientId!)
    setLoading(false)

    if ("error" in result) {
      NotificationManager.error(result.error, "Error", 3000)
    } else {
      setClient(result)
    }
  }, [clientId])

  useEffect(() => {
    if (!isCreate) {
      getClient()
    }
    getEmployees()
  }, [getClient, isCreate])

  // Validations
  const isValidClient =
    !!client?.name && !!client?.linkedin && !!client?.country && client?.csmId
  const isValidStakeHolder =
    !!stakeHolder.name && !!stakeHolder.role && isEmailValid(stakeHolder.email)

  const DUMMY_STATUS = [
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Completed",
      label: "Completed",
    },
    {
      value: "OnHold",
      label: "On Hold",
    },
  ]

  return (
    <Grid container className="class-ClientForm">
      <Grid container spacing={4} alignItems="center" pb={4}>
        <Grid item xs={12}>
          <Typography variant="overline">
            <Link href={ROUTES.CLIENTS}>Client</Link>{" "}
            {isCreate ? " > Create a client" : " > Update Client"}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ProfilePicture
            isUsingFile
            value={client?.logo?.url || undefined}
            onChange={() => {}}
            imageFile={logoFile}
            onFileChange={(file) => setLogoFile(file)}
          />
        </Grid>

        <Grid item sm={12} md={4}>
          <InputProfile
            label="Client Name *"
            placeholder="Name"
            disabled={!['admin'].includes(currentUser.companyRole?.name?.toLowerCase())}
            value={client?.name}
            onChange={(e) => handleClientChange("name", e.target.value)}
            error={isFormSubmitted && !client?.name}
            helperText={
              isFormSubmitted && !client?.name ? "Field is required" : " "
            }
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <GoogleInput
            label={"Country *"}
            disabled={!['admin', 'csm'].includes(currentUser.companyRole?.name?.toLowerCase())}
            onlyCountries
            handleValue={(value: any) =>
              handleClientChange("country", value.description)
            }
            error={isFormSubmitted && !client?.country}
            helperText={
              isFormSubmitted && !client?.country ? "Field is required" : " "
            }
            sx={{ mt: 2 }}
          />
        </Grid>

        <Grid item sm={12} md={4}>
          <SelectProfile
            label="CSM *"
            disabled={!['admin'].includes(currentUser.companyRole?.name?.toLowerCase())}
            placeholder="Choose a member"
            value={client.csmId}
            onChange={(value) => handleCsmChange(value)}
            options={employees.reduce((csmEmployees: any, employee) => {
              if (employee.companyRole?.name === "CSM")
                csmEmployees.push({
                  value: employee.id,
                  label: employee.fullName,
                })
              return csmEmployees
            }, [])}
            error={isFormSubmitted && !client.csmId}
            helperText={
              isFormSubmitted && !client.csmId ? (
                "Field is required"
              ) : (
                <> &nbsp;</>
              )
            }
          />
        </Grid>

        <Grid item sm={12} md={4}>
          <SelectProfile
            label="Status *"
            disabled={!['admin', 'csm'].includes(currentUser.companyRole?.name?.toLowerCase())}
            placeholder="Choose client status"
            value={client.status}
            onChange={(value) => handleClientChange("status", value)}
            options={DUMMY_STATUS}
            error={isFormSubmitted && !client.status}
            helperText={
              isFormSubmitted && !client.status ? (
                "Field is required"
              ) : (
                <> &nbsp;</>
              )
            }
          />
        </Grid>

        <Grid item sm={12} md={4}>
          <InputProfile
            label="Linkedin"
            placeholder="Linkedin"
            disabled={!['admin', 'csm'].includes(currentUser.companyRole?.name?.toLowerCase())}
            value={client?.linkedin}
            onChange={(e) => handleClientChange("linkedin", e.target.value)}
            error={isFormSubmitted && !client?.linkedin}
            helperText={
              isFormSubmitted && !client?.linkedin ? "Field is required" : " "
            }
          />
        </Grid>

        <Grid item sm={12} md={4}>
          <InputProfile
            label="Website"
            placeholder="Website"
            disabled={!['admin', 'csm'].includes(currentUser.companyRole?.name?.toLowerCase())}
            value={client?.webpage}
            onChange={(e) => handleClientChange("webpage", e.target.value)}
            helperText=" "
          />
        </Grid>

        <Grid item sm={12} md={12}>
          <InputProfile
            label="Business description"
            disabled={!['admin', 'csm'].includes(currentUser.companyRole?.name?.toLowerCase())}
            placeholder="Brief description of the business"
            value={client?.businessDescription}
            onChange={(e) =>
              handleClientChange("businessDescription", e.target.value)
            }
            rows={4}
          />
        </Grid>

        { ['admin', 'csm'].includes(currentUser.companyRole?.name?.toLowerCase()) && (
          <Grid
            container
            item
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: "#707070" }}>
                Key stakeholders
              </Typography>
            </Grid>

            <Grid item sm={12} md={3} lg={2} xl={1}>
              <div style={{ height: 100 }}>
                <ProfilePicture
                  isUsingFile
                  onChange={() => {}}
                  imageFile={stakeHolder?.photoFile}
                  onFileChange={(file) =>
                    handleStakeHolderChange("photoFile", file)
                  }
                />
              </div>
            </Grid>

            <Grid item sm={12} md={9} lg={3} xl={3}>
              <InputProfile
                label="Name *"
                placeholder="Write his her name"
                value={stakeHolder?.name}
                onChange={(e) => handleStakeHolderChange("name", e.target.value)}
                error={isCheckingStakeHolder && !stakeHolder?.name}
                helperText={
                  isCheckingStakeHolder && !stakeHolder?.name
                    ? "Field is required"
                    : " "
                }
              />
            </Grid>

            <Grid item sm={12} md={6} lg={3} xl={3}>
              <InputProfile
                label="Role *"
                placeholder="Write role"
                value={stakeHolder?.role}
                onChange={(e) => handleStakeHolderChange("role", e.target.value)}
                error={isCheckingStakeHolder && !stakeHolder?.role}
                helperText={
                  isCheckingStakeHolder && !stakeHolder?.role
                    ? "Field is required"
                    : " "
                }
              />
            </Grid>

            <Grid item sm={12} md={5} lg={3} xl={3}>
              <InputProfile
                type="email"
                label="Email *"
                placeholder="email.example@domain.com"
                value={stakeHolder?.email}
                onChange={(e) => handleStakeHolderChange("email", e.target.value)}
                error={isCheckingStakeHolder && !isEmailValid(stakeHolder?.email)}
                helperText={
                  isCheckingStakeHolder && !isEmailValid(stakeHolder?.email)
                    ? "Invalid email"
                    : " "
                }
              />
            </Grid>

            <Grid item sm={12} md={1} lg={1} xl={1}>
              <Box sx={{ mt: "2rem" }}>
                <FaIconButton
                  onClick={handleAddStakeHolder}
                  icon={faFloppyDisk}
                />
              </Box>
            </Grid>
          </Grid>
        ) }

        <Grid item xs={12}>
          <StakeHoldersTable
            stakeHolders={client?.keyStakeHolders}
            onDelete={handleDeleteStakeHolder}
          />
        </Grid>

        {/* <Grid item sm={12}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{color:'#707070'}}>Upload SOW</Typography>
          </Grid>
          <InputUploadFile
            isUsingFile
            onChange={() => {}}
            fileName={client?.sow?.name}
            onFileChange={(file) => setSowFile(file)}
          />
        </Grid> */}

        <Grid item sm={12}>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => navigate("/clients")}
            fullWidth
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            size="large"
            onClick={onSubmitForm}
            fullWidth
          >
            {isCreate ? "Save" : "Update"}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withSidebar(ClientForm, {
  showProfileBar: true,
  searchComponent: (
    <Link className="class-back-button" href={ROUTES.CLIENTS}>
      <Button
        variant="outlined"
        color="info"
        onClick={() => {}}
        sx={{ marginRight: 3, border: "none" }}
        endIcon={<ChevronLeftIcon />}
      >
        Back
      </Button>
    </Link>
  ),
})
