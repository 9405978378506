enum ROUTES {
  HOME = "/",
  LOGIN = "/login",
  SKILLS = "/skills",
  ROLES = "/roles",
  ADMIN_TIME_OFF = "/admin-time-off",
  EMPLOYEES = "/employees",
  PROFILE = "/profile",
  MY_PROFILE = "/my-profile",
  EMPLOYEE_PROFILE = "/employee-profile",
  EMPLOYEE_PROFILE_EDIT = "/employee-profile/edit",
  CLIENTS = "/clients",
  CLIENTS_CREATE = "/clients/create",
  CLIENTS_UPDATE = "/clients/update",
  PROJECTS = "/projects",
  PROJECTS_CREATE = "/projects/create",
  PROJECTS_UPDATE = "/projects/update",
  ADMINS = "/admins",
}

export default ROUTES
