import { Routes, Route, Navigate } from "react-router-dom"

import { EmployeeContext } from "./Hooks/Employee-context"
import useEmployees from "./Hooks/use-employees"
import Employees from "./Employees"
import EmployeDetails from "./EmployeDetails"

import MyProfile from "src/pages/MyProfile"
import ProtectedComponent from "src/components/atoms/ProtectedComponent"

const EmployeesContainer = () => {
  const emplooyeeFuncs = useEmployees()
  return (
    <EmployeeContext.Provider value={emplooyeeFuncs}>
      <Routes>
        <Route index element={<Employees />} />
        <Route
          path="/:idEmployee"
          element={
            <ProtectedComponent
              requiredPermissions={["user:read"]}
              fallback={<Navigate to="/" />}
            >
              <EmployeDetails />
            </ProtectedComponent>
          }
        />
        <Route
          path="/:idEmployee/edit"
          element={
            <ProtectedComponent
              requiredPermissions={["user:update"]}
              fallback={<Navigate to="/" />}
            >
              <MyProfile />
            </ProtectedComponent>
          }
        />
      </Routes>
    </EmployeeContext.Provider>
  )
}

export default EmployeesContainer
