import { Control, Controller } from 'react-hook-form'
import ProfilePicture from '../ProfilePicture'

interface Props {
  name: string
  control: Control
  required?: boolean
}

const FormProfilePicture = ({ name, control, required }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => <ProfilePicture {...field} />}
    />
  )
}

export default FormProfilePicture
