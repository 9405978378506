import React, { useState, useEffect, useCallback } from "react"
import { useNavigate, useParams, useLocation, Link } from "react-router-dom"
import { NotificationManager } from "react-notifications"
import { Grid, Typography, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import InputProfile from "src/components/atoms/InputProfile"
import InputUploadFile from "src/components/atoms/InputUploadFile"
import SelectProfile from "src/components/atoms/SelectProfile"
import { IUserAutoComplete } from "src/components/atoms/UsersAutoComplete"
import DatePicker from "src/components/atoms/DatePicker"
import UsersForProjectTable from "src/components/moleculas/UsersForProjectTable"
import withSidebar from "src/components/organisms/Sidebar"
import SeatsForProject from "src/components/organisms/SeatsForProject"
import { iUserWithId } from "src/interfaces/user.interfaces"
import { IClient } from "src/interfaces/client.interfaces"
import { RoleWithId } from "src/interfaces/roles.interfaces"

import {
  IUserForProjectShow,
  IProjectModel,
  Status,
  StatusEnum,
} from "src/interfaces/project.interface"
import ClientsService from "src/services/clients/clients.service"
import ProjectsService from "src/services/projects/projects.service"
import userService from "src/services/users/user.service"
import dateHelper from "src/helpers/date/DateHelper"
import ROUTES from "src/types/Routes"
import { initialUser, initialProject } from "./data"
import { mapProjectToDB, mapProjectToForm } from "./mappers"
import { MultipleSelectCheckmarks } from "src/components/atoms/MultipleSelectCheckmarks"
import { SelectChangeEvent } from "@mui/material/Select"
import useSkills from "src/pages/Skill/use-skills"
import "./ProjectForm.scss"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { getRoles } from 'src/services/roles/roles.service'
import {useRecoilValue} from "recoil";
import authState from "../../../recoil/auth";

const ProjectForm: React.FC = () => {
  const currentUser = useRecoilValue(authState.user);
  const { printedSkills } = useSkills()
  const newSkillsList = printedSkills?.map((data) => data.tech)
  const [roles, setRoles] = useState<RoleWithId[]>([])

  const navigate = useNavigate()
  const { id: projectId } = useParams()
  const location = useLocation()

  useEffect(() => {
    getRoles().then(setRoles)
  }, [])

  const isCreate = location.pathname.includes("create")

  const status: Status[] = ["Active", "Completed", "On Hold"]

  const roleOptions = roles!.map((role) => ({
    value: role.name,
    label: role.name,
  }))

  const [loading, setLoading] = useState(false)

  const [project, setProject] = useState<IProjectModel>(initialProject)
  const [seats, setSeats] = useState<IUserForProjectShow[]>([])
  const [skills, setSkills] = useState<any>([])

  const [documentFile, setDocumentFile] = useState<File | undefined>(undefined)

  const [user, setUser] = useState<IUserForProjectShow>(initialUser)
  // Mostly for clearing the autocomplete component when adding a User
  const [selectedUser, setSelectedUser] =
    useState<IUserAutoComplete | null>(null)

  // Fetched from DB
  const [employees, setEmployees] = useState<iUserWithId[]>([])
  const [clients, setClients] = useState<IClient[]>([])

  const [skillsDropdown, setSkillsDropdown] = React.useState<string[]>([])

  const handleChange = (event: SelectChangeEvent<typeof skillsDropdown>) => {
    const {
      target: { value },
    } = event
    setSkillsDropdown(typeof value === "string" ? value.split(",") : value)
    const newSkillAdded =
      typeof value === "string"
        ? value.split(",")
        : printedSkills?.filter((skill: any) =>
          value.find((data) => skill.tech === data)
        )
    if (newSkillAdded !== null) {
      setSkills(newSkillAdded)
    }
  }

  // Validations
  const [isCheckingUser, setIsCheckingUser] = useState<boolean>(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)

  const handleProjectChange = (
    key: keyof Omit<IProjectModel, "document" | "employees">,
    value: string
  ) => {
    setProject((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const handleFOChange = (value: string) => {
    handleProjectChange("foId", value)
    const employee = employees.find((employee) => employee.id === value)
    const foName = employee?.fullName ? employee.fullName : ""
    handleProjectChange("foName", foName)
  }

  const handleTLChange = (value: string) => {
    handleProjectChange("tlId", value)
    const employee = employees.find((employee) => employee.id === value)
    const tlName = employee?.fullName ? employee.fullName : ""
    handleProjectChange("tlName", tlName)
  }

  const handleUserChange = (
    key: keyof Omit<IUserForProjectShow, "id" | "fullName" | "photoURL">,
    value: string | number
  ) => {
    let selectedRole: RoleWithId | undefined
    if (key === "role") {
      selectedRole = roles!.find((role) => role.name === value)
    }

    if (key === "allocationPercentage" && (value < 0 || value > 100)) return

    setUser((prev) => ({
      ...prev,
      [key]:
        key === "role" && selectedRole !== undefined ? selectedRole : value,
    }))
  }

  const handleUserAutocompleteChange = (user: IUserAutoComplete | null) => {
    setUser((prev) => ({
      ...prev,
      id: user?.id || "",
      fullName: user?.fullName || "",
      photoURL: user?.photoURL || "",
    }))
    setSelectedUser(user)
  }

  const handleAddUser = () => {
    setIsCheckingUser(true)

    if (isValidUser) {
      setSeats([...seats, user])
      setUser(initialUser)
      // Clear the autocomplete
      setSelectedUser(null)
      setIsCheckingUser(false)
    }
  }

  const handleDeleteUser = (id: string) => {
    setSeats([...seats].filter((x) => x.id !== id))
  }

  const onSubmitForm = async () => {
    setIsFormSubmitted(true)

    if (isValidProject) {
      setLoading(true)
      const result = isCreate
        ? await ProjectsService.createProject(
          mapProjectToDB(project, seats, skills),
          documentFile
        )
        : await ProjectsService.updateProject(
          projectId!,
          mapProjectToDB(project, seats, skills),
          documentFile
        )
      setLoading(false)

      if (result.statusCode === 200) {
        NotificationManager.success(result.message, "Success", 3000)
        // navigate(ROUTES.PROJECTS)
        window.location.href = ROUTES.PROJECTS;
      } else {
        NotificationManager.error(result.message, "Error", 3000)
      }
    } else {
      NotificationManager.warning(
        "Please fill the fields correctly",
        "Error",
        3000
      )
    }
  }

  const getEmployees = async () => {
    setLoading(true)
    const users = await userService.getUsers()
    setLoading(false)

    setEmployees(users)
  }

  const getClients = async () => {
    setLoading(true)
    const result = await ClientsService.getClients()
    setLoading(false)

    if ("error" in result) {
      NotificationManager.error(result.error, "Error", 3000)
    } else {
      setClients(result)
    }
  }

  const getProject = useCallback(async () => {
    setLoading(true)
    const result = await ProjectsService.getProject(projectId!)
    setLoading(false)
    if ("error" in result) {
      NotificationManager.error(result.error, "Error", 3000)
    } else {
      let changeResultSkillsFormat = result.skillList.map((data) => data.tech)
      setProject(mapProjectToForm(result, result.skillList))
      setSeats(result.employees)
      setSkillsDropdown(changeResultSkillsFormat)
    }
  }, [projectId])

  useEffect(() => {
    if (!isCreate) {
      getProject()
    }
    getEmployees()
    getClients()
  }, [getProject, isCreate])

  useEffect(() => {
    const client = clients.find((client) => client.id === project.clientId)
    const clientCsmId = client?.csmId ? client.csmId : ""
    handleProjectChange("csmId", clientCsmId)
    handleProjectChange("csmName", client?.csmName || "")
    handleProjectChange("clientName", client?.name || "")
  }, [project.clientId, clients])

  useEffect(() => {
    if (project.status !== StatusEnum.ONHOLD)
      handleProjectChange("onHoldDescription", "")
  }, [project.status])

  // Validations
  const isValidOnHoldDescription =
    project.status !== StatusEnum.ONHOLD ||
    (project.status === StatusEnum.ONHOLD && project.onHoldDescription)
  const isValidProject =
    !!project.name &&
    !!project.clientId &&
    !!project.csmId &&
    !!project.foId &&
    isValidOnHoldDescription
  const isValidUser = !!user.role.name && !!user.status

  return (
    <Grid container className="class-ProjectForm">
      <Grid container spacing={3} alignItems="center" pb={4}>
        <Grid item xs={12}>
          <Typography variant="overline">
            <Link to={ROUTES.PROJECTS}>Projects</Link>
            {isCreate ? " > Create a Project" : " > Update a Project"}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            Project Info
          </Typography>
        </Grid>

        <Grid item sm={12} md={4}>
          <InputProfile
            label="Name *"
            placeholder="project's Name"
            value={project.name}
            disabled={!['csm', 'admin'].includes(currentUser.companyRole?.name?.toLowerCase())}
            onChange={(e) => handleProjectChange("name", e.target.value)}
            error={isFormSubmitted && !project.name}
            helperText={
              isFormSubmitted && !project.name ? "Field is required" : ""
            }
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <SelectProfile
            label="Client Name *"
            placeholder="Choose client's name"
            disabled={!['csm', 'admin'].includes(currentUser.companyRole?.name?.toLowerCase())}
            value={project.clientId}
            onChange={(value) => handleProjectChange("clientId", value)}
            options={clients!.map((client) => ({
              value: client.id,
              label: client.name,
            }))}
            error={isFormSubmitted && !project.clientId}
            helperText={
              isFormSubmitted && !project.clientId ? "Field is required" : ""
            }
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <MultipleSelectCheckmarks
            arrayData={newSkillsList}
            itemHeight={48}
            itemPadingTop={8}
            value={skillsDropdown}
            onChange={handleChange}
            disabled={!['csm', 'admin', 'ba/pm'].includes(currentUser.companyRole?.name?.toLowerCase())}
            label="Tech Stack"
            placeholder="Select a tech stack"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            Time Estimation
          </Typography>
        </Grid>

        {!isCreate && (
          <Grid item sm={12} md={4} mb={3}>
            <SelectProfile
              label="Status"
              placeholder="Choose a status"
              disabled={!['csm', 'admin', 'ba/pm'].includes(currentUser.companyRole?.name?.toLowerCase())}
              value={project.status}
              onChange={(value) => handleProjectChange("status", value)}
              options={status!.map((status) => ({
                value: status,
                label: status,
              }))}
              disableNone
            />
          </Grid>
        )}

        <Grid item sm={12} md={4} mb={3}>
          <DatePicker
            value={project?.dayStart ? new Date(project.dayStart) : null}
            label="Start Date"
            disabled={!['csm', 'admin', 'ba/pm'].includes(currentUser.companyRole?.name?.toLowerCase())}
            onChange={(e) =>
              handleProjectChange(
                "dayStart",
                e !== null ? dateHelper.toString(e) : ""
              )
            }
          />
        </Grid>
        <Grid item sm={12} md={4} mb={3}>
          <DatePicker
            value={project?.dayFinish ? new Date(project.dayFinish) : null}
            label="End Date"
            disabled={!['csm', 'admin', 'ba/pm'].includes(currentUser.companyRole?.name?.toLowerCase())}
            onChange={(e) =>
              handleProjectChange(
                "dayFinish",
                e !== null ? dateHelper.toString(e) : ""
              )
            }
          />
        </Grid>

        {project.status === StatusEnum.ONHOLD && (
          <Grid item sm={12} md={12}>
            <InputProfile
              label="On hold description *"
              placeholder="Brief description of the on hold status"
              value={project.onHoldDescription}
              onChange={(e) =>
                handleProjectChange("onHoldDescription", e.target.value)
              }
              rows={4}
              error={isFormSubmitted && !isValidOnHoldDescription}
              helperText={
                isFormSubmitted && !isValidOnHoldDescription
                  ? "Field is required"
                  : ""
              }
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            Responsibles
          </Typography>
        </Grid>
        <Grid item sm={12} md={4}>
          <SelectProfile
            label="FO *"
            disabled={!['csm', 'admin'].includes(currentUser.companyRole?.name?.toLowerCase())}
            placeholder="Choose a member"
            value={project.foId}
            onChange={(value) => handleFOChange(value)}
            options={employees.reduce((foEmployees: any, employee) => {
              if (employee.companyRole?.name === "BA/PM")
                foEmployees.push({
                  value: employee.id,
                  label: employee.fullName,
                })
              return foEmployees
            }, [])}
            error={isFormSubmitted && !project.foId}
            helperText={
              isFormSubmitted && !project.foId ? "Field is required" : ""
            }
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <SelectProfile
            label="TL"
            placeholder="Choose a member"
            value={project.tlId}
            onChange={(value) => handleTLChange(value)}
            options={employees.reduce((tlEmployees: any, employee) => {
              if (employee.companyRole?.name === "TL")
                tlEmployees.push({
                  value: employee.id,
                  label: employee.fullName,
                })
              return tlEmployees
            }, [])}
            // add conditional when it colum be added on backend
            error={false}
            // add conditional text when it colum be added on backend
            helperText={null}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <SelectProfile
            disabled
            label="CSM *"
            placeholder="Choose a member"
            value={project.csmId}
            onChange={(value) => handleProjectChange("csmId", value)}
            options={employees!.map((employee) => ({
              value: employee.id,
              label: employee.fullName,
            }))}
            error={isFormSubmitted && !project.csmId}
            helperText={
              isFormSubmitted && !project.csmId ? "Field is required" : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            Project Description
          </Typography>
        </Grid>
        <Grid item sm={12} md={12}>
          <InputProfile
            label=""
            disabled={!['csm', 'admin', 'ba/pm'].includes(currentUser.companyRole?.name?.toLowerCase())}
            placeholder="Brief description of the project"
            value={project.description}
            onChange={(e) => handleProjectChange("description", e.target.value)}
            rows={4}
          />
        </Grid>

        { ['csm', 'admin', 'ba/pm'].includes(currentUser.companyRole?.name?.toLowerCase()) && (
          <SeatsForProject
            user={user}
            handleUserChange={handleUserChange}
            isCheckingUser={isCheckingUser}
            selectedUser={selectedUser}
            handleUserAutocompleteChange={handleUserAutocompleteChange}
            handleAddUser={handleAddUser}
            roleOptions={roleOptions}
            employees={employees}
          />
        ) }

        <Grid container item xs={12}>
          <UsersForProjectTable users={seats} onDelete={handleDeleteUser} />
        </Grid>

        { ['csm', 'admin'].includes(currentUser.companyRole?.name?.toLowerCase()) && (
          <Grid item sm={12}>
            <Typography color="primary" variant="h6">
              SOW
            </Typography>
            <InputUploadFile
              isUsingFile
              onChange={() => { }}
              fileName={project.document.name}
              onFileChange={(file) => setDocumentFile(file)}
            />
          </Grid>
        ) }

        <Grid item sm={12}>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => navigate("/projects")}
            fullWidth
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            size="large"
            onClick={onSubmitForm}
            fullWidth
          >
            {isCreate ? "Save" : "Update"}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withSidebar(ProjectForm, {
  showProfileBar: true,
  searchComponent: (
    <Link to={ROUTES.PROJECTS} className="class-back-button">
      <Button
        variant="outlined"
        color="info"
        onClick={() => { }}
        sx={{ marginRight: 3, border: "none" }}
        endIcon={<ChevronLeftIcon />}
      >
        Back
      </Button>
    </Link>
  ),
})
