import { RoleWithId } from "src/interfaces/roles.interfaces"
import { IStorageFile } from "src/interfaces/file.interfaces"
import { TechWithId } from "./skills.interfaces"
import { Seniority } from "./user.interfaces"

// Actions for Projects when updating a Client
type Action = "create" | "update" | "delete" | "none"

// Status for projects to keep track of active projects
export type Status = "Active" | "Completed" | "On Hold"

// Enum for projects status
export const StatusEnum = {
  ACTIVE: "Active",
  COMPLETED: "Completed",
  ONHOLD: "On Hold",
}

// Save in the DB
export interface IUserForProjectModel {
  id: string
  role: RoleWithId
  status: "dedicated" | "on-demand"
  allocationPercentage: number
  dayStart: string
  dayFinish: string
  fullName: string
  photoURL: string
  seniority: Seniority | ""
}

// Show in the frontend with populated data
export interface IUserForProjectShow extends IUserForProjectModel {
  fullName: string
  photoURL: string
}

interface IProjectBase {
  name: string
  description: string
  clientId: string
  csmId: string
  repositoryLink: string
  dayStart: string
  dayFinish: string
  document: IStorageFile
  foId: string
  foName: string
  tlId: string
  tlName: string
  status: Status
  onHoldDescription: string
  csmName: string
  clientName: string
}

// Exclusive for use in forms
export interface IProjectForm extends IProjectBase {
  employees: IUserForProjectShow[]
  skillList: TechWithId[]
  // skillList: skillList[]
}

// Save in the DB
export interface IProjectModel extends IProjectBase {
  employees: IUserForProjectModel[]
  skillList: TechWithId[]
  // skillList: skillList[]
}

// Show in the frontend with populated data
export interface IProjectShow extends IProjectBase {
  id: string
  clientName: string
  csmName: string
  employees: IUserForProjectShow[]
  skillList: TechWithId[]
}

// Show in the frontend with populated data with Actions for Projects when updating a Client
export interface IProjectWithAction extends IProjectShow {
  action: Action
}
//project's filter
export interface iFilters {
  name: string
  foName: string
  tlName: string
  csmName: string
  clientName: string
  status: Status
}
