import { atom } from "recoil";

const isLoadingApp = atom({
    key: 'isLoadingApp',
    default: false
});

const appState = {
    isLoadingApp,
}

export default appState;