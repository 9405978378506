import React from 'react';

export const IconCardMode = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26.8'
      height='23.45'
      viewBox='0 0 26.8 23.45'
    >
      <path
        id='table-cells-large-solid'
        d='M23.45,32a3.351,3.351,0,0,1,3.35,3.35V52.1a3.353,3.353,0,0,1-3.35,3.35H3.35A3.351,3.351,0,0,1,0,52.1V35.35A3.35,3.35,0,0,1,3.35,32Zm0,3.35H15.075v6.7H23.45Zm0,10.05H15.075v6.7H23.45ZM11.725,42.05v-6.7H3.35v6.7ZM3.35,52.1h8.375V45.4H3.35Z'
        transform='translate(0 -32)'
      />
    </svg>
  );
};
