import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Countries } from './locate/Countries';
import './InputAdress.css';

interface InputAdressProps {
  className?: string;
  id?: string;
  backgroundColor?: string;
  label?: string;
  size?: 'small' | 'medium';
}

export const InputAdress = ({
  className,
  id = 'country-select-demo',
  backgroundColor,
  label,
  size
}: InputAdressProps) => {
  const [value, setValue] = useState<{
    code: string;
    label: string;
    phone: string;
  } | null>(Countries[0]);
  const [inputValue, setInputValue] = useState('');

  return (
    <div className={className}>
      <Autocomplete
        value={value}
        onChange={(
          event: any,
          newValue: { code: string; label: string; phone: string } | null
        ) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id={id}
        options={Countries}
        style={{ backgroundColor }}
        className={['class-InputAdress ', ' class-all-border '].join(' ')}
        disablePortal
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box
            component='li'
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading='lazy'
              width='20'
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=''
            />
            {option.label} ({option.code}) +{option.phone}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password'
            }}
          />
        )}
        size={
          size === 'small' ? 'small' : size === 'medium' ? 'medium' : undefined
        }
      />
    </div>
  );
};
