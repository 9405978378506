import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  styled,
  Chip,
} from '@mui/material'
import Pagination from 'src/components/moleculas/Pagination'
import { IProjectShow } from 'src/interfaces/project.interface'
import ROUTES from 'src/types/Routes'
import { EyeIcon, PencilIcon } from '@heroicons/react/outline'

import ProtectedComponent from "src/components/atoms/ProtectedComponent"

interface IProjectsTableProps {
  projects: IProjectShow[]
  minHeight?: number
}
// note dummy
const ProjectsTable: React.FC<IProjectsTableProps> = ({
  projects
}) => {
  const navigate = useNavigate()

  const [currentPageProjects, setCurrentPageProjects] =
    useState<IProjectShow[] | undefined>(undefined)

  const showingClients = projects ? projects : []

  const ProjectsTableHead = styled(TableRow)(({ theme }) => ({
    '&': {
      boxShadow: '0px 3px 6px #1C212426',
      opacity: 1,
      borderBottomLeftRadius: '15px',
      borderBottomRightRadius: '15px',
    },
    '& th': {
      borderBottom: '0',
    },
  }))

  const ProjectsTableCell = styled(TableCell)(({ theme }) => ({
    '&': {
      border: 0,
    },
  }))

  const widthStyle = {
    width: '15%',
  }

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          <TableHead>
            <ProjectsTableHead>
              <TableCell sx={{ ...widthStyle }}>Project Name</TableCell>
              <TableCell sx={{ ...widthStyle }}>Client Name</TableCell>
              <TableCell sx={{ ...widthStyle }}>Status</TableCell>
              <TableCell sx={{ ...widthStyle }}>Tech Stack</TableCell>
              <TableCell sx={{ ...widthStyle }}>FO</TableCell>
              <TableCell sx={{ ...widthStyle }}>TL</TableCell>
              <TableCell sx={{ ...widthStyle }}>CSM</TableCell>
              <TableCell></TableCell>
            </ProjectsTableHead>
          </TableHead>
          <TableBody>
            {currentPageProjects?.map((row) => (
              <TableRow sx={{ height: '70px' }} key={row.id}>
                <ProjectsTableCell sx={{ ...widthStyle }}>
                  {row.name || '--'}
                </ProjectsTableCell>
                <ProjectsTableCell sx={{ ...widthStyle }}>
                  {row.clientName || '--'}
                </ProjectsTableCell>
                <ProjectsTableCell sx={{ ...widthStyle }}>
                  {row.status || "--"}
                  {/* <Chip
                    label={row.status || "--"}
                    color={row.status === "Active" ? "info" : "error"}
                  /> */}
                </ProjectsTableCell>
                <ProjectsTableCell sx={{ ...widthStyle }}>
                  {row.skillList?.map(data => data.tech).join(', ') || '--'}
                </ProjectsTableCell>
                <ProjectsTableCell sx={{ ...widthStyle }}>
                  {row.foName || '--'}
                </ProjectsTableCell>
                <ProjectsTableCell sx={{ ...widthStyle }}>
                  {row.tlName || '--'}
                </ProjectsTableCell>
                <ProjectsTableCell>
                  {row.csmName || '--'}
                </ProjectsTableCell>

                {/* <EmployeesTableCell>
                  <Box display="flex">
                    <AvatarGroup total={row.employees.length}>
                      {row.employees[0] && (
                        <Avatar
                          alt={row.employees[0].fullName}
                          src={row.employees[0].photoURL}
                        />
                      )}
                      {row.employees[1] && (
                        <Avatar
                          alt={row.employees[1].fullName}
                          src={row.employees[1].photoURL}
                        />
                      )}
                    </AvatarGroup>
                  </Box>
                </EmployeesTableCell> */}
                <td>
                {/* <ProjectsTableCell> */}
                  <Box
                    display="flex"
                    sx={{ color: 'primary.blue', display: 'flex', gap: '20px' }}
                  >
                    <EyeIcon
                      style={{ width: '1.8em', cursor: 'pointer' }}
                      onClick={() => {
                        navigate(`${ROUTES.PROJECTS}/${row.id}`)
                      }}
                    />
                    <ProtectedComponent requiredPermissions={["project:update"]}>
                      <PencilIcon
                        style={{ width: '1.65em', cursor: 'pointer' }}
                        onClick={() => {
                          navigate(`${ROUTES.PROJECTS}/${row.id}/edit`)
                        }}
                      />
                    </ProtectedComponent>
                  </Box>
                {/* </ProjectsTableCell> */}
                </td>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* OLD TABLE */}
      {/* <TableContainer
        component={Paper}
        sx={{
          minHeight: minHeight ? minHeight : 150,
          maxHeight: 400,
          overflowY: 'scroll',
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Project Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Client Name</TableCell>
              <TableCell>Members</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageProjects?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.clientName}</TableCell>
                <TableCell>
                  <Box display="flex">
                    <AvatarGroup total={row.employees.length}>
                      {row.employees[0] && (
                        <Avatar
                          alt={row.employees[0].fullName}
                          src={row.employees[0].photoURL}
                        />
                      )}
                      {row.employees[1] && (
                        <Avatar
                          alt={row.employees[1].fullName}
                          src={row.employees[1].photoURL}
                        />
                      )}
                    </AvatarGroup>
                  </Box>
                </TableCell>
                <TableCell width={1}>
                  <Box display="flex">
                    <IconButton
                      onClick={() => {
                        navigate(`${ROUTES.PROJECTS}/${row.id}`)
                      }}
                    >
                      <VisibilityIcon style={{ width: '1.2em' }} />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        navigate(`${ROUTES.PROJECTS_UPDATE}/${row.id}`)
                      }}
                    >
                      <EditIcon style={{ width: '1.2em' }} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      <Pagination
        onChangeVisibleRecords={setCurrentPageProjects}
        records={showingClients}
      />
    </>
  )
}

export default ProjectsTable
