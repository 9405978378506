import React from "react"
import { ButtonComponent } from "../../atoms/Button/Button"
import { Input } from "../../atoms/input/Input"
// import { LinkComponent } from "../../atoms/Link/LinksComponent"
import { FilterComponent } from "../../organisms/FilterComponent/FilterComponent"
import "./SearchFilterComponent.css"

interface SearchFilterComponentProps {
  /**
   * Default value for Input text atom
   */
  value: string
  /**
   * Default value for className
   */
  className?: string
  /**
   * Function triggered when the value of Input Text atom is changed
   */
  onChange: (value: string) => void
  /**
   * List of Switches components rendered in the Filter molecule
   */
  listSwitches?: object[]
  /**
   * List of Inputs components rendered in the Filter molecule
   */
  listInputs?: object[]
  /**
   * List of InputDropdowns components rendered in the Filter molecule
   */
  listInputDropdown?: object[]
  /**
   * List of InputAddress components rendered in the Filter molecule
   */
  listInputAddress?: object[]
  // fastFilterOptions:Array<{
  //     label:string,
  //     onClick:()=>void
  // }>
  /**
   * Boolean to condition if we only search with or without a filter
   */
  isOnlyBasicSearch?: boolean
  /**
   * Boolean to condition if we only search with or without a filter
   */
  arrayDirectFilters?: any[]
  /**
   * Function to clear filters
   */
  onClearFilters: () => void
}

// const linkProperties = {
//   variant: "subtitle2",
// }

export const SearchFilterComponent = ({
  onChange,
  value,
  className,
  listSwitches,
  listInputs,
  listInputDropdown,
  listInputAddress,
  isOnlyBasicSearch = false,
  // arrayDirectFilters,
  onClearFilters,
}: SearchFilterComponentProps) => {
  return (
    <div
      className={`${
        isOnlyBasicSearch
          ? "class-button-icon-white class-SearchFilterComponent"
          : "class-SearchFilterComponent"
      }  ${className}`}
    >
      <form>
        <Input
          backgroundColor="#EAEEF1"
          border="left"
          label="Write your title here"
          widthMenu
          name="search"
          type="text"
          onHandleChange={onChange}
          value={value}
        />
        {isOnlyBasicSearch ? (
          <ButtonComponent
            backgroundColor="#0083D2"
            borderRadius="right"
            color="#F5F7F8"
            iconBolean
            iconColor="#F5F7F8"
            iconType="IconSearch"
            isIconOnly
            onClick={() => {}}
            variant="outlined"
          />
        ) : (
          <>
            <ButtonComponent
              borderRadius="none"
              iconBolean
              iconType="IconSearch"
              isIconOnly
              variant="outlined"
              disabled={true}
            />
            <FilterComponent
              listSwitches={listSwitches}
              listInputs={listInputs}
              listInputDropdown={listInputDropdown}
              listInputAddress={listInputAddress}
              onClearFilters={onClearFilters}
            />
          </>
        )}
      </form>
      {/* <div>
        {arrayDirectFilters && arrayDirectFilters.length > 0 && (
          <>
          <h5>Use: </h5>
          {arrayDirectFilters.map((element, key)=>(
            <LinkComponent
              key={`${element.leable}-${key}`}
              onClick={element.onClick}
              {...element.linkProperties}
              content={element.name}
            />
          ))}
          </>
        )}
      </div> */}
    </div>
  )
}
