import React from 'react'
import Switch, { SwitchProps } from '@mui/material/Switch'
import Typography from '@mui/material/Typography'

const label = { inputProps: { 'aria-label': 'Switch demo' } }

export interface Props extends SwitchProps {
  defaultChecked?: boolean
  leable?: string
  leable2?: string
}

export const BasicSwitches = ({ leable, leable2, ...props }: Props) => {
  return (
    <div>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {leable}
      </Typography>
      <Switch {...props} {...label} />
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {leable2}
      </Typography>
    </div>
  )
}
