import React, { useMemo, useContext } from 'react'
import { Grid, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import ProjectsTable from 'src/components/moleculas/ProjectsTable'
import Spinner from 'src/components/moleculas/Spinner/Index'
import withSidebar from 'src/components/organisms/Sidebar'
import ROUTES from 'src/types/Routes'
import { SearchFilterComponent } from 'src/stories/components/ui/molecules'
import { ProjectContext } from './project-context'
import ProtectedComponent from "src/components/atoms/ProtectedComponent"
import { IProjectShow } from 'src/interfaces/project.interface'
import authState from '../../../recoil/auth'

import './viewProjects.scss'

const SubSearchFilterComponent = () => {
  const {
    arrayDirectFiltersProject,
    clearFiltersProject,
    listInputDropdownProject,
    filter,
    setFilter
  } = useContext(ProjectContext);

  return <SearchFilterComponent
    className="class-filter-project"
    arrayDirectFilters={arrayDirectFiltersProject}
    listInputAddress={[]}
    listInputDropdown={listInputDropdownProject}
    listSwitches={[]}
    onChange={(e:string) => setFilter({...filter,name:e})}
    onClearFilters={clearFiltersProject}
    value={filter.name}
  />
}

const ViewProjects: React.FC = () => {
  const currentUser = useRecoilValue(authState.user)
  const navigate = useNavigate()
  const {
    projectsFiltered,
    loadingProject
  } = useContext(ProjectContext)

  const projects = useMemo(() => {
    if (currentUser.companyRole?.name === 'Employee') {
      return projectsFiltered
        .filter(
          (p: IProjectShow) => p.employees
            .map(employee => employee.id)
            .includes(currentUser.id)
        )
    }

    return projectsFiltered
  }, [currentUser, projectsFiltered])

  return (
    <Grid container>
      <Grid container spacing={3} alignItems="center" pb={4}>
        <Grid
          container
          item
          spacing={3}
          justifyContent="right"
          alignItems="baseline"
          my={2}
        >
          <ProtectedComponent requiredPermissions={["project:create"]}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => navigate(ROUTES.PROJECTS_CREATE)}
              sx={{ backgroundColor: 'primary.blue', borderRadius: "30px", width: '260px', height: '62px' }}
            >
              Add a Project
            </Button>
          </ProtectedComponent>
        </Grid>

        <Grid item xs={12}>
          { loadingProject ? <Spinner /> : <ProjectsTable projects={ projects } /> }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withSidebar(ViewProjects, {
  showProfileBar: true,
  searchComponent: <SubSearchFilterComponent />
})
