import { Grid } from '@mui/material'
import React from 'react'
import LoginBanner from 'src/components/atoms/LoginBanner'
import LoginBox from 'src/components/organisms/LoginBox'

const Login = () => {
  return (
    <Grid container>
      <Grid
        item
        xs={6}
        style={{
          backgroundColor: '#F5F7F8',
          marginTop: '-9px',
          marginBottom: '-9px',
          marginLeft: '-9px',
        }}
      >
        <LoginBanner />
      </Grid>
      <Grid item xs={6}>
        <LoginBox />
      </Grid>
    </Grid>
  )
}

export default Login
