import { PencilIcon } from "@heroicons/react/outline"
import React, { FC, useContext, useEffect, useState } from "react"
import { NotificationManager } from "react-notifications"
import Spinner from "src/components/moleculas/Spinner/Index"
import userService from "src/services/users/user.service"
import { iUserWithId } from "src/interfaces/user.interfaces"
import TabsEditUser from "src/components/moleculas/NewFormEditUser"
import { Button } from "@mui/material"
import CRUDModal from "src/components/moleculas/CRUDModal"
import { useForm, useFormState } from "react-hook-form"
import ObjectHelper from "src/helpers/object/ObjectHelper"
import { useRecoilState } from "recoil"
import authState from "src/recoil/auth"
import { getRoles } from 'src/services/roles/roles.service'
import { RoleWithId } from 'src/interfaces/roles.interfaces'

// type Inputs = {
//   fullName: string
//   email: string
//   location: Location
//   phoneNumber: string
//   birthDate: Date
//   photoURL: string
//   inBench: boolean
//   bio: string
//   inBenchSince: string
//   isCurrentEmployee: boolean
//   companyRole: Role
// }

interface iModalEditUser {
  userEdit: iUserWithId
  open: boolean
  setOpen: (value: boolean) => void
  refresh?: () => void
}

const ModalEditUser: FC<iModalEditUser> = ({
  userEdit,
  open,
  setOpen,
  refresh,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [user, setUser] = useRecoilState(authState.user)
  const [editedUser, setEditedUser] = useState<iUserWithId>(userEdit)
  const [roles, setRoles] = useState<RoleWithId[]>([])

  useEffect(() => {
    getRoles().then(setRoles)
  }, [])

  const methods = useForm<iUserWithId>({
    defaultValues: userEdit,
    mode: "onChange",
  })
  const { handleSubmit, reset, setValue, control, trigger } = methods
  const { isValid, dirtyFields } = useFormState({ control })
  const isDirty = Object.keys(dirtyFields).length > 0

  useEffect(() => {
    trigger()
  }, [open, trigger])

  useEffect(() => {
    const fixedEditedUser = {
      ...userEdit,
      companyRole: userEdit.companyRole ? userEdit.companyRole : roles![3],
    }

    setEditedUser(fixedEditedUser)
  }, [user, userEdit, roles])

  useEffect(() => {
    Object.entries(editedUser).forEach(([key, value]) => {
      //@ts-ignore
      setValue(key, value || "")
    })
  }, [editedUser, setValue])

  const handleMakeUserAdmin = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    const adminRole = 1
    await userService.changeUsersRole(userEdit.email, adminRole)
    NotificationManager.success(
      "User " + userEdit.fullName + " is now an admin"
    )
    setLoading(false)
  }

  const onSave = async (editedUser: iUserWithId) => {
    if (!editedUser.fullName) {
      return
    }

    setLoading(true)

    //@ts-ignore
    const updatedUser: Partial<iUserWithId> = {
      ...editedUser,
    }

    delete updatedUser.creationTime
    delete updatedUser.role
    delete updatedUser.firebaseId
    delete updatedUser.lastUpdated
    // delete updatedUser.pendingSkills
    delete updatedUser.email

    const result = await userService.updateUser(userEdit.id, updatedUser)
    if (result.statusCode >= 400) {
      NotificationManager.error(result.message, "Error", 1500)
      return
    }

    if (refresh) {
      NotificationManager.success(
        "User has been updated successfully",
        "Success",
        750
      )
      if (!!updatedUser.id && !!user && userEdit.id === user?.id) {
        setUser({ ...user, ...updatedUser })
      }
      refresh()
    }

    setLoading(false)
    setOpen(false)
  }

  const onSubmit = async (data: iUserWithId) => {
    const isValid = await trigger()

    if (!isValid) {
      NotificationManager.error(
        "Please fill all required fields",
        "Error",
        1500
      )
      return
    }

    const userToUpdate = {
      ...ObjectHelper.removeEmptyProperties(data),
      companyRole: roles?.find((role) => data.companyRole!.name === role.name),
    } as iUserWithId

    await onSave(userToUpdate)
  }

  const handleClose = (close: boolean) => {
    reset()
    setOpen(close)
  }

  return (
    <>
      <CRUDModal
        open={open}
        setOpen={handleClose}
        title={"Edit user " + userEdit.fullName}
        onSubmit={handleSubmit(onSubmit)}
        buttonTop={
          user &&
          user?.role < 1 && (
            <Button
              variant="contained"
              sx={{ marginLeft: "auto" }}
              onClick={(e) => handleMakeUserAdmin(e)}
            >
              Make admin
            </Button>
          )
        }
        content={<>{open && <TabsEditUser isEdit={true} editMethods />}</>}
        buttons={
          !loading ? (
            <>
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{ marginLeft: 1 }}
                disabled={!isDirty || !isValid}
              >
                Save
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  handleClose(false)
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <div className="w-full flex justify-center">
              <Spinner />
            </div>
          )
        }
        icon={
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
            <PencilIcon className="h-6 w-6 text-grey-600" aria-hidden="true" />
          </div>
        }
      />
    </>
  )
}

export default ModalEditUser
