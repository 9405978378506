import { Box, Button, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import CRUDModal from 'src/components/moleculas/CRUDModal'
import Spinner from 'src/components/moleculas/Spinner/Index'
import RoleList from 'src/components/organisms/RolesList'
import withSidebar from 'src/components/organisms/Sidebar'
import { RolesContext } from 'src/contexts/roles.context'
import { RoleWithId } from 'src/interfaces/roles.interfaces'
import { getRoles, addRole } from 'src/services/roles/roles.service'
import ProtectedComponent from 'src/components/atoms/ProtectedComponent'
import { Input } from 'src/stories/components/ui/atoms'

const Roles = () => {
  const [printedRoles, setPrintedRoles] = useState<RoleWithId[]>()
  const [newRole, setNewRole] = useState<string>('')
  const [createModal, setCreateModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { roles, setRoles } = useContext(RolesContext)

  const getAllRoles = async () => {
    const newRoles = await getRoles()
    setRoles(newRoles)
    setPrintedRoles(newRoles)
  }

  const addNewRole = async () => {
    setLoading(true)
    const result = await addRole(newRole)
    if (result.response) {
      NotificationManager.success('Role added successfully', 'Success', 750)
      console.log(result.response)
      getAllRoles()
      setCreateModal(false)
    }
    setLoading(false)
    setNewRole('')
  }

  useEffect(() => {
    setPrintedRoles(roles)
    getAllRoles()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          width: "100%",
          marginTop: "20px",
          marginBottom: "20px"
        }}
      >
        <Typography sx={{ lineHeight: "24px", fontWeight: 500 }}>Roles</Typography>
        <ProtectedComponent requiredPermissions={["role:create"]}>
          <Button
            onClick={() => setCreateModal(true)}
            type="button"
            variant="contained"
            sx={{ backgroundColor: 'primary.blue', borderRadius: "30px", width: '200px' }}
          >
            Add Role
          </Button>
        </ProtectedComponent>
      </Box>
      <RoleList roles={printedRoles} refreshRoles={getAllRoles} />
      <CRUDModal
        title={"Add role"}
        open={createModal}
        setOpen={setCreateModal}
        content={<div><Input
          backgroundColor="#EAEEF1"
          border="all"
          label="Role name"
          widthMenu
          type="text"
          value={newRole}
          onHandleChange={setNewRole}
          customClass="flex-auto" /></div>}
        buttons={!loading ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCreateModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                if (newRole.length > 1) {
                  addNewRole()
                }
              }}
            >
              Add
            </Button>
          </>
        ) : (
          <Box
            display={'flex'}
            justifyContent={'center'}
            sx={{ width: '100%' }}
          >
            <Spinner />
          </Box>
        )} />
    </>
  )
}

export default withSidebar(Roles, { showProfileBar: true })
