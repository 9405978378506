import * as React from 'react';
import Link from '@mui/material/Link';
import './Link.css';

export interface LinkProps {
  /**
   * Property can be used to set the onClick option
   */
  onClick?:()=>void;
    /**
   * Property can be used to set the underline behavior it can be [none | hover | always]
   */
  underline: any;
  /**
   * Boolean value for activate or desactivate the Link component
   */
  disabled?: boolean;
  /**
   * Color for the text of the Link component
   */
  color?: string;
  /**
   * Color for the background of the Link component
   */
  backgroundColor?: string;
  /**
   * Url for the link component
   */
  href?: string;
  /**
   * Material-UI Typografy used for the text of the Link component
   */
  variant?: any;
  /**
   * Keyword for specify where open the reference url
   */
  target?: string;
  /**
   * Content to be renderized within of the Link Component
   */
  content: JSX.Element;
}

export const LinkComponent = (props: LinkProps) => {
  const {
    underline,
    disabled,
    href,
    content,
    color,
    variant,
    target,
    backgroundColor,
    onClick
  } = props;

  return (
    <div className='root'>
      <Link
        onClick={onClick}
        className={disabled ? 'disabled' : ''}
        color={color}
        href={href}
        underline={underline}
        variant={variant}
        target={target}
        sx={{
          bgcolor: backgroundColor
        }}
      >
        {content}
      </Link>
    </div>
  );
};

LinkComponent.defaultProps = {
  underline: 'always',
  disabled: false,
  href: '#',
  variant: 'inherit',
  target: '_self',
  content: <>Link component data</>,
  backgroundColor: '#FFFFFF',
  size: 45
};
