import React from "react"
import { useNavigate } from "react-router-dom"
import {
  Typography,
  Box,
  Avatar,
  AvatarGroup,
  Card,
  CardContent,
  IconButton,
  Chip,
} from "@mui/material"
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
} from "@mui/icons-material"
import { IProjectShow, StatusEnum } from "src/interfaces/project.interface"
import ROUTES from "src/types/Routes"

interface IProjectCardProps {
  project: IProjectShow
}

const ProjectCard: React.FC<IProjectCardProps> = ({ project }) => {
  const navigate = useNavigate()

  return (
    <Card
      sx={{
        padding: "0.6rem",
        minWidth: 280,
        borderRadius: "30px",
        boxShadow: "0px 3px 6px #1C21244F",
      }}
    >
      <CardContent>
        <Box display="flex" justifyContent="center">
          <Typography variant="body1">{project.name}</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          {/* <Box>
            <AvatarGroup total={project.employees.length}>
              {project.employees[0] && (
                <Avatar
                  alt={project.employees[0].fullName}
                  src={project.employees[0].photoURL}
                />
              )}
              {project.employees[1] && (
                <Avatar
                  alt={project.employees[1].fullName}
                  src={project.employees[1].photoURL}
                />
              )}
            </AvatarGroup>
          </Box> */}
          <Chip
            label={project.status}
            color={
              project.status === StatusEnum.ACTIVE
                ? "info"
                : project.status === StatusEnum.ONHOLD
                ? "error"
                : "success"
            }
          ></Chip>
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={() => {
                navigate(`${ROUTES.PROJECTS}/${project.id}`)
              }}
            >
              <VisibilityIcon style={{ width: "1.2em" }} />
            </IconButton>
            <IconButton
              onClick={() => {
                navigate(`${ROUTES.PROJECTS_UPDATE}/${project.id}`)
              }}
            >
              <EditIcon style={{ width: "1.2em" }} />
            </IconButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ProjectCard
