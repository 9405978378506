import { Button, Grid, Switch, Typography } from "@mui/material"
import React, { FC, useContext, useEffect, useState } from "react"
import InputProfile from "src/components/atoms/InputProfile"
import SelectProfile from "src/components/atoms/SelectProfile"
import GoogleInput from "src/components/organisms/AutocompleteLocation"
import { iFilters, SENIORITY_OPTIONS } from "src/interfaces/user.interfaces"
import CRUDModal from "../CRUDModal"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import { getRoles } from "src/services/roles/roles.service"
import { RoleWithId } from "src/interfaces/roles.interfaces"

interface iProps {
  modalFilter: boolean
  setModalFilter: (modalFilter: boolean) => void
  setFilters: (a: iFilters) => void
}

//TODO use DEFAULT_FILTERS from the parent tree to inizialize the state or maybe just use it as state...
const ModalFilterEmployees: FC<iProps> = ({
  modalFilter,
  setModalFilter,
  setFilters,
}) => {
  const [roles, setRoles] = useState<RoleWithId[]>([])

  useEffect(() => {
    getRoles().then(setRoles)
  }, [])

  const [fullName, setFullName] = useState<string | undefined>("")
  const [onBench, setOnBench] = useState<boolean | undefined>(false)
  const [selectedSkills, setSelectedSkills] = useState<Set<string>>(new Set())
  const [currentSkill, setCurrentSkill] = useState<string | undefined>()
  const [companyRole, setCompanyRole] = useState<string>("")
  const [skillPrincipals, setSkillPrincipals] = useState<string>("")
  const [englishLevel, setEnglishLevel] = useState<number | "">("")
  const [birthDate, setBirthDate] = useState<string | undefined>()
  const [location, setLocation] = useState<Location | "">()
  const [seniority, setSeniority] = useState<number | "">("")
  const [email, setEmail] = useState<string | undefined>()
  const [isCurrentEmployee, setIsCurrentEmployee] = useState<boolean>(true)

  const onAddFilters = () => {
    setFilters({
      isCurrentEmployee,
      fullName: fullName ? fullName : "",
      onBench,
      //@ts-ignore
      skills: Array.from(selectedSkills).map((skill: string) =>
        JSON.parse(skill)
      ),
      birthDate,
      companyRoleName: companyRole,
      skillPrincipals: skillPrincipals,
      //@ts-ignore
      location,
      englishLevel: englishLevel ? englishLevel : "",
      seniority: seniority ? seniority : "",
      email,
    })
  }

  useEffect(() => {
    onAddFilters()
  }, [
    fullName,
    onBench,
    selectedSkills,
    birthDate,
    companyRole,
    englishLevel,
    location,
    seniority,
    email,
    isCurrentEmployee,
    skillPrincipals,
  ])

  const cleanFilters = () => {
    console.log("cleanFilters")
    setFullName("")
    setOnBench(false)
    setSelectedSkills(new Set())
    setCurrentSkill(undefined)
    setCompanyRole("")
    setSkillPrincipals("")
    setEnglishLevel("")
    setBirthDate(undefined)
    setLocation("")
    setSeniority("")
    setEmail(undefined)
  }

  const handleCancel = () => {
    cleanFilters()
    setModalFilter(false)
  }

  const handleDone = (e: any) => {
    e.preventDefault()
    onAddFilters()
    setModalFilter(false)
  }

  return (
    <div>
      <CRUDModal
        open={modalFilter}
        setOpen={setModalFilter}
        icon={<FilterAltIcon />}
        title="Employees filters"
        buttonTop={
          <Button
            sx={{ marginLeft: "auto" }}
            variant="contained"
            onClick={cleanFilters}
          >
            Clear Filters
          </Button>
        }
        content={
          <>
            <Grid container>
              <Grid item md={6} xs={12}>
                <InputProfile
                  value={fullName ? fullName : ""}
                  label={"Full Name"}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputProfile
                  value={email ? email : ""}
                  label={"Email"}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <SelectProfile
                  //@ts-ignore
                  options={roles?.map((role) => ({
                    value: role.name,
                    label: role.name,
                  }))}
                  label={"Company Role"}
                  value={companyRole}
                  onChange={(e) => {
                    setCompanyRole(e)
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <SelectProfile
                  label="English level"
                  placeholder="English level"
                  value={englishLevel}
                  onChange={(value) => setEnglishLevel(value)}
                  options={[
                    { value: "1", label: "Unknown" },
                    { value: "2", label: "Pre-Intermediate" },
                    { value: "3", label: "Intermediate" },
                    { value: "4", label: "Upper-Intemediate" },
                    { value: "5", label: "Advanced" },
                    { value: "6", label: "Native" },
                  ]}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <SelectProfile
                  label="Seniority"
                  placeholder="Seniority"
                  value={seniority}
                  onChange={(value) => setSeniority(value)}
                  options={SENIORITY_OPTIONS}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography color="primary" pl={3} variant="caption">
                  In Bench
                </Typography>

                <Switch
                  checked={onBench}
                  onChange={(e) => setOnBench(e.target.checked)}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography color="primary" pl={3} variant="caption">
                  Is current employee
                </Typography>

                <Switch
                  checked={isCurrentEmployee}
                  onChange={(e) => setIsCurrentEmployee(e.target.checked)}
                />
              </Grid>

              <Grid item mt={2} md={12} xs={12}>
                <GoogleInput
                  comingValue={location ? location : null}
                  onlyCountries
                  handleValue={(value: any) => setLocation(value)}
                  value={location}
                  label={"Location"}
                />
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button variant="contained" color="primary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" color="success" onClick={handleDone}>
              Done
            </Button>
          </>
        }
      />
    </div>
  )
}

export default ModalFilterEmployees
