import React from 'react';
import { BasicIconSet } from './BasicIcon/IconsImport';
import './Icon.css';

interface IconProps {
  color?: string;
  type?:
    | 'IconAdmins'
    | 'IconFilter'
    | 'IconHome'
    | 'IconLogOut'
    | 'IconProjects'
    | 'IconPTO'
    | 'IconRoles'
    | 'IconSave'
    | 'IconSearch'
    | 'IconSkills'
    | 'IconTeamMembers'
    | 'IconAglesLeft'
    | 'IconAnglesRight'
    | 'IconCardMode'
    | 'IconDoc'
    | 'IconEye'
    | 'IconLocate'
    | 'IconPen'
    | 'IconTable'
    | 'IconTrash'
    | 'IconAngular'
    | 'IconReact'
    | null;
  size?: 'tiny' | 'small' | 'medium' | 'large';
}

export const Icon = ({
  color,
  size = 'tiny',
  type = 'IconAdmins'
}: IconProps) => {
  const styleProps = {
    color: color
  };
  const SVG = BasicIconSet.filter((iconType) => iconType.name == type).map(
    (icon, key) => <icon.component key={key} />
  );
  return (
    <span
      style={{ fill: color }}
      className={[
        'class-svg',
        size === 'tiny'
          ? 'class-tiny'
          : size === 'small'
          ? 'class-small'
          : size === 'medium'
          ? 'class-medium'
          : size === 'large'
          ? 'class-large'
          : ''
      ].join(' ')}
    >
      {SVG}
    </span>
  );
};
