import React from 'react';

export const IconEye = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30.153'
      height='23.453'
      viewBox='0 0 30.153 23.453'
    >
      <path
        id='eye-solid_2_'
        data-name='eye-solid (2)'
        d='M14.637,38.722a4.121,4.121,0,0,1,.44-.021,5.026,5.026,0,1,1-5.026,5.026,4.121,4.121,0,0,1,.021-.44,3.843,3.843,0,0,0,1.654.44,3.354,3.354,0,0,0,3.35-3.35A3.843,3.843,0,0,0,14.637,38.722Zm10.523-2.5a20.282,20.282,0,0,1,4.863,6.863,1.665,1.665,0,0,1,0,1.288,21.226,21.226,0,0,1-4.863,6.863,14.731,14.731,0,0,1-10.083,4.219A14.726,14.726,0,0,1,5,51.234,21.307,21.307,0,0,1,.129,44.371a1.673,1.673,0,0,1,0-1.288A20.355,20.355,0,0,1,5,36.219,14.736,14.736,0,0,1,15.077,32,14.74,14.74,0,0,1,25.16,36.219Zm-10.083-.031a7.539,7.539,0,1,0,7.539,7.539A7.54,7.54,0,0,0,15.077,36.188Z'
        transform='translate(0 -32)'
      />
    </svg>
  );
};
