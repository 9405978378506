import React from "react"
import { useNavigate } from "react-router-dom"
import { Grid } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import "./ProfileHeader.scss"

const ProfileHeader = ({ isLoading, isFirstLogin }: any) => {
  const navigate = useNavigate()

  const handleClose = () => {
    navigate(-1)
  }

  return (
    <Grid
      container
      sm={12}
      sx={{
        padding: "1em",
      }}
      alignItems="center"
      className="class-ProfileHeader"
    >
      {/* <Grid item sm={12} md={6}>
        <img
          src={azumo}
          alt="Workflow"
          style={{
            color: 'grey',
            filter: 'invert(40%)',
          }}
        />
      </Grid> */}
      <LoadingButton
        loading={isLoading}
        variant="contained"
        color="secondary"
        size="large"
        onClick={handleClose}
        disabled={isFirstLogin}
        fullWidth
      >
        Cancel
      </LoadingButton>
      <LoadingButton
        loading={isLoading}
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        form="userForm"
        fullWidth
      >
        Save
      </LoadingButton>
    </Grid>
  )
}

export default ProfileHeader
