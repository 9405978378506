import { Box, Grid, ThemeProvider, Typography } from "@mui/material"
import { createTheme } from "@mui/system"
import React, { FC } from "react"
import {
  EnglishLevel,
  iUserWithId,
  SENIORITY_OPTIONS,
} from "src/interfaces/user.interfaces"
import DateHelper from "src/helpers/date/DateHelper"

interface iProps {
  user: iUserWithId
}
const ProfessionalView: FC<iProps> = ({ user }) => {
  const ENGLISH_LEVEL_OPTIONS = [
    { value: EnglishLevel.preIntermediate, label: "Pre-Intermediate" },
    { value: EnglishLevel.intermediate, label: "Intermediate" },
    { value: EnglishLevel.upperIntemediate, label: "Upper-Intemediate" },
    { value: EnglishLevel.advanced, label: "Advanced" },
    { value: EnglishLevel.native, label: "Native" },
  ]

  const theme = createTheme({
    palette: {
      background: {
        paper: "#fff",
      },
      text: {
        primary: "#173A5E",
        secondary: "#46505A",
      },
      action: {
        active: "#001E3C",
      },
      success: {
        dark: "#009688",
        red: "red",
      },
    },
  })

  const displayDate = (dateToDisplay: string) => {
    const newDate = new Date(dateToDisplay)
    return (
      newDate.getMonth() + "/" + newDate.getDate() + "/" + newDate.getFullYear()
    )
  }

  const dateDiff = DateHelper.dateDiff(
    new Date(user.companyStartDate),
    user.companyEndDate ? new Date(user.companyEndDate) : new Date()
  )

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          p: 2,
          minWidth: 300,
          gap: 2,
          margin: "30px 0",
          borderRadius: 2,
        }}
      >
        <Typography style={{ color: "#828689" }}>
          <Box
            sx={{
              fontSize: 16,
              fontWeight: "medium",
              display: "flex",
              alignItems: "center",
              gap: 2,
              marginBottom: 2,
            }}
          >
            Professional
          </Box>
        </Typography>
        <Grid
          container
          sx={{
            color: "text.secondary",
            fontSize: 14,
          }}
        >
          <Grid item xs={12} md={4} sx={{ p: 2 }}>
            <Typography style={{ color: "#828689" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Role & Seniority
              </Typography>
              {user?.seniority
                ? SENIORITY_OPTIONS.filter(
                    (srty) => srty.value === user?.seniority
                  ).map((data) => data.label)
                : "not added yet"}
              {" " + user?.companyRole?.name || "Employee"}
            </Typography>
          </Grid>

          <Grid item xs={12} md={4} sx={{ p: 2 }}>
            <Typography style={{ color: "#828689" }}>
              <Typography sx={{ fontWeight: "bold" }}>Primary Skill</Typography>
              {user?.skillPrincipals ? user?.skillPrincipals : "not added yet"}
            </Typography>
          </Grid>

          <Grid item xs={12} md={4} sx={{ p: 2 }}>
            <Typography style={{ color: "#828689" }}>
              <Typography sx={{ fontWeight: "bold" }}>English Level</Typography>
              {user?.englishLevel
                ? ENGLISH_LEVEL_OPTIONS.filter((filter) => {
                    /* eslint-disable-next-line */
                    return filter.value == user.englishLevel
                  }).map((data) => data.label)
                : "not added yet"}
            </Typography>
          </Grid>

          <Grid item xs={12} md={4} sx={{ p: 2 }}>
            <Typography style={{ color: "#828689" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Company Start Date
              </Typography>
              {user.companyStartDate
                ? displayDate(user.companyStartDate)
                : "not added yet"}
            </Typography>
          </Grid>

          <Grid item xs={12} md={4} sx={{ p: 2 }}>
            <Typography style={{ color: "#828689" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Company End Date
              </Typography>
              {user.companyEndDate
                ? displayDate(user.companyEndDate)
                : "not added yet"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ p: 2 }}></Grid>

          <Grid item xs={12} md={4} sx={{ p: 2 }}>
            <Typography style={{ color: "#828689" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Tenure Year(s)
              </Typography>
              {!Number.isNaN(dateDiff.years)
                ? `${dateDiff.years} Year(s)`
                : "--"}
            </Typography>
          </Grid>

          <Grid item xs={12} md={4} sx={{ p: 2 }}>
            <Typography style={{ color: "#828689" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Tenure Month(s)
              </Typography>
              {!Number.isNaN(dateDiff.months)
                ? `${dateDiff.months} Month(s)`
                : "--"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  )
}

export default ProfessionalView
