import React from 'react'
import { Autocomplete, TextField, FormLabel, Box } from '@mui/material'
import { iUserWithId } from 'src/interfaces/user.interfaces'

export interface IUserAutoComplete extends iUserWithId {
  label: string
}

interface IUsersAutoCompleteProps {
  users: iUserWithId[]
  label: string
  onChange: (user: IUserAutoComplete | null) => void
  selectedUser?: IUserAutoComplete | null
}

const UsersAutoComplete: React.FC<IUsersAutoCompleteProps> = ({
  users,
  label,
  onChange,
  selectedUser = null,
}) => {
  return (
      <Autocomplete
        disablePortal
        id="users-autocomplete"
        sx={{ minWidth: 250 }}
        options={
          users.length
            ? users.map((user) => ({
                ...user,
                label: `${user.fullName}`,
              }))
            : []
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedUser}
        renderInput={(params) => (
          <Box sx={{ display: 'flex' }} flexDirection="column">
            <FormLabel>{label}</FormLabel>
            <TextField
              {...params}
              hiddenLabel
              placeholder="Enter Employee name"
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: 5,
                  },
                },
                '& input': {
                  borderRadius: 5,
                  '&:focus': {
                    borderRadius: 5,
                  },
                },
              }}
            />
          </Box>
        )}
        onChange={(_, value) => onChange(value)}
      />
  )
}

export default UsersAutoComplete
