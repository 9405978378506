import { Container, Grid } from "@mui/material"
import React, { useContext } from "react"
import { Controller, ControllerRenderProps, FieldValues } from "react-hook-form"
import { NotificationManager } from "react-notifications"
import CertificateSelectProfile from "src/components/moleculas/CertificateSelectProfile"
import CertificationsTable from "src/components/moleculas/CertificationsTable"
import { RegisterContext } from "src/contexts/register.context"
import { Certification } from "src/interfaces/user.interfaces"
import "../Certificate/Certificate.scss"

interface iProps {
  isEdit: boolean
  editMethods: any
}

const Certificate = ({ isEdit, editMethods }: iProps) => {
  const { methods } = useContext(RegisterContext)

  const { control } = isEdit ? editMethods : methods

  const handleAddCertification = (
    newCert: Certification,
    field: ControllerRenderProps<FieldValues, "certifications">
  ) => {
    const isCertificationAlreadyAdded = field.value?.find(
      (cert: Certification) => cert.id === newCert.id
    )

    if (isCertificationAlreadyAdded) {
      NotificationManager.warning(
        "Certification cannot be duplicated",
        "Warning",
        2000
      )
      return null
    }

    const newCertifications = [...(field.value ? field.value : []), newCert]
    field.onChange(newCertifications)
  }

  const handleDeleteCertification = (
    id: string,
    field: ControllerRenderProps<FieldValues, "certifications">
  ) => {
    const currentCertifications = field.value.filter(
      (cert: Certification) => cert.id !== id
    )

    field.onChange(currentCertifications)
  }

  return (
      <Grid
        className="class-Certificate"
        container
        spacing={4}
        alignItems="end"
        pb={4}
      >
        <Controller
          name="certifications"
          control={control}
          render={({ field }) => (
            <>
              <CertificateSelectProfile
                onAddCertification={(newCert) =>
                  handleAddCertification(newCert, field)
                }
              />
              <Grid item sm={12} md={12}>
                <CertificationsTable
                  certifications={field.value ?? []}
                  onDelete={(id: string) =>
                    handleDeleteCertification(id, field)
                  }
                />
              </Grid>
            </>
          )}
        />
      </Grid>
  )
}

export default Certificate
