import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Box,
  styled,
  Chip,
} from '@mui/material'

import ProtectedComponent from 'src/components/atoms/ProtectedComponent'

import Pagination from 'src/components/moleculas/Pagination'
import { IClient } from 'src/interfaces/client.interfaces'
import panda from 'src/assets/images/PandaCoolAhre.png'
import ROUTES from 'src/types/Routes'
import {
  EyeIcon,
  PencilIcon,
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
} from '@heroicons/react/outline'

interface IClientsTableProps {
  clients: IClient[]
  minHeight?: number
}

const ClientsTable: React.FC<IClientsTableProps> = ({ clients, minHeight }) => {
  const navigate = useNavigate()

  const [currentPageClients, setCurrentPageClients] =
    useState<IClient[] | undefined>(undefined)
  const [sortedByStatus, setUpSortedByStatus] =
    useState<boolean | undefined>(undefined)
  const [sortedByName, setUpSortedByName] =
    useState<boolean | undefined>(undefined)
  const [sortedByCSM, setUpSortedByCSM] =
    useState<boolean | undefined>(undefined)

  const showingClients = clients ? clients : []

  const EmployeesTableHead = styled(TableRow)(({ theme }) => ({
    '&': {
      boxShadow: '0px 3px 6px #1C212426',
      opacity: 1,
      borderBottomLeftRadius: '15px',
      borderBottomRightRadius: '15px',
    },
    '& th': {
      borderBottom: '0',
    },
  }))

  const sortBystatus = (
    sortedBoolean: boolean | undefined,
    typeFilter: 'status' | 'name' | 'about' | 'CSM',
    setUpSorted: (boolean: boolean | undefined) => void
  ) => {
    let localSorted: boolean | undefined = undefined

    if (sortedBoolean === false) {
      localSorted = true
    } else if (sortedBoolean === undefined) {
      localSorted = false
    } else if (sortedBoolean === true) {
      localSorted = undefined
    }

    if (clients) {
      const newClients = [...clients]
      const sortedClients =
        localSorted === undefined
          ? newClients
          : newClients.sort((a, b) => {
            if (
              typeFilter === 'status'
                ? a.status < b.status
                : typeFilter === 'name'
                  ? a.name < b.name
                  : typeFilter === 'about'
                    ? a.businessDescription < b.businessDescription
                    : typeFilter === 'CSM'
                      ? a.csmName < b.csmName
                      : null
            ) {
              return !localSorted ? -1 : 1
            } else {
              return !localSorted ? 1 : -1
            }
          })

      setCurrentPageClients(sortedClients)

      if (sortedBoolean === false) {
        setUpSorted(true)
      } else if (sortedBoolean === undefined) {
        setUpSorted(false)
      } else if (sortedBoolean === true) {
        setUpSorted(undefined)
      }
    }
  }

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          <TableHead>
            <EmployeesTableHead>
              <TableCell
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() =>
                  sortBystatus(sortedByName, 'name', setUpSortedByName)
                }
              >
                <Box display={'flex'} justifyContent="space-between">
                  Client Name
                  {sortedByName === undefined ? null : !sortedByName ? (
                    <ArrowCircleUpIcon style={{ width: '1em' }} />
                  ) : (
                    <ArrowCircleDownIcon style={{ width: '1em' }} />
                  )}
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() =>
                  sortBystatus(sortedByStatus, 'status', setUpSortedByStatus)
                }
              >
                <Box display={'flex'} justifyContent="space-between">
                  Status
                  {sortedByStatus === undefined ? null : !sortedByStatus ? (
                    <ArrowCircleUpIcon style={{ width: '1em' }} />
                  ) : (
                    <ArrowCircleDownIcon style={{ width: '1em' }} />
                  )}
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() =>
                  sortBystatus(sortedByCSM, 'CSM', setUpSortedByCSM)
                }
              >
                <Box display={'flex'} justifyContent="space-between">
                  CSM
                  {sortedByCSM === undefined ? null : !sortedByCSM ? (
                    <ArrowCircleUpIcon style={{ width: '1em' }} />
                  ) : (
                    <ArrowCircleDownIcon style={{ width: '1em' }} />
                  )}
                </Box>
              </TableCell>
              <TableCell></TableCell>
            </EmployeesTableHead>
          </TableHead>
          <TableBody>
            {currentPageClients?.map((row) => (
              <TableRow key={row?.id}>
                <TableCell>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      alt="client logo"
                      src={row?.logo?.url || panda}
                      sx={{ marginRight: 3 }}
                    />
                    {row?.name}
                  </div>
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: '100px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {row?.status || "--"}
                  {/* <Chip
                    label={row?.status || "--"}
                    color={row?.status === "Active" ? "info" : row?.status === "Completed" ? "success" : "error"}
                  /> */}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: '100px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {row?.csmName ? row?.csmName : 'N/A'}
                </TableCell>
                <TableCell width={1}>
                  <Box
                    display="flex"
                    sx={{ color: 'primary.blue', display: 'flex', gap: '20px' }}
                  >
                    <EyeIcon
                      style={{ width: '1.8em', cursor: 'pointer' }}
                      onClick={() => {
                        navigate(`${ROUTES.CLIENTS}/${row?.id}`)
                      }}
                    />
                    <ProtectedComponent requiredPermissions={["client:update"]}>
                      <PencilIcon
                        style={{ width: '1.65em', cursor: 'pointer' }}
                        onClick={() => {
                          navigate(`${ROUTES.CLIENTS}/${row?.id}/edit`)
                        }}
                      />
                    </ProtectedComponent>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        onChangeVisibleRecords={setCurrentPageClients}
        records={showingClients}
      />
    </>
  )
}

export default ClientsTable
