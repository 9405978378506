import React from 'react';

export const IconTable = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='27.96'
      height='23.45'
      viewBox='0 0 27.96 23.45'
    >
      <path
        id='list-solid_1_'
        data-name='list-solid (1)'
        d='M20.059,48a1.351,1.351,0,0,1,1.353,1.353v2.706a1.35,1.35,0,0,1-1.353,1.353H17.353A1.351,1.351,0,0,1,16,52.059V49.353A1.353,1.353,0,0,1,17.353,48Zm22.1.9a1.8,1.8,0,0,1,0,3.608H25.921a1.8,1.8,0,1,1,0-3.608Zm0,9.019a1.8,1.8,0,0,1,0,3.608H25.921a1.8,1.8,0,1,1,0-3.608Zm0,9.019a1.8,1.8,0,0,1,0,3.608H25.921a1.8,1.8,0,0,1,0-3.608ZM16,58.372a1.351,1.351,0,0,1,1.353-1.353h2.706a1.35,1.35,0,0,1,1.353,1.353v2.706a1.35,1.35,0,0,1-1.353,1.353H17.353A1.351,1.351,0,0,1,16,61.078Zm4.059,7.666a1.35,1.35,0,0,1,1.353,1.353V70.1a1.35,1.35,0,0,1-1.353,1.353H17.353A1.351,1.351,0,0,1,16,70.1V67.391a1.351,1.351,0,0,1,1.353-1.353Z'
        transform='translate(-16 -48)'
      />
    </svg>
  );
};
