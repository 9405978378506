import { Grid } from "@mui/material"
import { useState } from "react"
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons"
import FaIconButton from "src/components/atoms/FaIconButton"
import InputProfile from "src/components/atoms/InputProfile"
import DatePicker from "src/components/atoms/DatePicker"
import { Certification } from "src/interfaces/user.interfaces"
import DateHelper from "src/helpers/date/DateHelper"
import InputUploadFile from "src/components/atoms/InputUploadFile"

const MD_SIZE = 2.7

interface Props {
  onAddCertification: (newCertification: Certification) => void
}

const CertificateSelectProfile = ({ onAddCertification }: Props) => {
  const [title, setTitle] = useState<string>("")
  const [company, setCompany] = useState<string>("")
  const [date, setDate] = useState<Date | null>(null)
  const [attachedFile, setAttachedFile] = useState<string>("")

  const clearFields = () => {
    setTitle("")
    setCompany("")
    setDate(null)
    setAttachedFile("")
  }

  const handleAddSkill = () => {
    const newCertification = {
      id: `${company}-${title}`,
      title,
      company,
      attachedFile,
      date: date ? DateHelper.toString(date) : "",
    } as Certification

    onAddCertification(newCertification)
    clearFields()
  }

  const isDisabled = !title || !company || !date || !attachedFile

  return (
    <>
      <Grid item sm={12} md={MD_SIZE}>
        <InputProfile
          label="Title *"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Write your title"
        />
      </Grid>
      <Grid item sm={12} md={MD_SIZE}>
        <InputProfile
          label="Company *"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          placeholder="Write the company"
        />
      </Grid>
      <Grid item sm={12} md={MD_SIZE}>
        <DatePicker label="Completion date *" value={date} onChange={setDate} />
      </Grid>
      <Grid item sm={12} md={MD_SIZE}>
        <InputUploadFile onChange={setAttachedFile} value={attachedFile} />
      </Grid>

      <Grid item sm={12} md={1}>
        <FaIconButton
          disabled={isDisabled}
          onClick={handleAddSkill}
          icon={faFloppyDisk}
          value={attachedFile}
        />
      </Grid>
    </>
  )
}

export default CertificateSelectProfile
