import React from "react"
import { Box, ThemeProvider, createTheme } from "@mui/system"
import { iUserWithId } from "src/interfaces/user.interfaces"
import { Typography } from "@mui/material"

const theme = createTheme({
  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#173A5E",
      secondary: "#46505A",
    },
    action: {
      active: "#001E3C",
    },
    success: {
      dark: "#009688",
      red: "red",
    },
  },
})

const Bio = (props: { currentPageEmployees: iUserWithId }) => {
  // console.log("currentPageEmployees",props.currentPageEmployees)
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          p: 2,
          minWidth: 300,
          gap: 2,
          margin: "0",
          borderRadius: 2,
        }}
      >
        <Typography style={{ color: "#828689", display: "grid", gap: "20px" }}>
          <Box
            sx={{
              fontSize: 16,
              fontWeight: "medium",
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            About Me
          </Box>
          <Box sx={{ color: "#828689", fontSize: 16 }}>
            {props.currentPageEmployees?.bio
              ? props.currentPageEmployees?.bio
              : "Not added yet"}
          </Box>
        </Typography>
      </Box>
    </ThemeProvider>
  )
}

export default Bio
