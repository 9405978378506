import React from 'react';

export const IconTrash = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22.12'
      height='25.28'
      viewBox='0 0 22.12 25.28'
    >
      <path
        id='trash-solid_1_'
        data-name='trash-solid (1)'
        d='M6.675.873A1.578,1.578,0,0,1,8.088,0h5.945a1.578,1.578,0,0,1,1.412.873l.356.707h4.74a1.58,1.58,0,0,1,0,3.16H1.58a1.58,1.58,0,0,1,0-3.16H6.32Zm12.818,22.14a2.375,2.375,0,0,1-2.365,2.266H4.992a2.376,2.376,0,0,1-2.366-2.266L1.536,6.32h19Z'
      />
    </svg>
  );
};
