import { BaseTextFieldProps, TextField } from '@mui/material'

interface Props extends BaseTextFieldProps {
  label: string
  value: string | undefined
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  rows?: number
  placeholder?: string
}

const SearchBar = (props: Props) => {
  return (
    <TextField
      {...props}
      onChange={props.onChange}
      hiddenLabel
      multiline={!!props.rows}
      maxRows={props.rows}
      fullWidth
      id="filled-hidden-label-small"
      size="small"
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderRadius: 5,
          },
          '& textarea': {
            '&:focus': {
              border: 'none',
              overFlow: 'auto',
              outline: 'none',
              WebkitBoxShadow: 'none',
            },
          },
        },
        '& input': {
          borderRadius: 5,
          '&:focus': {
            borderRadius: 5,
          },
        },
      }}
    />
  )
}

export default SearchBar
