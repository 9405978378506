import {
  collection,
  doc,
  documentId,
  getDocs,
  query,
  addDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { COLLECTIONS, db } from '../firebase/firebase.service'
import storageService from '../firebase/storage.service'
import { ResponseT } from 'src/types/types'
import { IClient } from 'src/interfaces/client.interfaces'
import {
  IProjectForm,
  IProjectWithAction,
} from 'src/interfaces/project.interface'
import { IStorageFile } from 'src/interfaces/file.interfaces'
// Other services
import FileService from 'src/services/file/file.service'
import ProjectsService from 'src/services/projects/projects.service'
// Mappers
import { mapToProjectModel } from 'src/pages/Projects/ProjectForm/mappers'

const baseStorageFile: IStorageFile = {
  name: '',
  path: '',
  url: '',
}

const createClient = async (
  client: Omit<IClient, 'id'>,
  logoFile?: File,
  sowFile?: File
): Promise<ResponseT> => {
  try {
    const collectionReference = collection(db, COLLECTIONS.CLIENTS)
    const response = await addDoc(collectionReference, {
      ...client,
      // Stakeholders will be checked and mapped below, for now we need to create the Client to get their id
      keyStakeHolders: [],
      deleted: false,
    })

    const clientId = response.id
    const clientDocRef = doc(db, COLLECTIONS.CLIENTS, clientId)

    const updatedStakeHolders = []

    for (const stakeHolder of [...client.keyStakeHolders]) {
      if (stakeHolder.photoFile !== undefined) {
        stakeHolder.photo = {
          ...(await FileService.uploadFile(
            `${COLLECTIONS.CLIENTS}/${clientId}/stakeholders/${stakeHolder.email}/${stakeHolder.photoFile.name}`,
            stakeHolder.photoFile
          )),
        }
      }
      // Firebase does not accept undefined as value
      delete stakeHolder.photoFile
      updatedStakeHolders.push(stakeHolder)
    }

    let logo: IStorageFile = {
      ...baseStorageFile,
    }
    let sow: IStorageFile = {
      ...baseStorageFile,
    }

    if (logoFile !== undefined) {
      logo = {
        ...(await FileService.uploadFile(
          `${COLLECTIONS.CLIENTS}/${clientId}/logo/${logoFile.name}`,
          logoFile
        )),
      }
    }

    if (sowFile !== undefined) {
      sow = {
        ...(await FileService.uploadFile(
          `${COLLECTIONS.CLIENTS}/${clientId}/sow/${sowFile.name}`,
          sowFile
        )),
      }
    }

    await updateDoc(clientDocRef, {
      keyStakeHolders: updatedStakeHolders,
      logo,
      sow,
    })

    return {
      statusCode: 200,
      message: 'Client created successfully',
      data: response,
    }
  } catch (err: any) {
    console.error(err.message)
    return {
      statusCode: err.code || 500,
      message: err.message || 'Error while creating client',
    }
  }
}

const updateClient = async (
  clientId: string,
  client: Omit<IClient, 'id'>,
  logoFile?: File,
  sowFile?: File
): Promise<ResponseT> => {
  try {
    let logo: IStorageFile = {
      ...client.logo,
    }
    let sow: IStorageFile = {
      ...client.sow,
    }

    if (logoFile !== undefined) {
      // Delete previous file (if any)
      const previousLogoPath = client.logo.path
      if (previousLogoPath) await storageService.deleteFile(previousLogoPath)

      logo = {
        ...(await FileService.uploadFile(
          `${COLLECTIONS.CLIENTS}/${clientId}/logo/${logoFile.name}`,
          logoFile
        )),
      }
    }

    if (sowFile !== undefined) {
      // Delete previous file (if any)
      const previousSowPath = client.sow.path
      if (previousSowPath) await storageService.deleteFile(previousSowPath)

      sow = {
        ...(await FileService.uploadFile(
          `${COLLECTIONS.CLIENTS}/${clientId}/sow/${sowFile.name}`,
          sowFile
        )),
      }
    }

    // Check for deleted stakeholders and remove their photo file (if any)
    const previousClient = await getClient(clientId)

    if (!('error' in previousClient)) {
      const newStakeholdersEmails = client.keyStakeHolders.map((x) => x.email)

      for (const prevStakeholder of previousClient.keyStakeHolders) {
        if (
          !newStakeholdersEmails.includes(prevStakeholder.email) &&
          prevStakeholder.photo.path
        ) {
          await storageService.deleteFile(prevStakeholder.photo.path)
        }
      }
    }

    // Check for created stakeholders and add their photo file (if any)
    const updatedStakeHolders = []

    for (const stakeHolder of [...client.keyStakeHolders]) {
      // In this case only stakeholders created in memory can have the photoFile field set, so this tells us that it is a new one
      if (stakeHolder.photoFile !== undefined) {
        stakeHolder.photo = {
          ...(await FileService.uploadFile(
            `${COLLECTIONS.CLIENTS}/${clientId}/stakeholders/${stakeHolder.email}/${stakeHolder.photoFile.name}`,
            stakeHolder.photoFile
          )),
        }
      }
      // Firebase does not accept undefined as value
      delete stakeHolder.photoFile
      updatedStakeHolders.push(stakeHolder)
    }

    const updatedClient: Omit<IClient, 'id'> = {
      ...client,
      keyStakeHolders: updatedStakeHolders,
      logo,
      sow,
    }

    const clientDocRef = doc(db, COLLECTIONS.CLIENTS, clientId)
    await updateDoc(clientDocRef, updatedClient)

    return {
      statusCode: 200,
      message: 'Client updated successfully',
      data: updatedClient,
    }
  } catch (err: any) {
    console.error(err.message)
    return {
      statusCode: 500,
      message: err.message || 'Error while updating client',
    }
  }
}

const getClients = async (): Promise<IClient[] | { error: string }> => {
  try {
    const collectionReference = collection(db, COLLECTIONS.CLIENTS)
    const q = query(collectionReference, where('deleted', '==', false))

    const querySnapshot = await getDocs(q)

    let clients: IClient[] = []
    querySnapshot.forEach((doc) => {
      let client = {
        ...(doc.data() as IClient),
        id: doc.id,
      }
      clients.push(client)
    })
    return clients
  } catch (err: any) {
    console.error(err.message)
    return { error: err.message }
  }
}

const getClient = async (
  clientId: string
): Promise<IClient | { error: string }> => {
  try {
    let client: IClient | undefined

    const collectionReference = collection(db, COLLECTIONS.CLIENTS)
    const q = query(
      collectionReference,
      ...[where(documentId(), '==', clientId), where('deleted', '==', false)]
    )

    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      client = {
        ...(doc.data() as IClient),
        id: doc.id,
      }
    })

    if (!client) {
      throw new Error('No clients found')
    }

    return client
  } catch (err: any) {
    console.error(err.message)
    return { error: err.message }
  }
}

const deleteClient = async (clientId: string): Promise<ResponseT> => {
  try {
    const documentReference = doc(db, COLLECTIONS.CLIENTS, clientId)
    const updatedDoc = await updateDoc(documentReference, { deleted: true })

    return {
      statusCode: 200,
      message: 'Client deleted successfully',
      data: updatedDoc,
    }
  } catch (err: any) {
    console.error(err.message)
    return {
      statusCode: err.code || 500,
      message: err.message || 'Error while deleting client',
    }
  }
}

const ClientsService = {
  createClient,
  updateClient,
  getClients,
  getClient,
  deleteClient,
}

export default ClientsService
