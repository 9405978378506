import { FC, useEffect, useState } from 'react'
import { Box, MenuItem, Pagination, Select, Typography } from '@mui/material'
import './index.css'

interface PaginationProps {
  onChangeVisibleRecords: (value: any[]) => void
  records: any[]
}
const PaginationMine: FC<PaginationProps> = ({
  onChangeVisibleRecords,
  records,
}) => {
  const [currentPage, setCurrentPages] = useState<number>(1)
  const [totalItems, setTotalItems] = useState<number>(records.length)
  const [pageSize, setPageSize] = useState<number>(8)
  const [previousPage, setPreviousPage] = useState<number>(0)

  useEffect(() => {
    setTotalItems(records.length)
  }, [records.length])

  const showNextPage = () => {
    if (currentPage * pageSize < totalItems) {
      setCurrentPages(currentPage + 1)
      setPreviousPage(currentPage)
    }
  }
  const showPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPages(currentPage - 1)
      setPreviousPage(currentPage)
    }
  }

  const showSelectedPage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPages(Number(value))
    setPreviousPage(currentPage)
  }

  const showFirstPage = () => {
    setCurrentPages(1)
    setPreviousPage(currentPage)
  }

  const showLastPage = () => {
    setCurrentPages(Math.ceil(totalItems / pageSize))
    setPreviousPage(currentPage)
  }

  useEffect(() => {
    onChangeVisibleRecords(
      records.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      )
    )
    if (totalItems !== records.length) {
      setTotalItems(records.length)
      setCurrentPages(1)
    }
    if (currentPage * pageSize > totalItems + pageSize) {
      setCurrentPages(1)
    }
  }, [currentPage, pageSize, records])

  return (
    <Box
      p={3}
      justifyContent={'space-between'}
      sx={{ backgroundColor: 'white' }}
    >
      <Box
        display={'flex'}
        p={3}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ backgroundColor: 'white' }}
      >
        <div>
          <Typography variant="body2">
            Showing{' '}
            <span className="font-medium">              
              {currentPage * pageSize - pageSize + 1}
            </span>{' '}
            to{' '}
            <span className="font-medium">
              {currentPage * pageSize > totalItems
                ? totalItems
                : currentPage * pageSize}
            </span>{' '}
            of <span className="font-medium">{totalItems}</span> results
          </Typography>
        </div>
        <Box display={'flex'} alignItems={'center'}>
          <Typography sx={{marginRight:"10px"}} variant="body2">Page size:</Typography>
          <Select
            sx={{ color: 'primary.dark' }}
            value={pageSize}
            onChange={(e) => {
              //@ts-ignore
              setPageSize(e.target.value)
            }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
        <div>
          <Pagination
            onChange={showSelectedPage}
            count={Math.ceil(totalItems / pageSize)}
          />
        </div>
      </Box>
    </Box>
  )
}

export default PaginationMine
