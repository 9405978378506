import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'

interface Props {
  label: string
  checked?: boolean
  onChange?: (event: React.SyntheticEvent, checked: boolean) => void;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
}

const PandaCheckbox = ({ labelPlacement = 'end', ...props }: Props) => {
  return (
    <FormGroup>
      <FormControlLabel
        {...props}
        control={<Checkbox />}
        labelPlacement={labelPlacement}
      />
    </FormGroup>
  )
}

export default PandaCheckbox
