import { atom } from 'recoil'

const isLeftSidebar = atom({
  key: 'isLeftSidebar',
  default: true,
})

const leftSidebar = {
  isLeftSidebar,
}

export default leftSidebar
