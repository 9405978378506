import { Routes, Route, Navigate } from "react-router-dom"

import { ClientContext } from "./ViewClients/client-context"
import useClients from "./ViewClients/use-clients"
import ViewClients from "./ViewClients"
import ClientDetails from "./ClientDetails"
import ClientForm from "./ClientForm"

import ProtectedComponent from "src/components/atoms/ProtectedComponent"

const ClientContainer = () => {
  const clientFuncs = useClients()

  return (
    <ClientContext.Provider
      value={clientFuncs}
    >
      <Routes>
        <Route index element={<ViewClients />} />
        <Route path="/:id" element={
          <ProtectedComponent requiredPermissions={["client:read"]} fallback={<Navigate to="/" />}>
            <ClientDetails />
          </ProtectedComponent>
        } />
        <Route path="/create" element={
          <ProtectedComponent requiredPermissions={["client:create"]} fallback={<Navigate to="/" />}>
            <ClientForm />
          </ProtectedComponent>
        } />
        <Route path="/:id/edit" element={
          <ProtectedComponent requiredPermissions={["client:update"]} fallback={<Navigate to="/" />}>
            <ClientForm />
          </ProtectedComponent>
        } />
      </Routes>
    </ClientContext.Provider>
  )
}

export default ClientContainer
