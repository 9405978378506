import React, { useState, useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import { useRecoilState } from 'recoil'
// Interfaces
import { IProjectShow, iFilters, Status } from 'src/interfaces/project.interface'
// Recoil
import authState from 'src/recoil/auth'
// Services
import ProjectsService from 'src/services/projects/projects.service'
import useEmployees from '../../Employees/Hooks/use-employees'
// src/pages/Employees/Employee-hook
import useClients from 'src/pages/Clients/ViewClients/use-clients'

const DEFAULT_FILTERS = {
  name: "",
  foName: "",
  tlName: "",
  csmName: "",
  clientName: "",
  status: "" as Status
} as iFilters

export default function useProjects() {
    const { employees } = useEmployees()

    const { clients } = useClients()

    const [userProject] = useRecoilState(authState.user)

    const [loadingProject, setLoadingProject] = useState(false)
    const [projects, setProjects] = useState<IProjectShow[]>([])

    const [projectsFiltered, setProjectsFiltered] = useState<IProjectShow[]>([])
    const [filter, setFilter] = useState<iFilters>(DEFAULT_FILTERS)

    const getProjects = async () => {
      setLoadingProject(true)
      const result = await ProjectsService.getProjects()
      setLoadingProject(false)

      if ('error' in result) {
        NotificationManager.error(result.error, 'Error', 3000)
      } else {
        setProjects(result)
        setProjectsFiltered(result ? result : [])
      }
    }

    const [getCSM, setGetCSM] = useState<any[]>([])
    const [getFO, setGetFO] = useState<any[]>([])
    const [getTL, setGetTL] = useState<any[]>([])
    const [getClient, setGetClient] = useState<any[]>([])

    useEffect(() => {

      const getAllCSM = () => {
        let result = employees.filter((data:any) => data.companyRole?.name === "CSM")
        let newArrayResult = result.map((data:any) => {
          return {
            value: data.id,
            label: data.fullName
          }
        })
        setGetCSM(newArrayResult)
      }

      const getAllFO = () => {
        let newArrayResult;
        let result = employees.filter((data:any) => data.companyRole?.name === "FO")

        if(result.length === 0){
          newArrayResult = projects.map(data => ({
            value: data.foId,
            label: data.foName
          })).filter((item, index, array) => {
            return index === array.findIndex(t => t.label === item.label);
          });
        }else{
          newArrayResult = result.map((data:any) => {
            return {
              value: data.id,
              label: data.fullName
            }
          })
        }
        setGetFO(newArrayResult)
      }

      const getAllTL = () => {
        let result = employees.filter((data:any) => data.companyRole?.name === "TL")
        let newArrayResult = result.map((data:any) => {
          return {
            value: data.id,
            label: data.fullName
          }
        })
        setGetTL(newArrayResult)
      }

      const getAllClient = () => {
        let newArrayResult = clients.map(data => {
          return {
            value: data.id,
            label: data.name
          }
        })
        setGetClient(newArrayResult)
      }

      getAllCSM()
      getAllFO()
      getAllTL()
      getAllClient()
    }, [employees])

    useEffect(() => {
      getProjects()
    }, [])

    const clearFiltersProject = () => {
      setProjectsFiltered(projects)
      setFilter(DEFAULT_FILTERS)
    }

    useEffect(()=>{
      const applyFilters = (paramProject: IProjectShow[]) => {

        const projectsFilter = [...paramProject]

        if(filter){
          let result: IProjectShow[] = projectsFilter
          //filter by Status
          if(filter.status && filter.status.length > 0){
            result = projectsFilter.filter(data => data.status === filter.status)
            if(result.length === 0){
              result = projects.filter(data => data.status === filter.status)
            }
          }
          //filter by name
          if(filter.name && filter.name.length > 0){
            result = projectsFilter.filter(data => data.name.toLocaleLowerCase().includes(filter.name))
          }
          //filter by TL
          if(filter.tlName && filter.tlName.length > 0){
            result = projectsFilter.filter(data => data.tlName === filter.tlName)
          }
          //filter by CSM
          if(filter.csmName && filter.csmName.length > 0){
            result = projectsFilter.filter(data => data.csmName === filter.csmName)
          }
          //filter by Client
          if(filter.clientName && filter.clientName.length > 0){
            result = projectsFilter.filter(data => data.clientName === filter.clientName)
          }
          //filter by FO
          if(filter.foName && filter.foName.length > 0){
            result = projectsFilter.filter(data => data.foName === filter.foName)
          }
          return result
        }
        return paramProject;
      }
      applyFilters(projects)
      setProjectsFiltered(applyFilters(projects))
    },[filter])

    const arrayDirectFiltersProject = [
      {
        leable: 'Link-Activo',
        linkProperties: null,
        name: 'Active',
        onClick: () => setFilter({...filter, status:"Active"})
      },
      {
        leable: 'Link-Completado',
        linkProperties: null,
        name: 'Completed',
        onClick: () => setFilter({...filter, status:"Completed"})
      },
      {
        leable: 'Link-Unhold',
        linkProperties: null,
        name: 'On hold',
        onClick: () => setFilter({...filter, status:"On Hold"})
      }
    ]

    const listInputDropdownProject = [
      {
        labelName: "FO",
        arrayOptions: getFO,
        onChange: (e:any) => setFilter({...filter, foName:e}),
        value: filter.foName,
      },
      {
        labelName: "TL",
        arrayOptions: getTL,
        onChange: (e:any) => setFilter({...filter, tlName:e}),
        value: filter.tlName,
      },
      {
        labelName: "CSM",
        arrayOptions: getCSM,
        onChange: (e:any) => setFilter({...filter, csmName:e}),
        value: filter.csmName,
      },
      {
        labelName: "Client",
        arrayOptions: getClient,
        onChange: (e:any) => setFilter({...filter, clientName:e}),
        value: filter.clientName,
      },
    ]

    return {
        arrayDirectFiltersProject,
        setFilter,
        filter,
        clearFiltersProject,
        userProject,
        loadingProject,
        listInputDropdownProject,
        projects,
        projectsFiltered
    }
}