import React from 'react';

export const IconLocate = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20.303'
      height='27'
      viewBox='0 0 20.303 27'
    >
      <path
        id='location-dot-solid_1_'
        data-name='location-dot-solid (1)'
        d='M8.9,26.393C6.138,23,0,14.772,0,10.151a10.151,10.151,0,1,1,20.3,0c0,4.621-6.186,12.848-8.9,16.242a1.6,1.6,0,0,1-2.506,0Zm1.253-12.858a3.384,3.384,0,1,0-3.384-3.384A3.387,3.387,0,0,0,10.151,13.535Z'
      />
    </svg>
  );
};
