// context for firebase user data
import React, { createContext, FC, ReactNode, useState } from "react"
import { useForm } from "react-hook-form"
import { useRecoilValue } from "recoil"
import { iUserWithId } from "src/interfaces/user.interfaces"
import authState from "src/recoil/auth"

interface UserController {
  userEdit: iUserWithId
  handleChangeInput: (e: any, name: string) => void
  methods: any
}

const INITIAL_STATE: iUserWithId = {} as iUserWithId

export const RegisterContext = createContext<UserController>({
  userEdit: INITIAL_STATE,
  handleChangeInput: () => {},
  methods: {},
})

interface iProps {
  children: ReactNode
}

const RegisterContextProvider: FC<iProps> = ({ children }) => {
  const user = useRecoilValue(authState.user)
  const [userEdit, setEditUser] = useState<iUserWithId>(user)
  const methods = useForm<iUserWithId>({
    defaultValues: userEdit,
    mode: "onChange",
  })

  const handleChangeInput = (e: any, name: string) => {
    // @ts-ignore
    setEditUser({ ...userEdit, [name]: e })
  }

  return (
    <RegisterContext.Provider value={{ userEdit, handleChangeInput, methods }}>
      {children}
    </RegisterContext.Provider>
  )
}

export const withRegisterContextProvider = (Component: FC) => {
  return (props: any) => {
    return (
      <RegisterContextProvider>
        <Component {...props} />
      </RegisterContextProvider>
    )
  }
}

export const withRegisterContext = (Component: FC) => {
  return (props: any) => {
    return (
      <RegisterContext.Consumer>
        {(context) => <Component {...props} context={context} />}
      </RegisterContext.Consumer>
    )
  }
}
