import React from 'react';
import './button.css';
import Button from '@mui/material/Button';

import { Icon } from '../icons/Icon';

interface ButtonProps {
  primary?: boolean;
  backgroundColor?: string;
  color?: string;
  size?: 'small' | 'medium' | 'large';
  label?: string;
  iconBolean?: boolean;
  iconColor?: string;
  iconType?:
    | 'IconAdmins'
    | 'IconFilter'
    | 'IconHome'
    | 'IconLogOut'
    | 'IconProjects'
    | 'IconPTO'
    | 'IconRoles'
    | 'IconSave'
    | 'IconSearch'
    | 'IconSkills'
    | 'IconTeamMembers'
    | 'IconAglesLeft'
    | 'IconAnglesRight'
    | 'IconCardMode'
    | 'IconDoc'
    | 'IconEye'
    | 'IconLocate'
    | 'IconPen'
    | 'IconTable'
    | 'IconTrash'
    | 'IconAngular'
    | 'IconReact';
  variant?: 'text' | 'contained' | 'outlined';
  borderRadius?: 'all' | 'left' | 'right' | 'none';
  iconSize?: 'tiny' | 'small' | 'medium' | 'large';
  isIconOnly?: boolean;
  isReverseIcon?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const ButtonComponent = ({
  primary = false,
  size = 'medium',
  backgroundColor,
  color,
  iconType,
  label,
  variant,
  iconBolean = false,
  isIconOnly = false,
  borderRadius = 'none',
  iconColor = '#eaeef1',
  iconSize = 'tiny',
  isReverseIcon = false,
  disabled = false,
  ...props
}: ButtonProps) => {
  const mode = primary
    ? 'storybook-button--primary'
    : 'storybook-button--secondary';

  return (
    <Button
      variant={variant}
      disabled={disabled}
      className={[
        isReverseIcon ? 'class-RowReverse' : '',
        'storybook-button',
        `storybook-button--${size}`,
        mode,
        isIconOnly === true ? ' class-only-icon ' : ' ',
        borderRadius === 'all'
          ? 'class-borderRaius-all'
          : borderRadius === 'left'
          ? 'class-borderRaius-left'
          : borderRadius === 'right'
          ? 'class-borderRaius-right'
          : borderRadius === 'none'
          ? 'class-borderRaius-none'
          : ' ',
        isIconOnly === true ? 'class-isIconOnly' : '',
        isIconOnly && iconType === 'IconSearch' ? 'class-isIcon-search' : ''
      ].join(' ')}
      style={{ backgroundColor, color }}
      {...props}
    >
      {iconBolean && <Icon color={iconColor} size={iconSize} type={iconType} />}
      {isIconOnly === false && label}
    </Button>
  );
};
