import { TimeOff, TimeOffStatus, TimeOffType } from "src/interfaces/timeOff.interfaces";
import { ResponseT } from "src/types/types";
import { writeBatch, doc, collection, query, where, getDocs, arrayUnion, runTransaction } from "firebase/firestore";
import { COLLECTIONS, db } from "../firebase/firebase.service";
import DateHelper from "src/helpers/date/DateHelper";
import TimeOffHelper from "src/helpers/timeOff/TimeOffHelper";
import { httpsCallable } from 'firebase/functions';
import { functions } from './../firebase/firebase.service';

class TimeOffService {
    public update = async (timeOffToUpdate: TimeOff): Promise<ResponseT> => {
        try {
            const timeOffRef = doc(db, COLLECTIONS.TIME_OFF, timeOffToUpdate.id);
            const userRef = doc(db, COLLECTIONS.USERS, timeOffToUpdate.userId);

            await runTransaction(db, async (transaction) => {
                const userDoc = await transaction.get(userRef);
                if (!userDoc.exists()) {
                    throw "Document does not exist!";
                }

                let ptosAvailable = userDoc.data().ptosAvailable;

                if (timeOffToUpdate.status === TimeOffStatus.REJECTED &&
                    timeOffToUpdate.type === TimeOffType.PAID) {
                    const totalDays = TimeOffHelper.getTotalDaysFromTimeOffs([timeOffToUpdate])
                    ptosAvailable = ptosAvailable + totalDays
                }

                if (timeOffToUpdate.status === TimeOffStatus.APPROVED){

                    //convert timeOff.date to date object
                    const startDate = new Date(timeOffToUpdate.startDate);
                    const endDate = new Date(timeOffToUpdate.endDate);

                    const startDateStr = startDate.toISOString().split('T')[0]
                    const endDateStr = endDate.toISOString().split('T')[0]
                    
                    const addMessage = httpsCallable(functions, 'dev-addTimeOffToHarvest');

                    addMessage({ email: timeOffToUpdate.email, dateFrom: startDateStr, dateTo: endDateStr})
                        .then((result) => {
                            console.log(result)
                            /** @type {any} */
                        });
                }

                const oldTimeOffsWithoutNewElement = userDoc.data()
                    .timeOffs.filter((oldTimeOff: TimeOff) => oldTimeOff.id !== timeOffToUpdate.id);
                const newTimeOffs = [...oldTimeOffsWithoutNewElement, timeOffToUpdate]

                transaction.update(userRef, { ptosAvailable, timeOffs: newTimeOffs });
                transaction.update(timeOffRef, { ...timeOffToUpdate });
            });
            console.log("Transaction successfully committed!");

            return {
                statusCode: 200,
                message: 'Time off updated',
                data: timeOffToUpdate,
            } as ResponseT;
        }
        catch (err) {
            console.error(err);
            return {
                statusCode: 400,
                message: 'Cannot update timeOff',
                data: err
            } as ResponseT;
        }
    }

    public updateByUserId = async (timeOffs: TimeOff[] | undefined, userId: string): Promise<TimeOff[]> => {
        if (!timeOffs) {
            return [];
        }

        try {
            const oldTimeOffs = await this.getByUserId(userId);

            const timeOffsToCreate = timeOffs.filter(timeOff => !oldTimeOffs.some(oldTimeOff => oldTimeOff.id === timeOff.id));
            const timeOffsToDelete = oldTimeOffs.filter(oldTimeOff => !timeOffs.some(timeOff => timeOff.id === oldTimeOff.id));
            const timeOffsToUpdate = timeOffs.filter(timeOff => oldTimeOffs.some(oldTimeOff => oldTimeOff.id === timeOff.id));

            const batch = writeBatch(db);

            timeOffsToCreate.forEach(timeOff => {
                const timeOffRef = doc(collection(db, COLLECTIONS.TIME_OFF));
                batch.set(timeOffRef, { ...timeOff });
            })

            timeOffsToUpdate.forEach(timeOff => {
                const timeOffRef = doc(db, COLLECTIONS.TIME_OFF, timeOff.id);
                batch.update(timeOffRef, { ...timeOff });
            })

            timeOffsToDelete.forEach(timeOff => {
                const timeOffRef = doc(db, COLLECTIONS.TIME_OFF, timeOff.id);
                batch.update(timeOffRef, { deleted: true });
            })

            await batch.commit();

        }
        catch (e) {
            console.log({ e })
        }

        const allTimeOffs = await this.getByUserId(userId);

        return allTimeOffs.filter(timeOff => !timeOff.deleted);
    }

    public getByUserId = async (userId: string): Promise<TimeOff[]> => {
        const q = query(collection(db, COLLECTIONS.TIME_OFF), where('userId', '==', userId), where('deleted', '==', false));
        const querySnapshot = await getDocs(q);

        let timeOffs: TimeOff[] = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            let timeOff = {
                ...(data as TimeOff),
                id: doc.id,
            }

            timeOffs.push(timeOff)
        })
        return timeOffs;
    }

    public getAll = async (): Promise<TimeOff[]> => {
        const q = query(collection(db, COLLECTIONS.TIME_OFF), where('deleted', '==', false));
        const querySnapshot = await getDocs(q);

        let timeOffs: TimeOff[] = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const status = data.status;
            let timeOff = {
                ...(data as TimeOff),
                status,
                id: doc.id,
            }

            timeOffs.push(timeOff)
        })
        return timeOffs;
    }
}

export default new TimeOffService();