import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined'
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined'

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material'
import DateHelper from 'src/helpers/date/DateHelper'
import { TimeOff, TimeOffStatus } from 'src/interfaces/timeOff.interfaces'

interface Props {
  timeOffs: TimeOff[]
  onChange: (index: string, newStatus: TimeOffStatus) => void
  minHeight?: number
}

const AdminTimeOffTable = ({ timeOffs, onChange, minHeight }: Props) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ height: minHeight ? minHeight : 500, overflowY: 'scroll' }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Full Name</TableCell>
            <TableCell>Date From</TableCell>
            <TableCell>Date To</TableCell>
            <TableCell>Total days</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Admin asigned</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {timeOffs.map((row: TimeOff, index: number) => {
            const isDisabled = row.status === TimeOffStatus.EXPIRED
            return (
              <TableRow key={index}>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.fullName}</TableCell>
                <TableCell>{row.startDate}</TableCell>
                <TableCell>{row.endDate}</TableCell>
                <TableCell>
                  {DateHelper.getTotalDays(row.startDate, row.endDate)}
                </TableCell>
                <TableCell>{row?.type}</TableCell>
                <TableCell>{row?.admin ? row?.admin.fullName : "None"}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell width={1}>
                  <IconButton
                    disabled={isDisabled}
                    onClick={() => {
                      onChange(row.id, TimeOffStatus.APPROVED)
                    }}
                  >
                    <ThumbUpAltOutlinedIcon />
                  </IconButton>
                </TableCell>
                <TableCell width={1}>
                  <IconButton
                    disabled={isDisabled}
                    onClick={() => {
                      onChange(row.id, TimeOffStatus.REJECTED)
                    }}
                  >
                    <ThumbDownAltOutlinedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AdminTimeOffTable
