import React, { useState, useEffect, useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { NotificationManager } from "react-notifications"
import {
  Grid,
  Typography,
  Button,
  Box,
  Avatar,
  Link,
  Chip,
} from "@mui/material"
// Icons
import EditIcon from "@mui/icons-material/Edit"
// Molecules
import Spinner from "src/components/moleculas/Spinner/Index"
import StakeHolderCard from "src/components/moleculas/StakeHolderCard"
import ProjectCard from "src/components/moleculas/ProjectCard"
import ConfirmDialog from "src/components/moleculas/ConfirmDialog"
// Organisms
import withSidebar from "src/components/organisms/Sidebar"
// Interfaces
import { IClient } from "src/interfaces/client.interfaces"
import { IProjectShow } from "src/interfaces/project.interface"
// Services
import ClientsService from "src/services/clients/clients.service"
import ProjectsService from "src/services/projects/projects.service"
// Types
import ROUTES from "src/types/Routes"
import pandaWeb from "../../../assets/images/PandaWeb.png"
import { DesktopComputerIcon } from "@heroicons/react/outline"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import "./getClient.scss"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

import ProtectedComponent from "src/components/atoms/ProtectedComponent"

const ClientDetails: React.FC = () => {
  const navigate = useNavigate()

  const { id: clientId } = useParams()

  const [loading, setLoading] = useState(false)
  const [client, setClient] = useState<IClient>()
  const [projects, setProjects] = useState<IProjectShow[]>([])
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const getClient = useCallback(async () => {
    setLoading(true)
    const result = await ClientsService.getClient(clientId!)
    setLoading(false)

    if ("error" in result) {
      NotificationManager.error(result.error, "Error", 3000)
    } else {
      setClient(result)
    }
  }, [clientId])

  const getProjects = useCallback(async () => {
    setLoading(true)
    const result = await ProjectsService.getByClientId(clientId!)
    setLoading(false)

    if ("error" in result) {
      NotificationManager.error(result.error, "Error", 3000)
    } else {
      setProjects(result)
    }
  }, [clientId])

  const onConfirmDialog = async () => {
    setLoading(true)
    const result = await ClientsService.deleteClient(clientId!)
    setLoading(false)

    if (result.statusCode === 200) {
      setIsDialogOpen(false)
      NotificationManager.success(result.message, "Success", 3000)
      navigate(ROUTES.CLIENTS)
    } else {
      NotificationManager.error(result.message, "Error", 3000)
    }
  }

  useEffect(() => {
    getClient()
    getProjects()
  }, [getClient, getProjects])

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      pb={4}
      className="class-getClient"
      pl={3}
    >
      <Grid container alignItems="center" pb={4}>
        <Grid
          container
          item
          spacing={3}
          justifyContent="space-between"
          alignItems="baseline"
          my={2}
        >
          <Grid item>
            <Typography
              style={{
                color: "#828689",
                fontFamily: `Arial, Helvetica, sans-serif, 'regular'`,
                fontSize: "16px",
                textShadow: "1px 1px 1px #00000029",
              }}
              variant="h6"
              pl={3}
            >
              <Link href={ROUTES.CLIENTS}>Client</Link> {" > Client details"}
            </Typography>
          </Grid>
          <ProtectedComponent requiredPermissions={["client:update"]}>
            <Box>
              <Button
                variant="outlined"
                color="info"
                onClick={() => navigate(`${ROUTES.CLIENTS}/${clientId}/edit`)}
                sx={{ marginRight: 3 }}
                endIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Box>
          </ProtectedComponent>
        </Grid>

        <Grid item xs={12}>
          {loading || !client ? (
            <Spinner />
          ) : (
            <>
              <Box
                sx={{
                  p: 2,
                  minWidth: 300,
                  gap: 2,
                  margin: "30px 0",
                  borderRadius: 2,
                }}
              >
                <Grid item>
                  <Avatar
                    variant="rounded"
                    src={client?.logo?.url || pandaWeb}
                    alt="client_logo"
                    sx={{
                      borderRadius: "30px",
                      minWidth: 300,
                      minHeight: 200,
                    }}
                  />
                </Grid>
                <Grid container item>
                  <Grid container item direction="column" xs={12} md={4}>
                    <Grid item>
                      <Typography
                        style={{
                          color: "#828689",
                          padding: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Client Name
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{ color: "#828689", padding: 10, fontSize: 16 }}
                      >
                        {client?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" xs={12} md={4}>
                    <Grid item>
                      <Typography
                        style={{
                          color: "#828689",
                          padding: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Country
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{ color: "#828689", padding: 10, fontSize: 16 }}
                      >
                        <LocationOnIcon style={{ fontSize: 16 }} />
                        {client?.country || `Don't added yet`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" xs={12} md={4}>
                    <Grid item>
                      <Typography
                        style={{
                          color: "#828689",
                          padding: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Status
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{ color: "#828689", padding: 10, fontSize: 16 }}
                      >
                        {client?.status || "--"}
                        {/* <Chip
                          label={client?.status || "--"}
                          color={client?.status === "Active" ? "info" : client?.status === "Completed" ? "success" : "error"}
                        /> */}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" xs={12} md={4}>
                    <Grid item>
                      <Typography
                        style={{
                          color: "#828689",
                          padding: 10,
                          fontWeight: "bold",
                        }}
                      >
                        CSM
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{ color: "#828689", padding: 10, fontSize: 16 }}
                      >
                        {client?.csmName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" xs={12} md={4}>
                    <Grid item>
                      <DesktopComputerIcon
                        style={{ width: "1.8em", cursor: "pointer" }}
                      />
                      {client?.webpage ? (
                        <Link href={client?.webpage} target="_blank">
                          <Typography
                            style={{ color: "#0083D2", fontSize: 16 }}
                          >
                            {client?.webpage}
                          </Typography>
                        </Link>
                      ) : (
                        <Typography style={{ color: "#0083D2", fontSize: 16 }}>
                          Not avaiable
                        </Typography>
                      )}
                    </Grid>
                    <Grid item>
                      <LinkedInIcon style={{ fontSize: 28 }} />
                      {client?.linkedin !== "N/A" ? (
                        <Link href={client?.linkedin} target="_blank">
                          <Typography
                            style={{
                              color: "#0083D2",
                              fontFamily: `Arial, Helvetica, sans-serif, 'regular'`,
                              fontSize: 16,
                            }}
                          >
                            {client?.linkedin}
                          </Typography>
                        </Link>
                      ) : (
                        <Typography
                          style={{
                            color: "#0083D2",
                            fontFamily: `Arial, Helvetica, sans-serif, 'regular'`,
                            fontSize: 16,
                          }}
                        >
                          {client?.linkedin}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  p: 2,
                  minWidth: 300,
                  gap: 2,
                  margin: "30px 0",
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    fontSize: 20,
                    fontWeight: "medium",
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                  style={{ marginBottom: 10 }}
                >
                  <Typography
                    style={{
                      color: "#828689",
                      padding: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {`About ${client?.name}`}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={{ color: "#828689", padding: 10, fontSize: 16 }}
                  >
                    {client?.businessDescription ||
                      "No description given yet..."}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  p: 2,
                  minWidth: 300,
                  margin: "30px 0",
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    fontSize: 20,
                    fontWeight: "medium",
                    display: "flex",
                    alignItems: "center",
                  }}
                  style={{ marginBottom: 15 }}
                >
                  <Typography
                    style={{
                      color: "#828689",
                      padding: "20px 10px",
                      fontWeight: "bold",
                    }}
                  >
                    Key stakeholders
                  </Typography>
                </Box>
                <Box sx={{ color: "text.secondary", fontSize: 16 }}>
                  <Grid container item xs={12} rowSpacing={3} columnSpacing={2}>
                    {client?.keyStakeHolders?.length > 0 ? (
                      client?.keyStakeHolders?.map((x, i) => (
                        <Grid
                          item
                          key={i}
                          style={{ margin: 0, padding: 0 }}
                          md={6}
                          lg={4}
                          xl={3}
                        >
                          <StakeHolderCard stakeholder={x} />
                        </Grid>
                      ))
                    ) : (
                      <Grid item>No stakeholders added yet...</Grid>
                    )}
                  </Grid>
                </Box>
              </Box>

              <Box
                sx={{
                  p: 2,
                  minWidth: 300,
                  margin: "30px 0",
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    fontSize: 20,
                    fontWeight: "medium",
                    display: "flex",
                    alignItems: "center",
                  }}
                  style={{ marginBottom: 15 }}
                >
                  <Typography
                    style={{
                      color: "#828689",
                      padding: "20px 10px",
                      fontWeight: "bold",
                    }}
                  >
                    Projects
                  </Typography>
                </Box>
                <Box sx={{ color: "text.secondary", fontSize: 16 }}>
                  <Grid container item xs={12} rowSpacing={3} columnSpacing={2}>
                    {projects?.length > 0 ? (
                      projects.map((x, i) => (
                        <Grid
                          item
                          key={i}
                          style={{ margin: 0, padding: 0 }}
                          md={6}
                          lg={4}
                          xl={3}
                        >
                          <ProjectCard project={x} />
                        </Grid>
                      ))
                    ) : (
                      <Grid item>No projects added yet...</Grid>
                    )}
                  </Grid>
                </Box>
              </Box>

              {/* <Box
                sx={{
                  p: 2,
                  minWidth: 300,
                  gap: 2,
                  margin: "30px 0",
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    fontSize: 20,
                    fontWeight: "medium",
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                  style={{ marginBottom: 15 }}
                >
                  <Typography
                    style={{
                      color: "#828689",
                      padding: "20px 10px",
                      fontWeight: "bold",
                    }}
                  >
                    Statement of work (SoW)
                  </Typography>
                </Box>
                <Box sx={{ color: "text.secondary", fontSize: 16, padding: 0 }}>
                  <Grid container item xs={12} rowSpacing={3} columnSpacing={2}>
                    <Grid item>
                      {!!client?.sow?.url ? (
                        <FileCard
                          fileName={client?.sow?.name}
                          url={client?.sow?.url || pandaWeb}
                        />
                      ) : (
                        <Typography
                          style={{ color: "#828689", padding: "20px 10px" }}
                        >
                          No SOW added yet...
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box> */}
              <ConfirmDialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onConfirm={onConfirmDialog}
                title="Put Client on hold"
                contentText="Are you sure you want to put this Client on hold?"
              />
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withSidebar(ClientDetails, {
  showProfileBar: true,
  searchComponent: (
    <Link className="class-back-button" href={ROUTES.CLIENTS}>
      <Button
        variant="outlined"
        color="info"
        onClick={() => {}}
        sx={{ marginRight: 3, border: "none" }}
        endIcon={<ChevronLeftIcon />}
      >
        Back
      </Button>
    </Link>
  ),
})
