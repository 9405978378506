import React, { ElementType } from "react"
import { MenuItem } from "@mui/material"

interface MenuItemProps {
  autoFocus?: boolean
  children?: React.ReactNode
  classes?: string
  component?: ElementType
  divider?: boolean
  focusVisibleClassName?: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export const MenuItemComponent = ({
  children,
  divider,
  ...props
}: MenuItemProps) => {
  return (
    <MenuItem divider={divider} {...props}>
      {children}
    </MenuItem>
  )
}
