import React, { useState, useEffect } from "react"
import { IClient, IFilterClient } from "src/interfaces/client.interfaces"
import ClientsService from "src/services/clients/clients.service"
import { NotificationManager } from "react-notifications"

const DEFAULT_FILTER_CLIENTS = {
  name: "",
  csmName: "",
  status: "",
} as IFilterClient

export default function useClients() {
  const [searchBarText, setSearchBarText] = useState("")
  const [loading, setLoading] = useState(false)

  const [clients, setClients] = useState<IClient[]>([])
  const [functionFilter, setFunctionFilter] = useState<IFilterClient>(
    DEFAULT_FILTER_CLIENTS
  )
  const [filteredClients, setFilteredClients] = useState<IClient[]>([])
  const [listCSM, setListCSM] = useState<any>([])
  const [trigger, setTrigger] = useState<boolean>(false)

  useEffect(() => {
    setTrigger(!trigger)
  }, [clients])

  useEffect(() => {
    const proof = () => {
      let verifyRepeatData = new Set()

      const GetCSM = clients
        .map((obj) => {
          if (!verifyRepeatData.has(obj.csmId)) {
            verifyRepeatData.add(obj.csmId)
            return {
              value: obj.csmId,
              label: obj.csmName,
            }
          }
        })
        .filter(Boolean)

      return GetCSM
    }
    proof()
    setListCSM(proof)
  }, [clients])

  //this's the execute CLIENT filtered
  useEffect(() => {
    setFilteredClients(applyFilters(clients ? clients : []))
    setTrigger(!trigger)
  }, [clients, functionFilter])

  const applyFilters = (emp: IClient[]) => {
    const filteredClient = [...emp]
    if (functionFilter) {
      let result: IClient[] = filteredClient
      //filter by name
      if (functionFilter.name && functionFilter.name.length > 0) {
        result = result.filter((client) => {
          return client.name.toLowerCase().includes(functionFilter.name)
        })
      }
      //filter by STATUS
      if (functionFilter.status && functionFilter.status.length > 0) {
        result = result.filter((data) => data.status === functionFilter.status)
      }
      //filter by CSM name
      if (functionFilter.csmName && functionFilter.csmName.length > 0) {
        result = result.filter(
          (data) => data.csmName === functionFilter.csmName
        )
      }
      setTrigger(!trigger)
      return result
    }
    return emp
  }

  const DUMMY_STATUS = [
    { value: 0, label: "Active" },
    { value: 1, label: "Completed" },
    { value: 2, label: "OnHold" },
  ]

  const getClients = async () => {
    setLoading(true)
    const result = await ClientsService.getClients()
    setLoading(false)

    if ("error" in result) {
      NotificationManager.error(result.error, "Error", 3000)
    } else {
      setClients(result)
      setFilteredClients(applyFilters(result))
    }
  }

  const handleSearchBarFilter = (text: string) => {
    setSearchBarText(text)
    setFunctionFilter({
      ...functionFilter,
      name: text,
    })
  }

  useEffect(() => {
    getClients()
  }, [])

  const listInputDropdown = [
    {
      labelName: "Status",
      arrayOptions: DUMMY_STATUS,
      onChange: (e: string) =>
        setFunctionFilter({
          ...functionFilter,
          status: e,
        }),
      value: functionFilter.status,
    },
    {
      labelName: "CSM",
      arrayOptions: listCSM,
      onChange: (e: string) =>
        setFunctionFilter({
          ...functionFilter,
          csmName: e,
        }),
      value: functionFilter.csmName,
    },
  ]

  const cleanFilters = () => {
    setFilteredClients(clients)
    setSearchBarText("")
    setFunctionFilter(DEFAULT_FILTER_CLIENTS)
  }

  return {
    clients,
    searchBarText,
    handleSearchBarFilter,
    loading,
    filteredClients,
    listInputDropdown,
    cleanFilters
  }
}
