import { Box, Grid, Typography } from "@mui/material"
import { ThemeProvider, createTheme } from "@mui/system"
import React, { FC } from "react"
import CardAboutMe from "src/components/atoms/CardAboutMe"
import { iUserWithId } from "src/interfaces/user.interfaces"

const theme = createTheme({
  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#173A5E",
      secondary: "#46505A",
    },
    action: {
      active: "#001E3C",
    },
    success: {
      dark: "#009688",
      red: "red",
    },
  },
})

interface iProps {
  currentUser: iUserWithId
}

const AboutMeEmployee: FC<iProps> = ({ currentUser }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          p: 2,
          minWidth: 300,
          gap: 2,
          margin: "0 0 30px 0",
          borderRadius: 2,
        }}
      >
        {/* <Typography style={{ color: "#828689" }}>
          <Box
            sx={{
              fontSize: 16,
              fontWeight: "medium",
              display: "flex",
              alignItems: "center",
              gap: 2,
              marginBottom: 2,
            }}
          >
            Meet me
          </Box>
        </Typography> */}
        <Grid spacing={2} container alignItems="stretch">
          <CardAboutMe title={"Nickname"} description={currentUser.nickname} />
          <CardAboutMe title={"Pet Name"} description={currentUser.petName} />
          <CardAboutMe
            title={"Favorite Movie/TV Show"}
            description={currentUser.favoriteMovie}
          />
          <CardAboutMe
            title={"Superpower"}
            description={currentUser.superPower}
          />
          <CardAboutMe title={"Phobia"} description={currentUser.phobia} />
          <CardAboutMe
            title={"Favorite Holiday Place"}
            description={currentUser.favoritePlaceHoliday}
          />
          <CardAboutMe
            title={"What makes you laugh"}
            description={currentUser.mostLaughs}
          />
          <CardAboutMe
            title={"Favorite Place"}
            description={currentUser.favoritePlaces}
          />
          <CardAboutMe
            title={"Favorite Food"}
            description={currentUser.favoriteFood}
          />
          <CardAboutMe
            title={"Favorite Song/Band"}
            description={currentUser.favoriteSong}
          />
          <CardAboutMe
            title={"Favorite Quote"}
            description={currentUser.favoriteQuotes}
          />
          <CardAboutMe
            title={"Favorite Season"}
            description={currentUser.favoriteSeason}
          />
          {/* <CardAboutMe
            title={"I nominate..."}
            description={currentUser.nomination}
          /> */}
        </Grid>
      </Box>
    </ThemeProvider>
  )
}

export default AboutMeEmployee
