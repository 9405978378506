import { Box, Grid } from '@mui/material'
import { Container } from '@mui/system'
import React, { useContext } from 'react'
import EmployeesTable from 'src/components/moleculas/EmployeesTable'
import Spinner from 'src/components/moleculas/Spinner/Index'
import ModalEditUser from 'src/components/organisms/ModalEditUser'
import withSidebar from 'src/components/organisms/Sidebar'
import { SearchFilterComponent } from 'src/stories/components/ui/molecules'
import { EmployeeContext } from './Hooks/Employee-context'

const EmployeeSearchFilterComponent = () => {
  const {
    handleSearchBarFilterEmployee,
    searchBarTextEmployee,
    listSwitches,
    listInputDropdownEmployee,
    clearFilters,
    arrayDirectFilters
} = useContext(EmployeeContext);

  return  <SearchFilterComponent
    onChange={handleSearchBarFilterEmployee}
    value={searchBarTextEmployee}
    listSwitches={listSwitches}
    listInputDropdown={listInputDropdownEmployee}
    onClearFilters={clearFilters}
    arrayDirectFilters={arrayDirectFilters}
  />
}

const Employees = () => {
  const {
    loadingEmployee,
    handleEditUser,
    filteredEmployees,
    setFilters,
    selectedUser,
    getUsersInComponent,
    modalEdit,
    setModalEdit
  } = useContext(EmployeeContext);

  return (
    <Grid container>
      {loadingEmployee ? (
        <Box justifyContent={"center"}>
          <Spinner />
        </Box>
      ) : (
        <Container maxWidth={false} >
          <EmployeesTable
            handleEditUser={ handleEditUser }
            employees={ filteredEmployees }
            setFilters={ setFilters }
          />
          {selectedUser && (
            <ModalEditUser
              refresh={getUsersInComponent}
              userEdit={selectedUser}
              open={modalEdit}
              setOpen={setModalEdit}
            />
          )}
        </Container>
      )}
    </Grid>
  )
}

export default withSidebar(Employees,{
  showProfileBar: true,
  searchComponent: <EmployeeSearchFilterComponent/>
})
