import {
  IUserForProjectShow,
  IProjectModel,
} from "src/interfaces/project.interface"

export const initialProject: IProjectModel = {
  clientId: "",
  clientName: "",
  csmId: "",
  csmName: "",
  repositoryLink: "",
  name: "",
  description: "",
  dayStart: "",
  dayFinish: "",
  document: {
    name: "",
    path: "",
    url: "",
  },
  employees: [],
  foId: "",
  foName: "",
  tlId: "",
  tlName: "",
  status: "Active",
  onHoldDescription: "",
  skillList: [],
}

export const initialUser: IUserForProjectShow = {
  id: "",
  photoURL: "",
  fullName: "",
  role: {
    id: "",
    name: "",
    deleted: false,
  },
  status: "dedicated",
  dayStart: "",
  dayFinish: "",
  allocationPercentage: 100,
  seniority: "",
}
