import {
  addDoc,
  collection,
  doc,
  documentId,
  getDocs,
  query,
  updateDoc,
  where,
  orderBy,
} from "firebase/firestore"
import { Tech, TechWithId } from "src/interfaces/skills.interfaces"
import { COLLECTIONS, db } from "../firebase/firebase.service"

export const getSkills = async () => {
  try {
    const q = query(
      collection(db, COLLECTIONS.SKILLS),
      // You cannot order your query by any field included in an equality (=) or in clause. - firestore docs
      where("deleted", "!=", true),
      // your first ordering must be on the same field. - firestore docs
      orderBy("deleted"),
      orderBy("tech")
    )
    const querySnapshot = await getDocs(q)
    let skills: TechWithId[] = []
    querySnapshot.forEach((doc) => {
      let skill = { id: doc.id, ...(doc.data() as Tech) }
      skills.push(skill)
    })
    return { response: skills }
  } catch (err: any) {
    console.error(err.message)
    return {
      statusCode: err.code || 500,
      message: err.message || "Error while updating user",
    }
  }
}

export const getSkill = async (id: number): Promise<TechWithId | string> => {
  try {
    let skill: TechWithId | undefined
    //TODO maybe use .converter() like in the backend project
    const q = query(
      collection(db, COLLECTIONS.SKILLS),
      where(documentId(), "==", id),
      where("deleted", "==", false)
    )

    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      skill = { id: doc.id, ...(doc.data() as Tech) }
    })
    if (!skill) {
      throw new Error("No skills found")
    }
    return skill
  } catch (err: any) {
    console.error(err.message)
    return err.message
  }
}

export const addSkill = async (skill: string) => {
  try {
    const collec = collection(db, COLLECTIONS.SKILLS)
    const response = await addDoc(collec, { tech: skill, deleted: false })
    return { response }
  } catch (err: any) {
    console.error(err.message)
    return {
      statusCode: err.code || 500,
      message: err.message || "Error while adding skill",
    }
  }
}
export const deleteSkill = async (id: string) => {
  try {
    let skill: TechWithId | undefined
    const document = doc(db, COLLECTIONS.SKILLS, id)
    const updatedDoc = await updateDoc(document, { deleted: true })
    console.log(updatedDoc)
    return { response: updatedDoc }
  } catch (err: any) {
    console.error(err.message)
    return {
      statusCode: err.code || 500,
      message: err.message || "Error while deliting skill",
    }
  }
}

export const updateSkill = async (id: string, skill: string) => {
  try {
    const document = doc(db, COLLECTIONS.SKILLS, id)
    const updatedDoc = await updateDoc(document, { tech: skill })
    console.log(updatedDoc)
    return { response: updatedDoc }
  } catch (err: any) {
    console.error(err.message)
    return {
      statusCode: err.code || 500,
      message: err.message || "Error while updating skill",
    }
  }
}
