import React, { useState } from 'react'
import { ButtonComponent } from '../../atoms/Button/Button'
import { InputAdress } from '../../atoms/InputAdress/InputAdress'
import { InputDropdown } from '../../atoms/InputDropdown/InputDropdown'
import { BasicSwitches } from '../../atoms/Switch/Switch'
import { Input } from '../../atoms/input/Input'
import { Countries } from '../../atoms/InputAdress/locate/Countries'

import './FilterComponent.css'

interface FilterComponentProps {
  onClearFilters: () => void
  /**
   * List of Switches components rendered in the Filter molecule
   */
  listSwitches?: any[]
  /**
   * List of Inputs components rendered in the Filter molecule
   */
  listInputs?: any[]
  /**
   * List of InputDropdowns components rendered in the Filter molecule
   */
  listInputDropdown?: any[]
  /**
   * List of InputAddress components rendered in the Filter molecule
   */
  listInputAddress?: any[]
}

export const FilterComponent = ({
  listInputs,
  listSwitches,
  listInputDropdown,
  listInputAddress,
  onClearFilters,
}: FilterComponentProps) => {
  const [displayFormFilter, setDisplayFormFilter] = useState(false)

  return (
    <div
      className={[
        displayFormFilter ? 'class-FilterComponent-clicked ' : ' ',
        'class-FilterComponent',
      ].join(' ')}
    >
      <ButtonComponent
        borderRadius="right"
        iconBolean
        iconType="IconFilter"
        isIconOnly
        onClick={() => setDisplayFormFilter(!displayFormFilter)}
        variant="contained"
      />
      {displayFormFilter && (
        <form>
          <span />
          <ButtonComponent
            backgroundColor="none"
            borderRadius="none"
            iconBolean
            iconColor="#1976d2"
            iconType="IconFilter"
            isReverseIcon
            label="Clean Filter"
            onClick={onClearFilters}
          />
          {listSwitches && listSwitches.length > 0 && (
            <div className="switch-container">
              {listSwitches.map((switchData, index) => (
                <BasicSwitches
                  {...switchData}
                  key={`${switchData.leable}-${index}`}
                />
              ))}
            </div>
          )}

          {listInputs &&
            listInputs.length > 0 &&
            listInputs.map((inputData, index) => (
              <Input
                key={`${inputData.leable}-${index}`}
                backgroundColor={inputData.backgroundColor}
                border={inputData.border}
                label={inputData.label}
                type={inputData.type}
              />
            ))}
          {listInputDropdown &&
            listInputDropdown.length > 0 &&
            listInputDropdown.map((inputDropdownData, index) => (
              <InputDropdown
                className={`inputdropdown-component${
                  [0, 1].includes(index) ? '-first-element' : ''
                }`}
                key={`${inputDropdownData.leable}-${index}`}
                {...inputDropdownData}
              />
            ))}
          {listInputAddress &&
            listInputAddress.length > 0 &&
            listInputAddress.map((inputAddressData, index) => (
              <InputAdress
                className="inputAddress-component"
                key={`${inputAddressData.leable}-${index}`}
                backgroundColor={inputAddressData.backgroundColor}
                id={inputAddressData.id}
                label={inputAddressData.label}
              />
            ))}
        </form>
      )}
    </div>
  )
}
