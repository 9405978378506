import React, { useState, useEffect, useCallback } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import { NotificationManager } from "react-notifications"
import { Grid, Typography, Button, Box } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import Spinner from "src/components/moleculas/Spinner/Index"
import FileCard from "src/components/moleculas/FileCard"
import ConfirmDialog from "src/components/moleculas/ConfirmDialog"
import withSidebar from "src/components/organisms/Sidebar"
import { IProjectShow, StatusEnum } from "src/interfaces/project.interface"
import ProjectsService from "src/services/projects/projects.service"
import ROUTES from "src/types/Routes"
import UsersForProjectTable from "src/components/moleculas/UsersForProjectTable"
import "./ProjectDetails.scss"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

import ProtectedComponent from "src/components/atoms/ProtectedComponent"
import {useRecoilValue} from "recoil";
import authState from "../../../recoil/auth";

const ProjectDetails: React.FC = () => {
  const currentUser = useRecoilValue(authState.user);
  const navigate = useNavigate()

  const { idProjects: projectId } = useParams()

  const [loading, setLoading] = useState(false)
  const [project, setProject] = useState<IProjectShow>()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const getProject = useCallback(async () => {
    setLoading(true)
    const result = await ProjectsService.getProject(projectId!)
    setLoading(false)

    if ("error" in result) {
      NotificationManager.error(result.error, "Error", 3000)
    } else {
      setProject(result)
    }
  }, [projectId])

  const onConfirmDialog = async () => {
    setLoading(true)
    const result = await ProjectsService.deleteProject(projectId!)
    setLoading(false)

    if (result.statusCode === 200) {
      setIsDialogOpen(false)
      NotificationManager.success(result.message, "Success", 3000)
      navigate(ROUTES.PROJECTS)
    } else {
      NotificationManager.error(result.message, "Error", 3000)
    }
  }

  useEffect(() => {
    getProject()
  }, [getProject])

  return (
    <Grid container className="class-projectDetail">
      <Grid container alignItems="center" pb={4}>
        <Grid
          container
          item
          spacing={3}
          justifyContent="space-between"
          alignItems="baseline"
          my={2}
        >
          <Grid item>
            <Typography variant="overline">
              <Link to={ROUTES.PROJECTS}>Projects</Link>
              {" > Project details"}
            </Typography>
          </Grid>
          <ProtectedComponent requiredPermissions={["project:update"]}>
            <Box>
              <Button
                variant="outlined"
                color="info"
                onClick={() => navigate(`${ROUTES.PROJECTS}/${projectId}/edit`)}
                sx={{ marginRight: 3, border: "none" }}
                endIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Box>
          </ProtectedComponent>
        </Grid>

        <Grid item xs={12}>
          {loading || !project ? (
            <Spinner />
          ) : (
            <>
              <Grid container item columnSpacing={4} alignItems="center" my={3}>
                <Grid container item xs={12} lg={6}>
                  <Grid item xs={12} my={2}>
                    <Typography color="primary" variant="h6">
                      Project Info
                    </Typography>
                  </Grid>
                  <Grid container item direction="column" xs={12} md={3}>
                    <Typography sx={{ fontWeight: "bold" }}>Name</Typography>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {project?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" xs={12} md={3}>
                    <Grid item>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Client Name
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {project?.clientName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" sm={12} md={3}>
                    <Grid item>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Tech Stack
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        <p style={{ margin: 0 }}>
                          {project?.skillList.length > 0
                            ? project?.skillList.map(
                                (data, key) =>
                                  `${data.tech} ${
                                    key < project?.skillList.length - 1
                                      ? ","
                                      : ""
                                  } `
                              )
                            : "N/A"}
                        </p>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={6}>
                  <Grid item xs={12} my={2}>
                    <Typography color="primary" variant="h6">
                      Time Estimation
                    </Typography>
                  </Grid>
                  <Grid container item direction="column" xs={12} md={3}>
                    <Grid item>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Status
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {project?.status || "--"}
                        {/* <Chip
                          label={project?.status || "--"}
                          color={project?.status === "Active" ? "info" : "error"}
                        /> */}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" xs={12} md={3}>
                    <Grid item>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Start Date
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {project?.dayStart || "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" xs={12} md={3}>
                    <Grid item>
                      <Typography sx={{ fontWeight: "bold" }}>
                        End Date
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {project?.dayFinish || "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={6}>
                  <Grid item xs={12} my={3}>
                    <Typography color="primary" variant="h6">
                      Responsibles
                    </Typography>
                  </Grid>
                  <Grid container item direction="column" xs={12} md={3}>
                    <Grid item>
                      <Typography sx={{ fontWeight: "bold" }}>FO</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {project?.foName || "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" xs={12} md={3}>
                    <Grid item>
                      <Typography sx={{ fontWeight: "bold" }}>TL</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {project?.tlName || "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" xs={12} md={3}>
                    <Grid item>
                      <Typography sx={{ fontWeight: "bold" }}>CSM</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {project?.csmName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={6}>
                  <Grid item xs={12} mt={{ xs: 3, lg: 0 }} mb={3}>
                    <Typography color="primary" variant="h6">
                      Project Description
                    </Typography>
                  </Grid>
                  <Grid container item direction="column" xs={12} mt={0}>
                    <Grid item>
                      <Typography variant="subtitle1">
                        <p style={{ marginTop: 0 }}>
                          {project?.description || "--"}
                        </p>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Temporarily remove [PAN-123] */}
                {/* <Grid container item direction="column" xs={12} md={2}>
                  <Grid item>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      link repository
                    </Typography>
                  </Grid>
                  <Grid item>
                    {project?.repositoryLink ? (
                      <Link href={project?.repositoryLink} target="_blank">
                        {project?.repositoryLink || '--'}
                      </Link>
                    ) : (
                      <Typography variant="subtitle1">--</Typography>
                    )}
                  </Grid>
                </Grid> */}

                {project.status === StatusEnum.ONHOLD && (
                  <Grid
                    container
                    item
                    direction="column"
                    xs={12}
                    sx={{ marginTop: "20px" }}
                  >
                    <Grid item>
                      <Typography sx={{ fontWeight: "bold" }}>
                        On hold description
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        <p style={{ margin: 0 }}>
                          {project?.onHoldDescription || "--"}
                        </p>
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12} my={3}>
                  <Typography color="primary" variant="h6">
                    Team members
                  </Typography>
                </Grid>
                <Grid container item xs={12} rowSpacing={3} columnSpacing={2}>
                  {project?.employees.length > 0 ? (
                    <UsersForProjectTable
                      users={project?.employees}
                      onDelete={() => {}}
                    />
                  ) : (
                    <Grid item>No members added yet...</Grid>
                  )}
                </Grid>

                { ['csm', 'admin', 'ba/pm'].includes(currentUser.companyRole?.name?.toLowerCase()) && (
                  <>
                    <Grid item xs={12} mt={4} mb={3}>
                      <Typography color="primary" variant="h6">
                        SOW
                      </Typography>
                    </Grid>
                    <Grid item>
                      {!!project?.document.name ? (
                        <FileCard
                          fileName={project?.document?.name}
                          url={project?.document?.url}
                        />
                      ) : (
                        "No documents added yet..."
                      )}
                    </Grid>
                  </>
                ) }
              </Grid>

              <ConfirmDialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onConfirm={onConfirmDialog}
                title="Delete Project"
                contentText="Are you sure you want to delete this Project?"
              />
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withSidebar(ProjectDetails, {
  showProfileBar: true,
  searchComponent: (
    <Link to={ROUTES.PROJECTS} className="class-back-button">
      <Button
        variant="outlined"
        color="info"
        onClick={() => {}}
        sx={{ marginRight: 3, border: "none" }}
        endIcon={<ChevronLeftIcon />}
      >
        Back
      </Button>
    </Link>
  ),
})
