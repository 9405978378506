// import React, { useState } from 'react';
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
// import './InputDropdown.css';

// interface InputDropdownProps {
//   className?: string;
//   labelName?: string;
//   arrayOptions: any[];
//   idName?: string;
//   backgroundColor?: string;
//   size?: 'small' | 'medium';
//   disabled?: boolean;
//   /**
//    * Default value for the InputDropdwon atom
//    */
//   // value?: string | number ;
//   value: string;
//   /**
//    * Function triggered for change the value of InputDropdwon when we select an option of the arrayOptions
//    */
//   onHandleChange?: (eventValue: any) => void;
//   /**
//    * Value showed when the user write in the input text field
//    */
//   inputValue?: string | number;
//   /**
//    * Function triggered when the user write in the Input Text Field
//    */
//   onHandleInputChange?: (eventValue: any) => void;
// }

// export const InputDropdown = ({
//   className,
//   arrayOptions,
//   labelName,
//   idName,
//   backgroundColor,
//   size,
//   value = '',
//   onHandleChange = () => ({}),
//   inputValue,
//   onHandleInputChange = () => ({}),
//   disabled
// }: InputDropdownProps) => {
//   return (
//     <div className={className}>
//       <Autocomplete
//         value={value}
//         onChange={(event: any, newValue: string | null) => {
//           onHandleChange(newValue);
//         }}
//         inputValue={value}
//         onInputChange={(event, newInputValue) => {
//           onHandleInputChange(newInputValue);
//         }}
//         disabled={disabled}
//         disablePortal
//         className={['class-inputDropdown', 'class-all-border '].join(' ')}
//         id={idName}
//         style={{ backgroundColor }}
//         options={arrayOptions}
//         renderInput={(params) => <TextField {...params} label={labelName} />}
//         size={
//           size === 'small' ? 'small' : size === 'medium' ? 'medium' : undefined
//         }
//       />
//     </div>
//   );
// };
import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import './InputDropdown.css'

interface Props {
  className?: string
  labelName?: string
  arrayOptions: string[]
  idName?: string
  backgroundColor?: string
  size?: 'small' | 'medium'
  value: string
  onChange: (value: string) => void
  disabled?: boolean;
}

export const InputDropdown = ({
  className,
  labelName = 'Movie',
  arrayOptions,
  idName,
  backgroundColor,
  size,
  value,
  onChange,
  disabled
}: Props) => {
  return (
    <div className={className}>
      <Autocomplete
        value={value}
        inputValue={value}
        onInputChange={(event, newInputValue) => {
          onChange(newInputValue)
        }}
        disabled={disabled}
        disablePortal
        className={['class-inputDropdown', 'class-all-border '].join(' ')}
        id={idName}
        style={{ backgroundColor }}
        options={arrayOptions}
        renderInput={(params) => <TextField {...params} label={labelName} />}
        size={
          size === 'small' ? 'small' : size === 'medium' ? 'medium' : undefined
        }
        isOptionEqualToValue={(option, value) => option === value}
      />
      {/* if something is falling with any Input Dropdown , please check it on isOptionEqualToValue */}
    </div>
  )
}
