import React from "react"
import { Control, Controller } from "react-hook-form"
import { TextField, Box, BaseTextFieldProps, FormLabel } from "@mui/material"

interface Props extends BaseTextFieldProps {
  label: string
  control: Control
  name: string
  rows?: number
  placeholder?: string
  required?: boolean
  errorText?: string
}

const FormInputText = ({
  label,
  control,
  required,
  errorText,
  ...props
}: Props) => {
  return (
    <Box sx={{ display: "flex" }} flexDirection="column">
      <FormLabel>{label}</FormLabel>
      <Controller
        name={props.name}
        control={control}
        rules={{
          required: { message: "Field is required", value: !!required },
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...props}
            {...field}
            hiddenLabel
            multiline={!!props.rows}
            size="small"
            error={!!fieldState.error}
            // Always have some text to prevent misalignments with its siblings
            helperText={!!fieldState.error ? fieldState.error?.message : " "}
            sx={Styles.textField}
          />
        )}
      />
    </Box>
  )
}

const Styles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 5,
      },
      "& textarea": {
        "&:focus": {
          border: "none",
          overFlow: "auto",
          outline: "none",
          WebkitBoxShadow: "none",
        },
      },
    },
    "& input": {
      borderRadius: 5,
      "&:focus": {
        borderRadius: 5,
      },
    },
  },
}

export default FormInputText
