import React from 'react';
import { MenuList } from '@mui/material';

interface MenuItemProps {
  autoFocus?: boolean;
  autoFocusItem?: boolean;
  children?: React.ReactNode;
  disabledItemsFocusable?: boolean;
  disableListWrap?: boolean;
  varian?: 'menu' | 'selectedMenu';
}

export const MenuListComponent = ({ children, ...props }: MenuItemProps) => {
  return <MenuList {...props}>{children}</MenuList>;
};
