import { Box } from '@mui/system'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { Avatar } from '@mui/material'
import panda from 'src/assets/images/PandaCoolAhre.png'
import { CSSProperties, useRef } from 'react'
import FaIconButton from '../FaIconButton'

interface Props {
  onChange: (imageUrl: string) => void
  value?: string
  smallSize?: boolean
  // When needing to get the File instead of url
  isUsingFile?: boolean
  imageFile?: File
  onFileChange?: (file: File) => void
}

const ProfilePicture = ({
  onChange,
  value,
  smallSize,
  isUsingFile,
  imageFile,
  onFileChange,
}: Props) => {
  const styles = {
    container: {
      position: 'relative',
      cursor: 'pointer',
    },
    avatar: {
      display: 'block',
      height: smallSize ? 105 : 150,
      width: smallSize ? 105 : 150,
    },
    fa: {
      position: 'absolute',
      bottom: 0,
      left: smallSize ? 80 : 120,
      height: smallSize ? 33 : 40,
      width: smallSize ? 33 : 40,
    },
  }

  const fileInput = useRef(null)

  const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.[0]
    if (!image) {
      return
    }

    const imageUrl = URL.createObjectURL(image)
    onChange(imageUrl)
    isUsingFile && onFileChange && onFileChange(image)
  }

  return (
    <Box
      sx={styles.container}
      // @ts-ignore
      onClick={() => fileInput.current?.click()}
    >
      <Avatar
        alt="avatar image"
        src={imageFile ? URL.createObjectURL(imageFile) : value || panda}
        sx={styles.avatar}
      />
      <FaIconButton icon={faCamera} style={styles.fa as CSSProperties} />
      <input
        hidden
        type="file"
        // @ts-ignore
        ref={fileInput}
        className="hidden"
        accept="image/*"
        onChange={handleChangeImage}
      />
    </Box>
  )
}

export default ProfilePicture
