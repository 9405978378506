import { TextField, Box, FormLabel } from "@mui/material"
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import InputProfile from "../InputProfile"

interface Props {
  label: string
  value: Date | null
  onChange: (newValue: Date | null) => void
  inputFormat?: string
  error?: boolean
  errorMessage?: string
  disabled?: boolean
  isHookForm?: boolean
  minDate?: Date
  maxDate?: Date
}

const DatePicker = ({
  onChange,
  inputFormat = "MM/DD/YYYY",
  error,
  errorMessage,
  isHookForm,
  label,
  minDate,
  maxDate,
  ...props
}: Props) => {
  const handleChange = (newValue: any) => {
    onChange(newValue ? newValue.toDate() : null)
  }

  // Always have some text to prevent misalignments with its siblings
  const helperText = error ? errorMessage : " "

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        {...props}
        onChange={handleChange}
        inputFormat={inputFormat}
        minDate={minDate}
        maxDate={maxDate}
        value={props.value || null}
        renderInput={(params: any) =>
          isHookForm ? (
            <Box
              sx={{
                display: "flex",
                marginTop: "-1.2rem",
              }}
              flexDirection="column"
            >
              <FormLabel>{label}</FormLabel>
              <TextField
                {...params}
                error={error}
                helperText={helperText}
                sx={{
                  "& input": {
                    marginTop: "1.2rem",
                  },
                  "& button": {
                    marginTop: "1.2rem",
                  },
                }}
              />
            </Box>
          ) : (
            <InputProfile
              {...params}
              label={label}
              error={error}
              helperText={helperText}
            />
          )
        }
      />
    </LocalizationProvider>
  )
}

export default DatePicker
