import React from "react"
import { Box, ThemeProvider, createTheme } from "@mui/system"
import { skillLevelMapper } from "src/helpers/mappers/skillLevel.mapper"
import { iUserWithId } from "src/interfaces/user.interfaces"
import { Grid, Typography } from "@mui/material"
import "./Skills.scss"
import SkillsTable from "../SkillsTable"

const theme = createTheme({
  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#173A5E",
      secondary: "#46505A",
    },
    action: {
      active: "#001E3C",
    },
    success: {
      dark: "#009688",
      red: "red",
    },
  },
})

const Skills = (props: { currentPageEmployees?: iUserWithId }) => {
  return (
    <Box
      sx={{
        p: 2,
        minWidth: 300,
        gap: 2,
        margin: "30px 0",
        borderRadius: 2,
      }}
    >
      <Typography style={{ color: "#828689" }}>
        <Box
          sx={{
            fontSize: 16,
            fontWeight: "medium",
            display: "flex",
            alignItems: "center",
            gap: 2,
            marginBottom: 2,
          }}
        >
          Skills
        </Box>
      </Typography>
      <SkillsTable
        skills={props.currentPageEmployees?.skills ?? []}
      />
    </Box>
    // <ThemeProvider theme={theme}>
    //   <Typography style={{ color: "#828689" }}>
    // <Box
    //   sx={{
    //     p: 2,
    //     minWidth: 300,
    //     gap: 2,
    //     margin: "30px 0",
    //     borderRadius: 2,
    //   }}
    // >
    // <Box
    //   sx={{
    //     fontSize: 16,
    //     fontWeight: "medium",
    //     display: "flex",
    //     alignItems: "center",
    //     gap: 2,
    //   }}
    // >
    //   Skills
    // </Box>
    //       <Box
    //         sx={{
    //           color: "text.secondary",
    //           display: "flex",
    //           fontSize: 14,
    //           gap: 2,
    //         }}
    //       >
    //         <Grid
    //           display="flex"
    //           sx={{
    //             width: "100%",
    //             overflow: "auto",
    //             flexWrap: "wrap",
    //             justifyContent: "space-between",
    //             flexDirection: "row",
    //             alignContent: "stretch",
    //           }}
    //         >
    //           {!props.currentPageEmployees
    //             ? "This member doesn't add any skill yet"
    //             : props.currentPageEmployees?.skills?.map(
    //                 (data: any, key: any) => (
    //                   <Box
    //                     key={key}
    //                     sx={{
    //                       background: "#EAEEF1",
    //                       borderRadius: "20px",
    //                       width: "33%",
    //                       margin: "6px 0",
    //                     }}
    //                   >
    //                     <Box
    //                       key={data.id}
    //                       sx={{
    //                         display: "flex",
    //                         padding: "20px",
    //                         justifyContent: "space-between",
    //                       }}
    //                     >
    //                       <Grid
    //                         display="flex"
    //                         sx={{
    //                           width: "100%",
    //                           overflow: "auto",
    //                           flexWrap: "wrap",
    //                           justifyContent: "space-between",
    //                           flexDirection: "row",
    //                           alignContent: "stretch",
    //                         }}
    //                       >
    //                         <Box
    //                           style={{
    //                             display: "flex",
    //                             alignItems: "center",
    //                           }}
    //                         >
    //                           {/*[PAN-206] Commented icon due to multiple skills having the same icon, can be used later with custom images/icons */}
    //                           {/* <div
    //                             className="class-icon-skills"
    //                             style={
    //                               data.tech === "React JS"
    //                                 ? {
    //                                     backgroundColor: "#0083D2",
    //                                     padding: "8px",
    //                                     borderRadius: "25px",
    //                                   }
    //                                 : data.tech === "Angular JS"
    //                                 ? {
    //                                     backgroundColor: "#E11F1F",
    //                                     padding: "8px",
    //                                     borderRadius: "25px",
    //                                   }
    //                                 : {
    //                                     backgroundColor: "#618C03",
    //                                     padding: "8px",
    //                                     borderRadius: "25px",
    //                                   }
    //                             }
    //                           >
    //                             {data.tech === "React JS" ? (
    //                               <IconReact />
    //                             ) : data.tech === "Angular JS" ? (
    //                               <IconAngular />
    //                             ) : (
    //                               <CodeIcon
    //                                 style={{ width: "1.8em", color: "white" }}
    //                               />
    //                             )}
    //                           </div> */}
    //                           <Typography
    //                             sx={{
    //                               color: "#828689",
    //                               lineHeight: "24px",
    //                               fontWeight: 500,
    //                             }}
    //                           >
    //                             &nbsp;{data.tech}
    //                           </Typography>
    //                         </Box>
    //                         <Box
    //                           style={{
    //                             display: "flex",
    //                             alignItems: "center",
    //                           }}
    //                         >
    //                           {/* <TrashIcon style={{ width: '1.4em'}} /> */}
    //                           <Typography
    //                             sx={{
    //                               color: "#828689",
    //                               lineHeight: "24px",
    //                               fontWeight: 500,
    //                             }}
    //                           >
    //                             {
    //                               //@ts-ignore
    //                               " " + skillLevelMapper[data.skillLevel]
    //                             }
    //                           </Typography>
    //                         </Box>
    //                         <Box
    //                           style={{
    //                             display: "flex",
    //                             alignItems: "center",
    //                           }}
    //                         >
    //                           {/* <PencilIcon style={{ width: '1.4em' }} /> */}
    //                           <Typography
    //                             sx={{
    //                               color: "#828689",
    //                               lineHeight: "24px",
    //                               fontWeight: 500,
    //                             }}
    //                           >
    //                             {`${data.year} year(s)`}
    //                           </Typography>
    //                         </Box>
    //                       </Grid>
    //                     </Box>
    //                   </Box>
    //                 )
    //               )}
    //         </Grid>
    //       </Box>
    //     </Box>
    //   </Typography>
    // </ThemeProvider>
  )
}

export default Skills
