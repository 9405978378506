import React, { useState, useEffect } from "react"
import { Box } from "@mui/system"
import { iUserWithId } from "../../interfaces/user.interfaces"
import userService from "../../services/users/user.service"
import { useParams, useNavigate } from "react-router-dom"
import PersonalData from "src/components/moleculas/EmployeDetailsComponent/PersonalData"
import Bio from "src/components/moleculas/EmployeDetailsComponent/Bio"
import Skills from "src/components/moleculas/EmployeDetailsComponent/Skills"
import Loading from "../Loading"
import AboutMeEmployee from "src/components/moleculas/EmployeDetailsComponent/AboutMeEmployee"
import { NotificationManager } from "react-notifications"
import CertificationsView from "src/components/organisms/CertificationView"
import withSidebar from "src/components/organisms/Sidebar"
import { CardTeamMember } from "src/stories/components"
import { hasPermissions } from "src/helpers/user/UserHelper"
import useEmployees from "./Hooks/use-employees"
import { useRecoilValue } from "recoil"
import authState from "src/recoil/auth"
import Button from "@mui/material/Button"
import "./Scss/Employees.scss"
import ProfessionalView from "src/components/moleculas/ProfessionalView"
import ROUTES from "src/types/Routes"

const EmployeDetails = () => {
  const navigate = useNavigate()
  const { employees } = useEmployees()
  const currentUserLoged = useRecoilValue(authState.user)
  const userHasPermissions = hasPermissions([
    ...(currentUserLoged.companyRole?.permissions || []),
    ...(currentUserLoged.permissions || []),
  ])
  const params = useParams()
  const id = params.idEmployee

  const [currentUser, setCurrentUser] = useState<iUserWithId>()

  useEffect(() => {
    userService
      .getUser(id!)
      .then(setCurrentUser)
      .catch((e) => NotificationManager.error("No user found"))
  }, [id])

  if (!currentUser) {
    return <Loading />
  }

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={() => {
          // navigate(-1)
          // navigate(ROUTES.EMPLOYEES)
          window.location.href = ROUTES.EMPLOYEES
        }}
        fullWidth
        className="back-button"
      >
        Back
      </Button>
      <PersonalData currentPageEmployees={currentUser} />
      <div
        className={
          userHasPermissions("user:edit")
            ? "class-child-admin class-child-profile"
            : " class-child-profile"
        }
      >
        {userHasPermissions("user:view") ? (
          <div>
            <ProfessionalView user={currentUser} />
            <Skills currentPageEmployees={currentUser} />
            <CertificationsView certifications={currentUser.certifications} />
            <Bio currentPageEmployees={currentUser} />
            <AboutMeEmployee currentUser={currentUser} />
          </div>
        ) : (
          <div>
            <Bio currentPageEmployees={currentUser} />
            <AboutMeEmployee currentUser={currentUser} />
            <ProfessionalView user={currentUser} />
            <Skills currentPageEmployees={currentUser} />
            <CertificationsView certifications={currentUser.certifications} />
          </div>
        )}
        {!userHasPermissions("user:manage") && (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Box
              sx={{
                fontSize: 16,
                fontWeight: "medium",
                display: "flex",
                alignItems: "center",
                gap: 2,
                color: "#828689",
              }}
            >
              Look another members
            </Box>
            {employees
              .filter(
                (data: any, key: number) =>
                  key <= 4 && data.email !== currentUser.email
              )
              .map((data: any) => (
                <CardTeamMember
                  userName={data.fullName}
                  urlImage={data.photoURL}
                  status={
                    data.isCurrentEmployee
                      ? data.inBench
                        ? "In branch"
                        : "In project"
                      : "Suspended"
                  }
                  SkillArray={[
                    {
                      backgroundColor: "#0083D2",
                      borderRadius: "all",
                      color: "#4fc4ff",
                      disabled: true,
                      iconBolean: true,
                      iconColor: "#F5F5F5",
                      iconType: "IconReact",
                      isIconOnly: true,
                      label: "Button name",
                      primary: true,
                      variant: "contained",
                    },
                    {
                      backgroundColor: "#E11F1F",
                      borderRadius: "all",
                      color: "#4fc4ff",
                      disabled: true,
                      iconBolean: true,
                      iconColor: "#F5F5F5",
                      iconType: "IconAngular",
                      isIconOnly: true,
                      label: "Button name",
                      primary: true,
                      variant: "contained",
                    },
                    {
                      backgroundColor: "#618C03",
                      borderRadius: "all",
                      color: "#fafff0",
                      disabled: true,
                      iconBolean: false,
                      iconColor: "#F5F5F5",
                      isIconOnly: false,
                      label: "+88",
                      primary: true,
                      variant: "contained",
                    },
                  ]}
                  cardType="result"
                  userDescription=""
                />
              ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default withSidebar(EmployeDetails, { showProfileBar: true })
